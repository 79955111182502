<template>
	<div class="p-2">
		<div class="font-bold pl-2">Dashboard</div>
		<div class="p-10">
			    <div class="card bg-base-200 flex "> 
				<div class="flex px-3 justify-between header">
					<h2 class="card-title p-4">Kpis</h2>
					<div>
						<button class="btnprimary mt-4" @click="visibilitykpis = !visibilitykpis">Filters</button>
						<button class="btnprimary mt-2 ml-2" v-if="visibilitykpis&&kpiSetSelected" @click="filterData()">Apply</button>
					</div>
				</div>
				<div class="card-body">
					<div class="filters flex flex-wrap container" v-if="visibilitykpis">
						<div>
							<label class="controllabel font-normal text-sm mb-1">Reporting Frequency</label>
							<select class="inputboxstyling" v-model="kpiDataFilter.reportingFrequency">
								<option v-for="(option, optionIndex) in options" :key="optionIndex">{{ option }}</option>
							</select>
						</div>
						<div>
							<label class="controllabel font-normal text-sm mb-1">Target Time Period</label>
							<select class="inputboxstyling" v-model="kpiDataFilter.targetTimePeriod">
								<option v-for="(option, optionIndex) in options" :key="optionIndex">{{ option }}</option>
							</select>
						</div>
						<div>
							<label class="controllabel font-normal text-sm mb-1">Departments</label>
							<select class="inputboxstyling" v-model="kpiDataFilter.departmentId">
								<option v-for="(department, departmentIndex) in departmentsList" :key="departmentIndex" :value="department._id">{{ department.teamName }}</option>
							</select>
						</div>
						<div>
							<label class="controllabel font-normal text-sm mb-1">Control Source</label>
							<select class="inputboxstyling" @change="getControlsData($event,'kpi')" v-model="kpiDataFilter.controlSource">
								<option v-for="(controlSet, controlIndex) in allControlSets" :key="controlIndex" :value="controlSet._id">{{ controlSet.controlSource }}</option>
							</select>
						</div>
						<div v-if="kpiSetSelected">
							<label class="controllabel font-normal text-sm mb-1">Control Family</label>
							<select class="inputboxstyling" v-model="kpiDataFilter.controlFamily">
								<option v-for="(control, controlNumber) in controlsDataOfSelectedControlSet" :key="controlNumber">{{ control.controlFamily }}</option>
							</select>
						</div>

						<div v-if="kpiSetSelected">
							<label class="controllabel font-normal text-sm mb-1">Control Class</label>
							<select class="inputboxstyling" v-model="kpiDataFilter.controlClass">
								<option v-for="(control, controlNumber) in controlsDataOfSelectedControlSet" :key="controlNumber">{{ control.controlClass }}</option>
							</select>
						</div>
					</div>
					<div class="w-96 h-80 relative m-auto p-6">
							   <!-- <apexchart 
      							width="400"
     							type="donut"
      							:options="chartOptions"
      							:series="a"
  								  ></apexchart> -->
						<!-- <doughnut-chart v-if="isLoaded && noData" :styles="myStyles" :chartData="chartData"></doughnut-chart> -->
						<div v-if="!noData">No Records Found</div>
					</div>
				</div>
			</div>
		</div>
		<div class="p-10">
			<div class="card bg-base-200 flex ">
				<div class="flex px-3 justify-between header">
					<h2 class="card-title p-4">Controls</h2>
					<div>
						<button class="btnprimary mt-4" @click="visibility = !visibility">Filters</button>
						<button class="btnprimary mt-2 ml-2" v-if="visibility && controlSetSelected" @click="applyFilters()">Apply</button>
					</div>
				</div>
				<div class="card-body">
					<div class="filters flex justify-between" v-if="visibility">
						<div>
							<label class="controllabel font-normal text-sm">Departments</label>
							<select class="inputboxstyling" v-model="controlDataFilter.departmentId">
								<option v-for="(department, departmentIndex) in departmentsList" :key="departmentIndex" :value="department._id">{{ department.teamName }}</option>
							</select>
						</div>
						<div>
							<label class="controllabel font-normal text-sm mb-1">Control Source</label>
							<select class="inputboxstyling" @change="getControlsData($event,'control')" v-model="controlDataFilter.controlSource">
								<option v-for="(controlSet, controlIndex) in allControlSets" :key="controlIndex" :value="controlSet._id">{{ controlSet.controlSource }}</option>
							</select>
						</div>
						<div v-if="controlSetSelected">
							<label class="controllabel font-normal text-sm mb-1">Control Family</label>
							<select class="inputboxstyling" v-model="controlDataFilter.controlFamily">
								<option v-for="(control, controlNumber) in controlsDataOfSelectedControlSet" :key="controlNumber">{{ control.controlFamily }}</option>
							</select>
						</div>

						<div v-if="controlSetSelected">
							<label class="controllabel font-normal text-sm mb-1">Control Class</label>
							<select class="inputboxstyling" v-model="controlDataFilter.controlClass">
								<option v-for="(control, controlNumber) in controlsDataOfSelectedControlSet" :key="controlNumber">{{ control.controlClass }}</option>
							</select>
						</div>
					</div>
			
					<div class="w-96 h-80 relative m-auto p-6">
						   <!-- <apexchart 
      						width="400"
      						type="donut"
      						:options="chartOptions"
      						:series="b"
   							 ></apexchart> -->
						<!-- <apexchart width="400" type="donut" ></apexchart> -->
						<!-- <doughnut-chart v-if="isCLoaded && controlData" :styles="myStyles" :chartData="chartDataForControls"></doughnut-chart> -->
						<div v-if="!controlData">No Records Found</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<style  scoped>
.container > div {
 width:32%;
 margin: 5px;
}
.inputboxstyling{
	margin-top: 6px;
}
.filterButton {
	border-radius: 5px;
	background-color: light;
}
.card {
	height: 36rem;
}

</style>

<script lang="ts">
// import DoughnutChart from '../../components/charts/donought-chart.vue';
import _ from 'lodash';
import { emitter,toast } from '../../main';
// import VueApexCharts from 'vue3-apexcharts';
import { mapGetters } from 'vuex';
import { defineComponent } from 'vue';
export default defineComponent({
	data() :any{
		return {
			varx: 10,
			controlDataFilter: { controlClass: '', controlFamily: '', departmentId: '', controlSource: '' },
			kpiDataFilter: { controlClass: '', controlFamily: '', departmentId: '', controlSource: '', targetTimePeriod: '', reportingFrequency: '' },
			visibility: false,
			isLoaded: false,
			isCLoaded: false,
			controlSetSelected: false,
			visibilitykpis: false,
			kpiSetSelected:false,
			a:'',
			b:'',
			allControlSets: [],
			// chartData: {
			// 	labels: [],
			// 	datasets: [
			// 		{
			// 			data: []
			// 		}
			// 	]
			// },
			
          
          
        //   chartOptions: {
        //    colors:['#f84a51', '#4bc26e', '#d8dbdc'],
        //     responsive: [{
        //       breakpoint: 480,
        //       options: {
               
        //         legend: {
        //           position: 'bottom'
        //         }
        //       }
        //     }]
        //   },
          
          
			chartData: {
				colors: ['#f84a51', '#4bc26e', '#d8dbdc'],
				labels: ['Completed', 'In Progress', 'Not Started'],
				legend: {
					fontSize: '14px',
					// horizontalAlign:'center',
					position: 'bottom'
				}
			},
        
			chartOptions: {
				colors: ['#f84a51', '#4bc26e', '#d8dbdc'],
				labels: ['Completed', 'In Progress', 'Not Started'],
				legend: {
					fontSize: '14px',
					// horizontalAlign:'center',
					position: 'bottom'
				}
			},
			selectedTimePeriod: '',
			departmentsList: [],
			selectedReportingFrequency: '',
			noData: false,
			controlData: false,
			options: ['Daily', 'Weekly', 'Monthly', 'Quarterly', 'Half-Yearly', 'Yearly'],

			chartDataForControls: {
				labels: [],
				datasets: [
					{
						data: []
					}
				]
			},
			controlsDataOfSelectedControlSet: [],
			controlChartData: {
				labels: [],
				datasets: [
					{
						data: []
					}
				]
			}
		};
	},
	// components: {
	// 	DoughnutChart
	// },
	// components: {
	// 	apexchart: VueApexCharts
	// },
	computed: {
		myStyles() :any{
			return {
				height: `250px`,
				width: '400px',
				position: 'relative'
			};
		},

		...mapGetters({
			userInfo: 'userInfo'
		})
	},
	created() {
		// this.getAllControlsData({});
		this.fetchAllTasks();
		this.fetchTasksProcessedData();
		this.getAllControlSets();
		this.fetchDepartmentsList();
		sessionStorage.removeItem('selectedTab')
	},

	methods: {
		applyFilters() {
			this.getAllControlsData(this.controlDataFilter);
		},
		async getControlsData(event: { target: { value: any; }; },type: string) {
			
			let payload
			if(type =='kpi'){
				this.kpiSetSelected = true;
				payload = { controlSetId: event.target.value };

			}else{
				this.controlSetSelected = true;
				payload = { controlSetId: event.target.value };
			}
			
			await this.$http.post(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/controlset/controls/getall`, payload).then((result: { data: any; }) => {
				this.controlsDataOfSelectedControlSet = result.data;
			});
		},
			async getKpiData(event: { target: { value: any; }; }) {
			
			let payload = { controlSetId: event.target.value };
			await this.$http.post(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/controlset/controls/getall`, payload).then((result: { data: any; }) => {
				this.controlsDataOfSelectedControlSet = result.data;
			});
		},
		async fetchDepartmentsList() {
			await this.$http
				.get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`)
				.then((res: { data: any; }) => {
					this.departmentsList = res.data;
				})
				.catch((error: any) => {
					toast.error(`Save failed with ${error}`, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
				});
		},
		async getAllControlSets() {
			await this.$http.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/controlset/getall`).then((result: { data: any; }) => {
				this.allControlSets = result.data;
			});
		},
		async getAllControlsData(controlDataFilters: { constructor?: any; }) {
			let result:any;
			if (Object.keys(controlDataFilters).length === 0 && controlDataFilters.constructor === Object) {
				await this.$http.post(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/controls/dashboard`).then((res: any) => {
					result = res;
					console.log("Ddddd",result)
				});
			} else {
				await this.$http.post(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/controls/dashboard`, controlDataFilters).then((res: any) => {
					result = res;
				});
			}
			if (result.data.length != 0) {
				this.chartDataForControls = {
					labels: [],
					datasets: [
						{
							data: []
						}
					]
				};
				Object.entries(result.data[0]).forEach((entry) => {
					const [key, value] = entry;
					if (key !== '_id') {
						this.chartDataForControls.labels.push(key);
						this.chartDataForControls.datasets[0].data.push(value);
					}
				});

				this.b=this.chartDataForControls.datasets[0].data
				this.chartDataForControls.datasets[0].backgroundColor = ['#f84a51', '#4bc26e', '#d8dbdc'];
				// this.chartDataForControls.datasets[0].hoverBackgroundColor = ['#f84a51', '#4bc26e', '#d8dbdc'];
				this.chartDataForControls.datasets[0].borderWidth = 0;
				this.isCLoaded = true;
				this.controlData = true;

			} 
		},
		fetchAllTasks() {
			if (this.userInfo.organisationType == 10701) {
				this.$router.push({ name: 'admin' });
			}
			this.$http.get(`${process.env.VUE_APP_MONITOR_API_URL}/api/org/tasks`).then((result: { data: any; }) => {
				var finalData = [];
				Object.entries(_.groupBy(result.data, 'controlNumber')).forEach((entry) => {
					const [key, value] = entry;
					// var x = _.groupBy(value,'departmentId');
					Object.entries(_.groupBy(value, 'departmentId')).forEach((nested) => {
						const [key1, value1]:any = nested;
						finalData.push(...value1);

					});
				});
				this.controlChartData.labels.push('NO-DATA');
				this.controlChartData.labels.push('NOT-MET');
				this.controlChartData.datasets[0].data.push(2);
				this.controlChartData.datasets[0].data.push(1);
				this.controlChartData.datasets[0].backgroundColor = ['#d8dbdc', '#f84a51', '#d8dbdc'];
				this.controlChartData.datasets[0].borderWidth = 0;
			});
		},
		async fetchTasksProcessedData(filterObject: any) {
			await this.$http.post(`${process.env.VUE_APP_MONITOR_API_URL}/api/org/tasks/dashboard`, filterObject).then((result: { data: string|any[]; }) => {
				if (result.data.length != 0) {
					this.chartData = {
						labels: [],
						datasets: [
							{
								data: []
							}
						]
					};
					Object.entries(result.data[0]).forEach((entry) => {
						const [key, value] = entry;
						if (key !== '_id') {
							this.chartData.labels.push(key);
							this.chartData.datasets[0].data.push(value);
							
						}
					});
					console.log("Ddddd",this.chartData.datasets[0])
					this.a=this.chartData.datasets[0].data
					console.log("aaa",this.a)
					this.chartData.datasets[0].backgroundColor = ['#f84a51', '#4bc26e', '#d8dbdc'];
					// this.chartData.datasets[0].hoverBackgroundColor = ['#f84a51', '#4bc26e', '#d8dbdc'];
					// this.chartData.datasets[0].borderWidth = 0;
					this.isLoaded = true;
					this.noData = true;
					console.log("sss")
				} else {
					console.log("sss")
					this.noData = false;
				}
			});
		},
		filterData() {
			this.fetchTasksProcessedData(this.kpiDataFilter);
		}
	}
});
</script>
