<template>
  <div class="pt-3 pl-5 pr-5">
    <div v-if="isLoading">
      <div
        class="row w-full mb-3 justify-between flex"
        style="align-items: center"
      >
        <h1 class="title">Active KPI's</h1>
        <div class="cardbody">
          <div class="flex flex-row-reverse items-center">
            <button class="flex" @click="filterOpen" :class="columnObject ? 'filterButtondisabled pointer-events-none' : 'filterButton'">
              Filters
              <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
            </button>
          </div>
        </div>
      </div>
      <div>
        <tableData
          :tableRowData="tableRow"
          :headersData="headers"
          :departmentArr="deptOptions"
          :loadData="dataLoading"
        ></tableData>
        <div v-if="toolTipActive">
					<tool-tip :tipText="toolTipText"></tool-tip>
				</div>
      </div>
      <popup v-if="showPopup" :popUp="showPopup">
        <!-- {{allKpis}} -->
        <template v-slot:header>
          <div class="flex items-center justify-between py-4 px-2 rounded">
            <h1 class="pl-2">KPI Info</h1>
            <!-- <h1 class="pl-2" v-if="allKpis.ccid">
              {{ allKpis.ccid }}
            </h1>
            <h1 class="pl-2" v-else>{{ allKpis.description }}</h1> -->
            <button class="float-right text-3xl" @click="showPopup = false">
              <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
            </button>
          </div>
        </template>
        <fieldset>
          <div>
            <div>
              <div class="statuscontainer popupbodycontainer">
                <div class="formcontainer flex flex-col">
                  <!-- <div class="row flex flex-row w-full upperspacing"> -->
                   <div class="w-full flex flex-col upperspacing">
                        <label class="text-xs font-extrabold mb-2">Department</label>
                        <div class="text-sm">{{ allKpis.department }}</div>
                    </div>

                        <!-- </div> -->
                        <div class="w-full flex flex-col mt-3">
                          <label class="text-xs font-extrabold mb-2">CCID</label>
                          <div class="text-sm"> {{  allKpis.ccid  }}  </div>
                        </div>
                  <!-- <div class="row flex flex-row w-full upperspacing">
                    <div class="w-full">
                      <label class="controllabel">KPI ID</label>
                      <input
                        type="text"
                        class="inputboxstyling"
                        v-model="allKpis.kpi_id"
                        placeholder="KPI ID"
                        disabled
                      />
                    </div>
                  </div> -->
                  <div class="w-full flex flex-col upperspacing">
                      <label class="text-xs font-extrabold mb-2">KPI Name</label>
                     <div class="text-sm"> {{  allKpis.description  }}  </div>
                  </div>
                  
                  <!-- <div class="w-full flex flex-col mt-3">
                      <label class="text-xs font-extrabold mb-2">Department</label>
                     <div class="text-sm"> {{  allKpis.department  }}  </div>
                  </div> -->
                  <!-- <div class="row flex flex-row w-full upperspacing">
										<div class="w-full">
											<label class="controllabel">Departments</label>
											<div v-if="controlDepartmentArr.length > 0">
												<Multiselect
													v-model="controlDepartmentArr"
													mode="tags"
													:close-on-select="false"
													:classes="{
														container: 'relative h-fit mx-auto w-full flex items-center justify-end box-border cursor-pointer border border-lightgrey rounded bg-white text-base leading-snug outline-none'
													}"
													:options="deptOptions"
													:caret="false"
													:showOptions="false"
													:canClear="false"
													:disabled="true"
													placeholder="Departments"
												>
													<template v-slot:tag="{ option, handleTagRemove, disabled }">
														<div
															class="multiselect-tag"
															:class="{
																'is-disabled': disabled
															}"
														>
															{{ option.label }}
															<span v-if="!disabled" class="multiselect-tag-remove" @click="handleTagRemove(option, $event)">
																<span class="multiselect-tag-remove-icon"></span>
															</span>
														</div>
													</template>
												</Multiselect>
											</div> -->
                  <!-- <div v-else>
												<input class="inputboxstyling" :disabled="true" v-model="naDepartments" placeholder="Departments" />
											</div> -->
                  <!-- </div> -->
                  <!-- </div> -->
                  <div class="formcontainer flex flex-col upperspacing">
                    <div class="w-full flex flex-col">
                        <label class="text-xs font-extrabold mb-2">Weightage (%)</label>
                        <div class="mb-3 text-sm">{{ allKpis.weightage }}</div>
                      <!-- <div class="w-3/6 leftspacing">
												<label class="controllabel">Target Time Period</label>
												<input class="inputboxstyling" :disabled="true" v-model="allKpis.targetTimePeriod" placeholder="Target (%)" />
											</div> -->
                       
                    </div>
                  </div>

                  <!-- <h1 class="upperspacing" v-if="hideFormula && allKpis.metrics">Metrics</h1>
									
									<div class="row flex w-full gap-16" v-if="allKpis.metrics">
										<div class="w-4/6">
											
												<div v-for="(metric, metricIndex) in allKpis.metrics" :key="metricIndex">
													<label class="controllabel">{{ metric.key }}</label>
													<input type="text" class="inputboxstyling" :disabled="true" v-model="allKpis.metrics[metricIndex].label" />
												</div>
											
										</div>

										<div class="w-2/6 flex justify-center items-center" v-if="hideFormula">
											<div class="">
												<label class="controllabel font-normal text-sm mb-1">Formula</label>
												<input type="text" class="inputboxstyling" :disabled="true" v-model="allKpis.isformula" placeholder="Formula" />
											</div>
										</div>
									</div> -->
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </popup>
    </div>
    <!-- <loader v-if="showLoader" /> -->
  </div>
</template>
<style scoped>
.multiselect-tag {
  font-size: 12px;
  font-weight: 600;
}
.multiselect-tag.is-disabled {
  background-color: #059669 !important;
}
.multiselect.is-disabled {
  background-color: white !important;
}
</style>
<script lang="ts">
import * as XLSX from 'xlsx';
// import loader from "../../components/loader.vue";
import _ from "lodash";
import { defineComponent } from "vue";
import { emitter, toast } from "@/main";
import popup from "@/components/popup.vue";
import tableData from "@/components/tableData.vue";
import toolTip from "@/components/toolTip.vue";
// import Multiselect from '@vueform/multiselect/src/Multiselect';
export default defineComponent({
  data(): any {
    return {
      headers: ["_id", "departments", "CCID", "KPItitle", "KPI Name", "weightage (%)"],

      isLoading: false,
      showLoader: false,

      showFilters: false,
      hideFormula: false,
      showPopup: false,
      columnObject: false,
      toolTipActive : false,
			toolTipText : '',
      dummyList: [],
      allKpisList: [],
      tableRow: [],
      departmentsList: [],
      controlDepartmentArr: [],
      naDepartments: "",

      kpiList: [],
      deptOptions: [],
      allKpis: {
        kpi_id: "",
        weightage: "",
        description: [],
      },
      controlRecurringArray: [
        { label: "Daily", id: 1 },
        { label: "Weekly", id: 2 },
        { label: "Monthly", id: 3 },
        { label: "Quarterly", id: 4 },
        { label: "Half-Yearly", id: 5 },
        { label: "Yearly", id: 6 },
      ],
      dataLoading:true,
    };
  },
  components: {
    tableData,
    // loader,
    popup,
    toolTip
    // Multiselect
  },
  async mounted() {
    this.dataLoading = true;
    await this.fetchDepartmentsList();
    await this.fetchAllKpisInfo();
    emitter.on("showKpisViewData", (isData: any) => {
      let comingId = isData.entityData._id;
      let showObj = this.kpiList.find((obj: any) => {
        return obj._id === comingId;
      });
      this.showEditPopUp(showObj, isData.indexx);
    });
    await this.makeTable();
    this.dataLoading = false;
    emitter.off('activiteToolTip');
		emitter.on('activiteToolTip', (data: any) => {
			this.toolTipActive = true;
			this.toolTipText = data.tipText
		});
		emitter.off('deActiviteToolTip');
		emitter.on('deActiviteToolTip', (data: any) => {
			this.toolTipActive = false;
			this.toolTipText = ''
		});
  },
  methods: {
    showEditPopUp(obj: any, index: any) {
      this.showPopup = true;
      this.allKpis = { ...obj };
      this.hideFormula = true;
      // if (this.tableRow[index].departments == 'N/A') {
      // 	this.naDepartments = this.tableRow[index].departments;
      // } else {
      // 	this.controlDepartmentArr = this.tableRow[index].departments;
      // }
      // if (Number.isInteger(obj.targetTimePeriod)) {
      // 	this.allKpis.targetTimePeriod = this.controlRecurringArray.find((itemOne: any) => {
      // 		return itemOne.id == obj.targetTimePeriod;
      // 	}).label;
      // }
      // if (Number.isInteger(obj.reportingFrequency)) {
      // 	this.allKpis.reportingFrequency = this.controlRecurringArray.find((itemOne: any) => {
      // 		return itemOne.id == obj.reportingFrequency;
      // 	}).label;
      // }
    },
    filterOpen() {
      if (this.columnObject == false) {
        this.showFilters = !this.showFilters;
        emitter.emit("visible", this.showFilters);
      }
    },
    async fetchAllKpisInfo() {
      try {
        await this.$http
          .get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/kpis/getall`)
          .then((result: { data: any }) => {
            this.isLoading = true;
            this.showLoader = true;
            this.allKpisList = result.data;
            console.log("allKpisList", this.allKpisList);
            _.each(
              this.allKpisList,
              (kpi: {
                kpis: any;
                ccid: any;
                controlTitle: any;
                departments: any;
              }) => {
                if (kpi.kpis) {
                  _.each(
                    kpi.kpis,
                    (kpi: {
                      ccid: any;
                      targetTimePeriod: any;
                      reportingFrequency: any;
                      controlTitle: any;
                      departments: any;
                    }) => {
                      // kpi.departments = kpi.departments;
                      this.kpiList.push(kpi);
                    }
                  );
                  this.dummyList = this.kpiList;
                }
              }
            );
          });
      } catch (e) {
        toast.error("error", {
          timeout: 1000,
          closeOnClick: true,
          closeButton: "button",
          icon: true,
        });
      }
      this.showLoader = false;
    },
    async fetchDepartmentsList() {
      (this.showLoader = true),
        //  (this.isLoading = false);
        await this.$http
          .get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`)
          .then((res: { data: any }) => {
            this.isLoading = true;
            this.showLoader = false;
            this.departmentsList = res.data;
          })
          .catch((error: any) => {
            this.isLoading = false;
            (this.showLoader = false),
              toast.error(`Save failed with ${error}`, {
                timeout: 1000,
                closeOnClick: true,
                closeButton: "button",
                icon: true,
              });
          });
    },
    makeTable() {
      this.kpiList.forEach((kpi: any) => {
        this.departmentsList.forEach((eachdepartment: any) => {
          if (kpi.department == eachdepartment._id) {
            kpi.department = eachdepartment.teamName;
          }
        });
        kpi.isformula = "A/B";
        this.deptOptions = this.departmentsList.map((item: any) => {
          return { value: item._id, label: item.teamName };
        });
        let finalKPIObj: any = {
          _id: kpi._id,
          departments: kpi.department == undefined || kpi.department == '' ? "N/A" : kpi.department ,
          // number: kpi.controlNumber != undefined ? kpi.controlNumber : "N/A",
          KPItitle: kpi.controlTitle != undefined ? kpi.controlTitle : "N/A",
          CCID: kpi.ccid != undefined ? kpi.ccid : "N/A",
          // "KPI ID": kpi.kpi_id != undefined ? kpi.kpi_id : "N/A",
          "KPI Name": kpi.description != undefined ? kpi.description : "N/A",
          "weightage (%)": parseInt(kpi.weightage),
          // 'Target (%)': parseInt(kpi.target),
          // metrics: kpi.metrics,
          // 'Target Time Period': Number(kpi.targetTimePeriod) ? parseInt(kpi.targetTimePeriod) : kpi.targetTimePeriod,
          // 'Reporting Frequency': Number(kpi.reportingFrequency) ? parseInt(kpi.reportingFrequency) : kpi.reportingFrequency,
          // isformula: kpi.isformula
        };
        this.tableRow.push(finalKPIObj);
      });
      // if (this.tableRow.length == 0) {
      //   let finalKPIObj = {
      //     departments: "",
      //     CCID: "",
      //     // "KPI ID": '',
      //     "KPI Name": "",
      //     "weightage (%)": "",
      //     // 'Target (%)': '',
      //     // 'Target Time Period': '',
      //     // 'Reporting Frequency': ''
      //   };
      //   this.tableRow.push(finalKPIObj);
      // }
      if (this.kpiList && this.kpiList.length == 0) {
        this.columnObject = true;
      } else {
        this.columnObject = false;
      }
    },
  },
});
</script>

