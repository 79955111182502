<template>
	<div class="pt-3 pl-5 pr-5">
		<div v-if="isLoading">
			<!-- {{ controlsList }} -->
			<div class="row w-full justify-between flex" style="align-items: center">
				<h1 class="title">{{ label }}</h1>
				<input type="file" accept=".xls,.xlsx" class="hidden" ref="file" @change="fileReader($event)" />
				<div class="flex flex-row-reverse">
					<button class="flex" @click="filterOpen" :class="!columnObject ? 'filterButton' : 'filterButtondisabled pointer-events-none'">
						Filters
						<img src="@/assets/filter.svg" class="h-3 ml-2.5" />
					</button>
					<div class="flex flex-row-reverse mr-2 relative">
						<button class="btnprimary btns flex justify-center items-center" @click="showBlock = !showBlock">Add <img src="@/assets/Collapse.svg" alt="" class="ml-2" /></button>
						<ul class="card shadow-sm z-50 absolute cursor-pointer top-10" v-if="showBlock && showDropdown">
							<li class="px-4 py-2 cursor-pointer border-b h-7 border-lightgrey hover:bg-lightgrey text-sm flex items-center" @click="openFileModal">
								<div>Import</div>
							</li>
							<li class="px-4 py-2 cursor-pointer border-b h-7 border-lightgrey hover:bg-lightgrey text-sm flex items-center" @click="addControls">
								<div>Create</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<tableData :tableRowData="tableRow"  :headersData="headers" :tableRouteArr="optionsValue" :loadData="dataLoading"></tableData>
            <div v-if="toolTipActive">
                <tool-tip :tipText="toolTipText"></tool-tip>
            </div>
			<popup class="" v-if="showModal" :popUp="showModal">
				<template v-slot:header>
					<div class="flex items-center justify-between py-4 px-2 rounded">
						<h1 class="pl-2">Internal Control</h1>
						<button class="float-right text-3xl" @click="onCancelbtn('close')">
							<img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
						</button>
					</div>
				</template>
				<div>
					<div class="statuscontainer popupbodycontainer">
						<div class="formcontainer flex flex-col">
							<fieldset>
								<div class="row flex flex-row w-full mt-3">
									<div class="w-full">
										<label class="controllabel">Title</label>
										<textarea type="text" class="inputboxstyling mt-2 mt-2" placeholder="Title" v-model.trim="controlObject.controlTitle" 
										@input="v$.controlObject.controlTitle.$touch()"
										:class="{
												'is-invalid': v$.controlObject.controlTitle.$error
											}"
											/>
										<div v-if="v$.controlObject.controlTitle.$error" class="text-red-500">
											<div class="error-text" v-if="v$.controlObject.controlTitle.required.$invalid">Required</div>
											<div v-else-if="v$.controlObject.controlTitle.required && v$.controlObject.controlTitle.restrictCharacters100.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
										</div>
									</div>
								</div>
								<div class="row flex flex-row w-full mt-3">
									<div class="w-6/12">
										<label class="controllabel">Number</label>
										<input
											type="text"
											class="inputboxstyling mt-2"
											v-model.trim="controlObject.controlNumber"
											placeholder="Number"
											@input="v$.controlObject.controlNumber.$touch()"
											:class="{
												'is-invalid': v$.controlObject.controlNumber.$error || checkControlNumber
											}"
										/>
										<!-- @change="controlNumberUnique(controlObject)" -->
										<div v-if="v$.controlObject.controlNumber.$error" class="text-red-500">
											<div class="error-text" v-if="v$.controlObject.controlNumber.required.$invalid">Required</div>
											<div v-else-if="v$.controlObject.controlNumber.required && v$.controlObject.controlNumber.restrictCharacters8.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
										</div>
										<div v-else-if="checkControlNumber" class="text-red-500">
											<div class="error-text">Control Number already exists</div>
										</div>
									</div>

									<div class="w-3/6 leftspacing">
										<label class="controllabel">Frequency</label>
										<select class="inputboxstyling mt-2" :class="{ 'is-invalid': v$.controlObject.controlFrequency.$error }" v-model="controlObject.controlFrequency">
											<option value="" selected disabled hidden>Select</option>
											<option v-for="(value, index) in controlFrequency" :key="index" :value="value.id">{{ value.label }}</option>
										</select>
										<div v-if="v$.controlObject.controlFrequency.$error" class="text-red-500">
											<div class="error-text" v-if="v$.controlObject.controlFrequency.required.$invalid">Required</div>
										</div>
									</div>
								</div>
								<div class="row flex flex-row w-full mt-3">
									<div class="w-6/12">
										<label class="controllabel">Type</label>
										<input
											type="text"
											class="inputboxstyling mt-2"
											v-model.trim="controlObject.controlType"
											placeholder="Type"
											@input="v$.controlObject.controlType.$touch()"
											:class="{
												'is-invalid': v$.controlObject.controlType.$error
											}"
										/>

										<!-- <select class="inputboxstyling mt-2" :class="{ 'is-invalid': v$.controlObject.controlType.$error }" v-model="controlObject.controlType">
											<option value="" selected disabled hidden>Select</option>
											<option v-for="(value, index) in controlTypeArray" :key="index" :value="value.id">{{ value.label }}</option>
										</select> -->
										<!-- :class="{
												'is-invalid': v$.controlObject.controlType.$error
											}" -->
										<div v-if="v$.controlObject.controlType.$error" class="text-red-500">
											<!-- <div class="error-text" v-if="v$.controlObject.controlType.required.$invalid">Required</div> -->
											<div v-if="v$.controlObject.controlType.restrictCharacters30.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
										</div>
									</div>
									<div class="w-3/6 leftspacing">
										<label class="controllabel">Priority</label>
										<select class="inputboxstyling mt-2" v-model="controlObject.controlPriority">
											<option value="" selected disabled hidden>Select</option>
											<option v-for="(value, index) in priorityArray" :key="index" :value="value.id">{{ value.label }}</option>
										</select>

										<!-- :class="{ 'is-invalid': v$.controlObject.controlPriority.$error }"
										<div v-if="v$.controlObject.controlPriority.$error" class="text-red-500">
											<div class="error-text" v-if="v$.controlObject.controlPriority.required.$invalid">Required</div>
										</div> -->
									</div>
								</div>
								<div class="row flex flex-row w-full mt-3">
									<div class="w-6/12">
										<label class="controllabel">Family</label>
										<input
											type="text"
											class="inputboxstyling mt-2"
											placeholder="Family"
											v-model.trim="controlObject.controlFamily"
											@input="v$.controlObject.controlFamily.$touch()"
											:class="{
												'is-invalid': v$.controlObject.controlFamily.$error
											}"
										/>

										<!-- :class="{
												'is-invalid': v$.controlObject.controlFamily.$error
											}" -->
										<div v-if="v$.controlObject.controlFamily.$error" class="text-red-500">
											<!-- <div class="error-text" v-if="v$.controlObject.controlFamily.required.$invalid">Required</div> -->
											<div v-if="v$.controlObject.controlFamily.restrictCharacters30.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
										</div>
									</div>
									<div class="w-3/6 leftspacing">
										<label class="controllabel">Class</label>
										<input
											type="text"
											class="inputboxstyling mt-2"
											placeholder="Class"
											v-model.trim="controlObject.controlClass"
											@input="v$.controlObject.controlClass.$touch()"
											:class="{
												'is-invalid': v$.controlObject.controlClass.$error
											}"
										/>

										<!-- :class="{
												'is-invalid': v$.controlObject.controlClass.$error
											}" -->
										<div v-if="v$.controlObject.controlClass.$error" class="text-red-500">
											<!-- <div class="error-text" v-if="v$.controlObject.controlClass.required.$invalid">Required</div> -->
											<div v-if="v$.controlObject.controlClass.restrictCharacters30.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
										</div>
									</div>
								</div>
								<div class="row flex flex-row w-full mt-3">
									<div class="w-full">
										<label class="controllabel">Audit Methodology</label>
										<textarea 
											type="text-area" 
											class="inputboxstyling mt-2" 
											placeholder="Audit Methodology" 
											@input="v$.controlObject.controlAuditMethodology.$touch()" 
											v-model.trim="controlObject.controlAuditMethodology"
											:class="{
												'is-invalid': v$.controlObject.controlAuditMethodology.$error
											}"
										> 
										</textarea>
										<div v-if="v$.controlObject.controlAuditMethodology.$error" class="text-red-500">
											<!-- <div class="error-text" v-if="v$.controlObject.controlAuditMethodology.required.$invalid">Required</div> -->
											<div v-if="v$.controlObject.controlAuditMethodology.restrictCharacters300.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
										</div>
									</div>
								</div>
								<div class="row flex flex-row w-full mt-3">
									<div class="w-full">
										<label class="controllabel">Audit Success Criteria</label>
										<textarea 
											type="text-area" 
											class="inputboxstyling mt-2"
											placeholder=" Audit Success Criteria" 
											@input="v$.controlObject.controlAuditSuccessCriteria.$touch()" 
											v-model.trim="controlObject.controlAuditSuccessCriteria"
											:class="{
												'is-invalid': v$.controlObject.controlAuditSuccessCriteria.$error
											}"
										> 
										</textarea>
										<div v-if="v$.controlObject.controlAuditSuccessCriteria.$error" class="text-red-500">
											<!-- <div class="error-text" v-if="v$.controlObject.controlAuditSuccessCriteria.required.$invalid">Required</div> -->
											<div v-if="v$.controlObject.controlAuditSuccessCriteria.restrictCharacters300.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
										</div>
									</div>
								</div>
								<div class="row flex flex-row w-full mt-3">
									<div class="w-full">
										<label class="controllabel">Maintenance Task</label>
										<textarea 
											type="text-area" 
											class="inputboxstyling mt-2" 
											placeholder="Maintenance Task" 
											@input="v$.controlObject.controlMaintenanceTask.$touch()" 
											v-model.trim="controlObject.controlMaintenanceTask"
											:class="{
												'is-invalid': v$.controlObject.controlMaintenanceTask.$error
											}"
										> 
										</textarea>
										<div v-if="v$.controlObject.controlMaintenanceTask.$error" class="text-red-500">
											<!-- <div class="error-text" v-if="v$.controlObject.controlMaintenanceTask.required.$invalid">Required</div> -->
											<div v-if="v$.controlObject.controlMaintenanceTask.restrictCharacters300.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
										</div>
									</div>
								</div>
								<div class="row flex flex-row w-full mt-3">
									<div class="w-full">
										<label class="controllabel">Policies</label>
										<textarea 
											type="text-area" 
											class="inputboxstyling mt-2" 
											placeholder="Policies" 
											@input="v$.controlObject.controlPolicies.$touch()" 
											v-model.trim="controlObject.controlPolicies"
											:class="{
												'is-invalid': v$.controlObject.controlPolicies.$error
											}"
										> 
										</textarea>
										<div v-if="v$.controlObject.controlPolicies.$error" class="text-red-500">
											<!-- <div class="error-text" v-if="v$.controlObject.controlPolicies.required.$invalid">Required</div> -->
											<div v-if="v$.controlObject.controlPolicies.restrictCharacters300.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
										</div>
									</div>
								</div>
								<div class="row flex flex-row w-full mt-3">
									<div class="w-full">
										<label class="controllabel">Guidance</label>
										<textarea 
											type="text-area" 
											class="inputboxstyling mt-2" 
											placeholder="Control Guidance" 
											@input="v$.controlObject.controlGuidance.$touch()" 
											v-model.trim="controlObject.controlGuidance"
											:class="{
												'is-invalid': v$.controlObject.controlGuidance.$error
											}"
										> 
										</textarea>
										<div v-if="v$.controlObject.controlGuidance.$error" class="text-red-500">
											<!-- <div class="error-text" v-if="v$.controlObject.controlGuidance.required.$invalid">Required</div> -->
											<div v-if="v$.controlObject.controlGuidance.restrictCharacters300.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
										</div>
									</div>
								</div>
								<div class="w-full col-md-6 mt-3">
									<label class="controllabel">Frameworks</label>

									<select class="inputboxstyling mt-2" @change="getFrameworkControls(controlObject.controlOptionFrameWork)" v-model="controlObject.controlOptionFrameWork">
										<option value="" selected disabled hidden>Select</option>
										<option v-for="data in frameWorksList" :key="data.label" :value="data.label">{{ data.label }}</option>
									</select>
									<!-- <div v-if="v$.controlObject.controlOptionFrameWork.$error" class="text-red-500">
										<div class="error-text" v-if="v$.controlObject.controlOptionFrameWork.required.$invalid">Required</div>
									</div> -->
								</div>
								<div class="w-full col-md-6 mt-3">
									<label class="controllabel">Controls</label>
									<select class="inputboxstyling mt-2" v-model="controlObject.frameworkControls">
										<option value="" selected disabled hidden>Select</option>
										<option v-for="data in frameworkControls" :key="data.control_title" :value="data.control_title">{{ data.control_title }}</option>
									</select>
									<!-- <div v-if="v$.controlObject.frameworkControls.$error" class="text-red-500">
										<div class="error-text" v-if="v$.controlObject.frameworkControls.required.$invalid">Required</div>
									</div> -->
								</div>
							</fieldset>
							<!-- <div class="border-b mt-4 mb-2 border-solid border-lightgrey"></div> -->
							<!-- <div class="row flex flex-row w-full mt-3"> -->
							<!-- <div class="w-full"> -->
							<!-- <label class="controllabel">Version</label>
									<input
										type="number"
										class="inputboxstyling"
										placeholder="Version"
										v-model="controlObject.version"
										:class="{
											'is-invalid': v$.controlObject.version.$error
										}"
									/>
									<div v-if="v$.controlObject.version.$error" class="text-red-500">
										<div class="error-text" v-if="v$.controlObject.version.required.$invalid">Required</div>
									</div> -->
							<!-- <label class="controllabel">Framework</label>
									<select class="inputboxstyling" @change="mappedFrameWork(controlObject)">
										<option value="" selected disabled hidden>Select</option>
										<option v-for="(value, index) in framework" :key="index" :value="value">{{ value }}</option>
									</select> -->
							<!-- :class="{ 'is-invalid': v$.controlObject.controlFrameWork.$error }" v-model="controlObject.controlFrameWork"> -->
							<!-- <div v-if="v$.controlObject.controlFrameWork.$error" class="text-red-500">
										<div class="error-text" v-if="v$.controlObject.controlFrameWork.required.$invalid">Required</div>
									</div> -->
							<!-- </div> -->
							<!-- </div> -->
							<!-- <div class="row flex flex-row w-full mt-3"> -->
							<!-- <div class="w-full"> -->
							<!-- <label class="controllabel">Year</label>
									<select class="inputboxstyling" :class="{ 'is-invalid': v$.controlObject.year.$error }" v-model="controlObject.year">
										<option value="" selected disabled hidden>Select</option>
										<option v-for="(value, index) in groupedControlsYear" :key="index" :value="value.label">{{ value.label }}</option>
									</select> -->
							<!-- <Multiselect
										v-model="controlObject.year"
										:options="groupedControlsYear"
										:searchable="true"
										:minChars="1"
										:filterResults="true"
										class="multiselect-custom"
										:classes="{ singleLabelText: 'text-sm' }"
										:canClear="true"
										:caret="false"
										placeholder="Year"
									></Multiselect> -->
							<!-- :class="{
											'is-invalid borderRed': v$.controlObject.controlMapFrameWork.$error
										}" -->
							<!-- <Multiselect
										v-model="controlObject.controlMapFrameWork"
										:options="groupedFramewoks"
										:searchable="true"
										:minChars="1"
										:filterResults="true"
										class="multiselect-custom"
										:classes="{ singleLabelText: 'text-sm' }"
										:canClear="true"
										:caret="false"
										placeholder="Mapped Framework Control"
									></Multiselect> -->
							<!-- :class="{
											'is-invalid borderRed': v$.controlObject.controlMapFrameWork.$error
										}" -->
							<!-- <div v-if="v$.controlObject.year.$error" class="text-red-500">
										<div class="error-text" v-if="v$.controlObject.year.required.$invalid">Required</div>
									</div> -->
							<!-- </div> -->
							<!-- </div> -->
						</div>
						<div class="buttonposition flex justify-center items-center my-4 mx-0">
							<button class="btnprimary mr-1.5" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="onCancelbtn(controlObject._id)">Cancel</button>
							<button class="btnprimary" v-if="!controlObject._id" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="saveCustomControl(controlObject)">Save</button>
							<button class="btnprimary" v-else :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="updateControl(controlObject)">Update</button>
						</div>
					</div>
				</div>
			</popup>
		</div>
		<!-- <loader v-if="showLoader" /> -->
	</div>
</template>

<style scoped>
.borderRed {
	border: 1px solid red !important;
}
::-webkit-scrollbar {
	width: 3px !important;
}
</style>
<script lang="ts">
import _ from 'lodash';
import * as XLSX from 'xlsx';
// import loader from '../../components/loader.vue';
import { defineComponent } from 'vue';
import { emitter, toast } from '../../main';
import { helpers } from '@vuelidate/validators';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import popup from '@/components/popup.vue';
import tableData from '@/components/tableData.vue';
import toolTip from "@/components/toolTip.vue";
const restrictCharacters100 = helpers.regex(/^.{0,100}$/);
const restrictCharacters300 = helpers.regex(/^.{0,300}$/);
const restrictCharacters30 = helpers.regex(/^.{0,30}$/);
const restrictCharacters8 = helpers.regex(/^.{0,8}$/);
// import Multiselect from '@vueform/multiselect';
export default defineComponent({
	data(): any {
		return {
			headers:['_id','number','title','family','class','no.of Kpis', 'actionButtonForKPIs'],

			v$: useVuelidate(),
			isLoading: false,
			showLoader: false,

			priority: ['Critical', 'High', 'Moderate', 'Low'],
			priorityArray: [
				{ label: 'Very Low', id: 1 },
				{ label: 'Low', id: 2 },
				{ label: 'Moderate', id: 3 },
				{ label: 'High', id: 4 },
				{ label: 'Critical', id: 5 }
			],
			classes: [],
			tableRow: [],
			nonTableRow: [],
			editObject: {},
			frameWorksList: [],
			frameworkControls: [],
			framework: [],
			showModal: false,
			showBlock: false,
			showDropdown: false,
			controlFrequency: [
				{ label: 'Transactional', id: 1 },
				{ label: 'Daily', id: 2 },
				{ label: 'Weekly', id: 3 },
				{ label: 'Monthly', id: 4 },
				{ label: 'Quarterly', id: 5 },
				{ label: 'Semi Annualy', id: 6 },
				{ label: 'Annualy', id: 7 },
				{ label: 'Ad-Hoc', id: 8 }
			],

			label: '',
			toolTipActive : false,
			toolTipText : '',
			dataLoading:true,

			controlSet: '',
			frameworkArray: [],
			jsonArray: [],
			columnObject: false,

			showFilters: false,
			controlTypeArray: [
				{ label: 'Preventive', id: 1 },
				{ label: 'Detective', id: 2 },
				{ label: 'Corrective', id: 3 },
				{ label: 'Deterrent', id: 4 },
				{ label: 'Directive', id: 5 },
				{ label: 'Recovery', id: 6 },
				{ label: 'Compensating', id: 7 }
			],
			optionsValue: [
				{
					id: 1,
					value: 'KPI',
					displayIcon: 'mapIcon',
					presentRoute: '/allControls'
				}
			],
			controlsList: [],
			dummyControlList: [],
			frameworkMappedArray: [],
			groupedFramewoks: [],
			groupedControlsYear: [],
			controlObject: {
				controlFrameWork: '',
				controlMapFrameWork: '',
				controlNumber: '',
				controlPriority: '',
				controlTitle: '',
				controlClass: '',
				controlFamily: '',
				controlObjective: '',
				controlGuidance: '',
				controlAuditMethodology: '',
				controlAuditSuccessCriteria: '',
				controlMaintenanceTask: '',
				controlPolicies: '',
				controlType: '',
				// version: '',
				// year: '',
				controlFrequency: '',
				controlOptionFrameWork: '',
				frameworkControls: []
			},
			allControlNumbers:[],
			controlNumberExcludingSelectedControlNumber:[],
			mandatoryKeys: ['title', 'controlObjective', 'controlAuditMethodology ', 'controlAuditSuccessCriteria', 'controlMaintenanceTask', 'controlPolicies', 'number', 'family', 'priority', 'class', 'controlGuidance', 'type'],
			fullKeys: ['controlNumber', 'controlTitle', 'controlGuidance', 'controlClass', 'controlFamily', 'controlType', 'controlPriority', 'controlFrameWork']
		};
	},
	validations() {
		return {
			controlObject: {
				// controlFrameWork: { required },
				// controlMapFrameWork: { required },
				controlTitle:{required, restrictCharacters100},
				controlNumber: { required, restrictCharacters8 },
				// controlPriority: { required },
				// controlTitle: { required },
				controlClass: { restrictCharacters30 },
				controlFamily: { restrictCharacters30 },
				// controlObjective: { required },
				controlGuidance: { restrictCharacters300 },
				controlAuditMethodology: { restrictCharacters300 },
				controlAuditSuccessCriteria: { restrictCharacters300 },
				controlMaintenanceTask: { restrictCharacters300 },
				controlPolicies: { restrictCharacters300 },
				controlType: { restrictCharacters30 },
				// version: { required },
				// year: { required },
				controlFrequency: { required }
			}
		}
	},
	components: {
		popup,
		tableData,
		toolTip
		// Multiselect,
		// loader
	},
	async created() {
		this.dataLoading = true;
		await this.rootFetchControlsInfo();
		emitter.off('activiteToolTip');
        emitter.on('activiteToolTip', (data: any) => {
            this.toolTipActive = true;
            this.toolTipText = data.tipText
       });
        emitter.off('deActiviteToolTip');
        emitter.on('deActiviteToolTip', (data: any) => {
            this.toolTipActive = false;
            this.toolTipText = ''
        });
		emitter.on('close-dropdown',()=>{this.showBlock = false}) 
	},
	async mounted() {
		await this.getAllControls();
		
		this.label = 'Internal Controls';
		emitter.on('rootControlsPopup', (data: any) => {
			console.log('qwerty',data)
			let dataModified = this.controlsList.find((item: any) => {
				return item._id == data.entityData._id;
			});
			if(dataModified && dataModified.controlFrequency == undefined || dataModified.controlFrequency == null || dataModified.controlFrequency == ''){
				dataModified.controlFrequency = ''
			} if(dataModified.frameworkControls == undefined || dataModified.frameworkControls == null || dataModified.frameworkControls == ''){
				dataModified.frameworkControls = ''
			}if(dataModified.controlOptionFrameWork == undefined || dataModified.controlOptionFrameWork == null || dataModified.controlOptionFrameWork == ''){
				dataModified.controlOptionFrameWork = ''
			} if(dataModified.controlPriority == undefined || dataModified.controlPriority == null || dataModified.controlPriority == ''){
				dataModified.controlPriority = ''
			} 
			this.showEditPopUp(dataModified);
		});
		this.dataLoading = false;
		emitter.on('*', (type, booleanValue) => {
			if (type == 'apps-sidemenubar' || type == 'toggle-sidebar') {
				this.showBlock == true ? ((this.showBlock = false), (this.showDropdown = false)) : '';
			} else if( type == 'deActiviteToolTip' || type == 'activiteToolTip'){
                return ;
            } else {
				if (this.showBlock == true) {
					if (this.showDropdown == false) {
						this.showDropdown = true;
					} else {
						this.showDropdown = false;
						this.showBlock = false;
					}
				} else {
					this.showDropdown = false;
				}
			}
		});
	},
	 beforeUnmount() {
		 sessionStorage.removeItem('selectedTab')
	 },
	computed: {
		disableStatus: function (): any {
			let disableStatusCondition;
			if (this.controlObject._id) {
				if (JSON.stringify(this.controlObject) == JSON.stringify(this.editObject)) {
					disableStatusCondition = false;
				} else {
					disableStatusCondition = true;
				}
			} else {
				let val: any = [];
				Object.values(this.controlObject).forEach((value: any) => {
					val.push(value);
				});
				for (let n of val) {
					if (n != '') {
						disableStatusCondition = true;
					}
				}
			}
			return disableStatusCondition;
		},
		checkControlNumber: function (): any {
			const trimmedControlNumber = this.controlObject.controlNumber.trim().toLowerCase().replace(/\s+/g, '');

			if (this.allControlNumbers == null || this.allControlNumbers == undefined || this.allControlNumbers.length == 0) {
				return false;
			}

			if (!this.controlObject._id) {
				let a = false;
				a = this.allControlNumbers.map((name: any) => name.toLowerCase().replace(/\s+/g, '')).includes(trimmedControlNumber);
				return a;
			} else if (this.controlObject._id) {
				let a = false;
				a = this.controlNumberExcludingSelectedControlNumber.map((name: any) => name.toLowerCase().replace(/\s+/g, '')).includes(trimmedControlNumber);
				return a;
			}
		}

	},
	methods: {
		addControls() {
			this.v$.$reset();
			this.editObject = {};
			Object.keys(this.controlObject).forEach((prop) => {
				this.controlObject[prop] = '';
			});
			delete this.controlObject._id;
			this.showModal = true;
		},
		getFrameworkControls(name: any) {
			console.log('name is ', name);
			this.frameworkControls = [];
			let array = Object.values(this.jsonArray);
			array.forEach((x: any) => {
				x.forEach((y: any) => {
					if (y.controlFrameWork == name) {
						this.frameworkControls.push({ frameWorkName: name, control_number: y.controlNumber, control_title: y.controlTitle });
					}
				});
			});
		},
		mappedFrameWork(data: any) {
			this.controlObject.controlMapFrameWork = [];
			this.frameworkMappedArray = this.groupBy(this.controlsList, 'frameworkgroupBy');
			let frameArr: any = [];
			Object.keys(this.frameworkMappedArray).forEach((frame: any) => {
				frameArr = Object.values(this.frameworkMappedArray[frame]);
			});
			this.groupedFramewoks = [];
			frameArr.forEach((control: any) => {
				if (control.controlNumber) {
					this.groupedFramewoks.push({ value: control._id, label: control.controlNumber });
				}
			});
		},
		showEditPopUp(data: any) {
			this.controlNumberExcludingSelectedControlNumber = []
			if(data != undefined){
				this.controlsList.map((control:any)=>{
					if(control._id != data._id){
						this.controlNumberExcludingSelectedControlNumber.push(control.controlNumber)
					}
				})
			}
			this.mappedFrameWork(data);
			this.v$.$reset();
			this.controlObject = { ...data };
			this.editObject = { ...data };
			this.showModal = true;
		},
		onCancelbtn(status: any) {
			this.v$.$reset();
			this.showBlock = false;
			if (status === 'close') {
				this.showModal = false;
			} else if (status) {
				this.controlObject = { ...this.editObject };
			} else {
				this.addControls();
			}
		},
		filterOpen() {
			if (this.columnObject == false) {
				this.showFilters = !this.showFilters;
				emitter.emit('visible', this.showFilters);
			}
		},
		saveCustomControl(controlObject: any) {
			this.v$.$touch();
			// console.log("this.allControlNumbers",this.allControlNumbers)
			if (!this.v$.controlObject.$invalid && this.checkControlNumber == false) {
				let uniqueControlNumber: any;
				// uniqueControlNumber = this.controlsList.some((item: any) => item.controlNumber === controlObject.controlNumber);
				console.log('let uniqueControlNumber', uniqueControlNumber);
				// if (uniqueControlNumber == false) {
					this.showModal = false;
					let payload;
					let framework = 'InternalControls';
					payload = {
						controlFrameWork: framework,
						controlsList: [{ ...controlObject, version: 1, year: 2024, isInternalControl: true }]
					};
					this.addControlSource([payload]);
				// } else {
				// 	this.showModal = false;
				// 	toast.error(`Control Number should be unique`, {
				// 		timeout: 1000,
				// 		closeOnClick: true,
				// 		closeButton: 'button',
				// 		icon: true
				// 	});
				// }
			}
		},
		async updateControl(controlObject: any) {
			this.v$.$touch();
			if (!this.v$.$invalid && this.checkControlNumber == false) {
				this.showModal = false;
				let objId = controlObject._id;
				delete controlObject._id;
				try {
					await this.$http.post(`${process.env.VUE_APP_CONTROLS_API_URL}/api/root/control/${objId}/update?frameWork=${'InternalControls'}`, controlObject).then(async (result: any) => {
						await this.rootFetchControlsInfo();
						toast.info(`Updated Successfully`, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
					});
					// await this.getAllControls();
					
				} catch (e) {
					toast.error(`Something went wrong`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				}
			}
		},
		makeTable() {
			this.dataLoading = true;
			this.tableRow = [];
			if (this.controlsList.length > 0) {
				this.controlsList.forEach((item: any) => {
					this.nonTableRow.push(item);
					let kpisLength;
					Object.keys(item).includes('kpis') ? (kpisLength = item.kpis.length) : (kpisLength = 0);
					this.tableRow.push({ _id: item._id, number: item.controlNumber ? item.controlNumber : 'N/A', title: item.controlTitle?item.controlTitle : 'N/A', family: item.controlFamily ? item.controlFamily : 'N/A', class: item.controlClass ? item.controlClass : 'N/A', 'no.of Kpis': kpisLength ? kpisLength : 0 });
					this.dataLoading = false;
					if (item.controlFrameWork) {
						this.frameworkArray.push(item.controlFrameWork);
					}
				});
			} else {
				this.tableRow.push({ _id: '', number: '', title: '', family: '', class: '', 'no.of Kpis': '' });
				this.dataLoading = false;
			}
			if (this.controlsList.length == 0) {
				this.columnObject = true;
			} else {
				this.columnObject = false;
			}
			if (this.frameworkArray) {
				console.log('framework122', this.frameworkArray);
				this.framework = new Set(this.frameworkArray);
				console.log('framework', this.framework);
			} else {
				let frameArt = ['NIST', 'IST', 'HIPPA'];
				this.framework = new Set(frameArt);
				// let randomFramework = frameArt[Math.floor(Math.random() * frameArt.length)];
				// this.controlObject.controlFrameWork = randomFramework;
			}
			this.dataLoading = false;
		},
		async rootFetchControlsInfo() {
			this.controlsList = [];
			this.allControlNumbers = [];
			this.showLoader = true;
			const uniqueYears = new Set();
			await this.$http
				.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/root/frameWorkcontrols/getall?frameWork=${'InternalControls'}`)
				.then((result: { data: any }) => {
					this.isLoading = true;
					this.showLoader = false;
					this.controlsList = result.data;
					this.dummyControlList = [...this.controlsList]
					this.controlsList.map((control: any) => {
						if (control.year) {
							uniqueYears.add(control.year);
						}
						this.allControlNumbers.push(control.controlNumber)
					});
				})
				.catch((error: any) => {
					this.isLoading = false;
					(this.showLoader = false), (this.controlsList = []);
					toast.error(`Save failed with ${error}`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
			const currentYear = new Date().getFullYear();
			if (!uniqueYears.has(currentYear)) {
				uniqueYears.add(currentYear);
			}
			this.groupedControlsYear = Array.from(uniqueYears)
				.sort()
				.map((year) => ({ value: year, label: year }));
			await this.makeTable();
		},
		groupBy(arr: any[], key: string | number) {
			if (key == 'frameworkgroupBy' || key == 'controlFrameWork') {
				return arr.reduce((group: any, product: any) => {
					const { controlFrameWork } = product;
					group[controlFrameWork] = group[controlFrameWork] ?? [];
					group[controlFrameWork].push(product);
					return group;
				}, {});
			} else {
				return arr.reduce((storage: { [x: string]: any[] }, item: { [x: string]: any }) => {
					if (_.every(this.mandatoryKeys, _.partial(_.has, item))) {
						var group = item[key];
						storage[group] = storage[group] || [];
						storage[group].push(item);
					}
					return storage;
				}, {});
			}
		},
		groupByFrameworks(arr: any[]) {
			return arr.reduce((group: any, product: any) => {
				const { controlFrameWork } = product;
				group[controlFrameWork] = group[controlFrameWork] ?? [];
				group[controlFrameWork].push(product);
				return group;
			}, {});
		},
		async getAllControls() {
			emitter.emit('visible', false);
			this.showFilters = false;
			try {
				this.isLoading = false;
				const result = await this.$http.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/root/controls/getall`);
				this.isLoading = true;
				this.columnObject = false;
				this.rowData = result.data;
				this.jsonArray = [];
				let arr: any = [];
				let jObj: any = [];
				let iControls: any = [];
				let val ='';
				console.log("rowData", this.rowData);
				await this.makeTable()
				this.rowData.forEach((item: any) => {
					if (item.controlFrameWork && item.controlNumber && Object.keys(item).includes('controlFrameWork') && Object.keys(item).includes('controlNumber') && !Object.keys(item).includes('isInternalControl')) {

						jObj.push(item);
						console.log("item isss", item);

;						
                        val =this.groupByFrameworks(jObj);
						console.log("valllllllllllllllllll", val);
						this.jsonArray = val;

					} 
				});
				console.log('======jsonArray====', this.jsonArray);
				// console.log('arr', arr);
				// let obj = { 'Internal Controls': iControls };
				// Object.assign(this.jsonArray, obj);
				console.log('jsonArray', this.jsonArray);
				this.carouselHead = [];
				// this.frameWorksList=[];
				let displayFrameName = '';
				Object.keys(this.jsonArray).forEach((key, index) => {
					// if (frameName === index) {
					// 	displayFrameName = key;
					// }
					console.log("key is ", key);
					console.log("index is ", index);
					this.frameWorksList.push({ value: index, label: key });
				});
				// this.tableRow = arr
				// 	.filter((control: any) => control.controlFrameWork && control.controlFrameWork === displayFrameName)
				// 	.map((control: any) => ({
				// 		_id: control._id,
				// 		// frameWork: control.controlFrameWork,
				// 		number: control.controlNumber,
				// 		title: control.controlTitle,
				// 		family: control.controlFamily,
				// 		class: control.controlClass,
				// 		priority: Number(control.controlPriority) ? parseInt(control.controlPriority) : control.controlPriority
				// 		// 'No .of Kpis': control.kpis ? control.kpis.length: '0'
				// 	}));
				// this.tableRow = [];
				// arr.forEach((control: any) => {
				// 	if (control.controlFrameWork && control.controlFrameWork === displayFrameName && !Object.keys(control).includes('isInternalControl')) {
				// 		const obj: any = {
				// 			_id: control._id,
				// 			// frameWork: control.controlFrameWork,
				// 			number: control.controlNumber,
				// 			title: control.controlTitle,
				// 			family: control.controlFamily,
				// 			class: control.controlClass,
				// 			priority: Number(control.controlPriority) ? parseInt(control.controlPriority) : control.controlPriority
				// 			// 'No .of Kpis': control.kpis ? control.kpis.length: '0'
				// 		};
				// 		this.tableRow.push(obj);
				// 	} else if(Object.keys(control).includes('isInternalControl') && control.isInternalControl == true)  {
				// 		const obj: any = {
				// 			_id: control._id,
				// 			// frameWork: control.controlFrameWork,
				// 			number: control.controlNumber,
				// 			title: control.controlTitle,
				// 			family: control.controlFamily,
				// 			class: control.controlClass,
				// 			priority: Number(control.controlPriority) ? parseInt(control.controlPriority) : control.controlPriority
				// 			// 'No .of Kpis': control.kpis ? control.kpis.length: '0'
				// 		};
				// 		this.tableRow.push(obj);
				// 	}
				// });
				// this.tableRow = [];
				// let array = Object.values(this.jsonArray);
				// array.forEach((controlValues: any, index: any) => {
				// 	controlValues.forEach((control: any) => {
				// 		if (frameName === index) {
				// 			this.tableRow.push({
				// 				_id: control._id,
				// 				// frameWork: control.controlFrameWork,
				// 				number: control.controlNumber ? control.controlNumber : 'N/A',
				// 				title: control.controlTitle? control.controlNumber : 'N/A',
				// 				family: control.controlFamily? control.controlNumber : 'N/A',
				// 				class: control.controlClass? control.controlNumber : 'N/A',
				// 				priority: Number(control.controlPriority) ? parseInt(control.controlPriority) : control.controlPriority
				// 				// 'No .of Kpis': control.kpis ? control.kpis.length: '0'});
				// 			});
				// 		}
				// 	});
				// });
				// if (this.rowData.length === 0) {
				// 	this.columnObject = true;
				// 	this.tableRow.push({ _id: '', number: '', title: '', family: '', class: '', priority: '' });
				// }
			} catch (e) {
				this.isLoading = false;
				toast.error(`Something went wrong`, {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
		},
		async addControlSource(payload: any, action: any) {
			try {
				await this.$http.post(`${process.env.VUE_APP_CONTROLS_API_URL}/api/root/controls/create`, payload).then(async (result: any) => {
					await this.rootFetchControlsInfo();
					if (action != 'toast') {
						toast.info(`Saved Successfully`, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
					}
				});
			} catch (e) {
				toast.error(`Something went wrong`, {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
		},
	// async addControlSource(payload: any, action: any) {
	// 		try {
	// 			await this.$http.post(`${process.env.VUE_APP_CONTROLS_API_URL}/api/root/controls/create`, payload).then(async (result: any) => {
	// 				await this.rootFetchControlsInfo();
	// 				if (action != 'toast') {
	// 					toast.info(`Saved Successfully`, {
	// 						timeout: 1000,
	// 						closeOnClick: true,
	// 						closeButton: 'button',
	// 						icon: true
	// 					});
	// 				}
	// 			});
	// 		} catch (e) {
	// 			toast.error(`Something went wrong`, {
	// 				timeout: 1000,
	// 				closeOnClick: true,
	// 				closeButton: 'button',
	// 				icon: true
	// 			});
	// 		}
	// 	},
		fileReader(oEvent: any): any {
			var oFile = oEvent.target.files[0];
			var reader = new FileReader();

			reader.onload = async (e:any) => {
			let data = e.target.result;
			data = new Uint8Array(data);
			var workbook = XLSX.read(data, { type: 'array' });
			var first_sheet_name = workbook.SheetNames[0];
			var worksheet = workbook.Sheets[first_sheet_name];

			// Extract headers from the first row
			var headers:any = XLSX.utils.sheet_to_json(worksheet, {
				header: 1,
				range: 0
			})[0];
			var jsonObj = XLSX.utils.sheet_to_json(worksheet, {
				raw: true,
				header: headers,
				range: 1 
			});

			console.log("JSON Object: ", jsonObj);
			var transformedJsonObj: any = []
			const uniqueControlNumbers = new Set<string>();
			jsonObj.map((item:any) => {
				let arr = [
					"controlNumber",
					"controlTitle"
				];
				let mandatoryCondition = arr.every((key: any) => {
					return Object.keys(item).includes(key) && item[key];
				});
				if(item.controlPriority){
					let PriorityObj = this.priorityArray.find((obj: any) => {
						let comparePriority = this.removeSpaces(item.controlPriority)
						let compareArrayPriority = this.removeSpaces(obj.label)
						console.log("comparePriority", comparePriority)
						console.log("compareArrayPriority", compareArrayPriority)
						return compareArrayPriority.toLowerCase() == comparePriority.toLowerCase();
					});
					console.log("PriorityObj", PriorityObj)
					item.controlPriority = PriorityObj ? PriorityObj.id: item.controlPriority;
				}
			
				let controlCheck
				if((this.dummyControlList != undefined || this.dummyControlList != null) && this.dummyControlList.length > 0){
					controlCheck = this.dummyControlList.some((control: any)=>{
						return control.controlNumber === item.controlNumber
					})
				}
				console.log("controlCheck", controlCheck)

				// Extract framework-related keys dynamically
				if(mandatoryCondition && !controlCheck){
					const frameworks = Object.keys(item).filter(key => key.startsWith('Framework_'));

					// Format frameworks array
					const frameworksArray = frameworks.map(frameworkKey => {
						const originalHeader = frameworkKey;
						const frameworkName = originalHeader.split('_')[1];
						return {
							framework: frameworkName,
							controls: item[frameworkKey] ? item[frameworkKey].split(',') : []
						};
					});

					if(!uniqueControlNumbers.has(item.controlNumber)) {
						transformedJsonObj.push({
							...item,
								frameworks: frameworksArray
						});
						uniqueControlNumbers.add(item.controlNumber);
					}

					
				}
			});


			console.log("Transformed JSON Object: ", transformedJsonObj);

			await this.$http.post(`${process.env.VUE_APP_CONTROLS_API_URL}/api/root/internalcontrols/mappingframeworks`, transformedJsonObj).then(async (result: any) => {
				// await this.getAllControls();
				await this.rootFetchControlsInfo()
				
			if (transformedJsonObj && transformedJsonObj.length == 0) {
				toast.error(`Control Numbers should be unique`, {
					timeout: 5000,
					closeOnClick: true,
					closeButton: "button",
					icon: true,
				});
			} else {
				toast.info(`Imported`, {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
			})
		};
		reader.readAsArrayBuffer(oFile);
		},
		removeSpaces(str: any) {
			return str.replace(/\s/g, '');
		},
		


		openFileModal() {
			this.$refs.file.click();
			this.showBlock = false;
		}
	}
});
</script>

