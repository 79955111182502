<template>
	<div class="pt-3 pl-5 pr-5">
		<div class="row w-full  justify-between flex  items-center">
			<h1 class="title">Control Library</h1>
			<!-- <div class="cardbody"> -->
				<div class="flex flex-row-reverse items-center">
					<button class="flex" :class="!columnObject ? 'filterButton' : 'filterButtondisabled pointer-events-none'" @click="filterOpen">
						Filters
						<img src="@/assets/filter.svg" class="h-3 ml-2.5" />
					</button>
				<!-- </div> -->
			</div>
		</div>
		<div class="text-xs mt-3">
			Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
			velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
		</div>
		<div class="mt-5 px-5 pb-5 card w-full relative">
			<div class="group-buttons" v-if="isSmallScreen">
                    <!-- Group navigation buttons for smaller screens -->
                    <div class="absolute top-5 left-0 cursor-pointer" @click="scrollTabs('left')" :class="{ 'disabled pointer-events-none': (selectedFrame === 0 || currentGroupIndex === 0) && isSmallScreen }">
                        <img src="@/assets/leftArrowFilled.svg" class="arrow left-0" />
                    </div>
                    <div class="absolute top-5 right-0 cursor-pointer" @click="scrollTabs('right')" :class="{ 'disabled pointer-events-none': (selectedFrame === carouselHead.length - 1 || currentGroupIndex >= totalGroups - 1) && isSmallScreen }">
                        <img src="@/assets/rightArrowFilled.svg" class="arrow right-0" />
                    </div>
                </div>
                <div v-else>
                    <!-- Scroll buttons for larger screens -->
                    <div class="absolute top-5 left-0 cursor-pointer" @click="scrollTabs('left')" :class="{ 'disabled pointer-events-none': !canScrollLeft && !isSmallScreen }">
                        <img src="@/assets/leftArrowFilled.svg" class="arrow left-0" />
                    </div>
                    <div class="absolute top-5 right-0 cursor-pointer" @click="scrollTabs('right')" :class="{ 'disabled pointer-events-none': !canScrollRight && !isSmallScreen }">
                        <img src="@/assets/rightArrowFilled.svg" class="arrow right-0" />
                    </div>
                </div>
			<div>
				<div
					class="mt-5 flex flex-row borderBottom overflow-hidden justify-between cursor-pointer">
					<div class="pb-1" :class="selectedFrame == tab.value ? 'adjust header font-semibold primary text-sm':'text-sm text-disabledgrey'" v-for="(tab, tabIndex) in visibleTabs" :key="tabIndex" @click="toggleTabs(tab.value)">
						<div>
							<div ref="actualIndex">{{ tab.displayName }}</div>
							<div :class="{ progress: selectedFrame == tab.value }" class="h-1 p-0.75" style="border-radius:5px;"></div>
						</div>
					</div>
				</div>
				<div class="progress1 h-1"></div>
			</div>

			<div>
				<tableData :tableRowData="tableRow" :headersData="headers" :loadData="dataLoading"></tableData>
				<div v-if="toolTipActive">
					<tool-tip :tipText="toolTipText"></tool-tip>
				</div>
				<!--  :tabelActionBtn="true" :tableRouteArr="optionsValue" -->
			</div>
			<popup v-if="popUpFrameWork" :popUp="popUpFrameWork">
				<template v-slot:header>
					<div class="flex items-center justify-between py-4 px-2 rounded">
						<h1 class="pl-2">{{ frameworkObj.controlFrameWork || 'Internal Controls'}}</h1>
						<button class="float-right text-3xl" @click="onCancelbtn()">
							<img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
						</button>
					</div>
				</template>
				<fieldset>
					<div>
						<div>
							<div class="statuscontainer popupbodycontainer">
								<div class="formcontainer flex flex-col">
										<!-- <div class="w-full flex flex-col">
											<label class="text-xs font-extrabold my-2">Framework</label>
											<div class="mb-3 text-sm" v-if="frameworkObj.controlFrameWork">{{ frameworkObj.controlFrameWork }}</div>
											<div class="mb-3 text-sm" v-else-if="frameworkObj.frameworks">{{ frameworkObj.frameworks[0].framework }}</div>
											<div class="mb-3 text-sm" v-else>{{ 'N/A' }}</div>
										</div> -->
									<div class="row flex flex-row w-full">
										<div class="row flex flex-row w-full">
											<div class="w-6/12">
												<label class="text-xs font-extrabold">Number</label>
												<div class="mb-3 mt-2 text-sm">{{ frameworkObj?.controlNumber || 'N/A'}}</div>
											</div>
											<div class="w-3/6 leftspacing">
												<label class="text-xs font-extrabold">Title</label>
												<div class="mb-3 mt-2 text-sm">{{ frameworkObj?.controlTitle || 'N/A'}}</div>											</div>
										</div>
									</div>
									<div class="row flex flex-row w-full">
											<div class="w-6/12">
												<label class="text-xs font-extrabold">Type</label>
												<div class="mb-3 mt-2 text-sm">{{ frameworkObj?.controlType || 'N/A' }}</div>											</div>
											<div class="w-3/6 leftspacing" >
											<div v-if="checkType()">
												<div>
													<label class="text-xs font-extrabold">Priority</label>
													<div class="mb-3 mt-2 text-sm">N/A</div>
												</div>
											</div>
												<div v-else v-for="(dataValue, indexNum) in priorityArray" :key="indexNum">
													<div v-if="frameworkObj.controlPriority == dataValue.id">
														<label class="text-xs font-extrabold">Priority</label>
														<div class="mb-3 mt-2 text-sm">{{ dataValue != undefined ? dataValue.label : 'N/A' }}</div>
													</div>
												</div>
											</div>
									</div>
									<div class="row flex flex-row w-full">
											<div class="w-6/12">
												<label class="text-xs font-extrabold">Family</label>
												<div  v-if="frameworkObj.controlFamily != null"   class="mb-3 mt-2 text-sm">{{ frameworkObj.controlFamily }}</div>
												<div v-else>
													<div class="mb-3 mt-2 text-sm">{{ 'N/A' }}</div>
												</div>
											</div>
											<div class="w-3/6 leftspacing">
												<label class="text-xs font-extrabold">Class</label>
												<div  v-if="frameworkObj.controlClass != null"   class="mb-3 mt-2 text-sm">{{ frameworkObj.controlClass }}</div>
												<div v-else>
													<div class="mb-3 mt-2 text-sm">{{ 'N/A' }}</div>
												</div>
											</div>
									</div>
									<div class="row flex flex-row w-full">
										<div class="w-full">
											<label class="text-xs font-extrabold">Audit Methodology</label>
											<div  v-if="frameworkObj.controlAuditMethodology != null"  class="mb-3 mt-2 text-sm">{{ frameworkObj.controlAuditMethodology }}</div>
											<div v-else>
												<div class="mb-3 mt-2 text-sm">{{ 'N/A' }}</div>
											</div>
										</div>
									</div>
									<div class="row flex flex-row w-full">
										<div class="w-full">
											<label class="text-xs font-extrabold">Audit Success Criteria</label>
											<div  v-if="frameworkObj.controlGuidance != null" class="mb-3 mt-2 text-sm">{{ frameworkObj.controlGuidance }}</div>
											<div v-else>
												<div class="mb-3 mt-2 text-sm">{{ 'N/A' }}</div>
											</div>
										</div>
									</div>
									<div class="row flex flex-row w-full">
										<div class="w-full">
											<label class="text-xs font-extrabold">Maintenance Task</label>
											<div v-if="frameworkObj.controlMaintenanceTask != null">
												<div class="mb-3 mt-2 text-sm">{{ frameworkObj.controlMaintenanceTask }}</div>
											</div>
											<div v-else>
												<div class="mb-3 mt-2 text-sm">{{ 'N/A' }}</div>
											</div>
										</div>
									</div>
									<div class="row flex flex-row w-full">
										<div class="w-full">
											<label class="text-xs font-extrabold">Policies</label>
											<div v-if="frameworkObj.controlPolicies != null">
												<div class="mb-3 mt-2 text-sm">{{ frameworkObj.controlPolicies }}</div>
											</div>
											<div v-else>
												<div class="mb-3 mt-2 text-sm">{{ 'N/A' }}</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</fieldset>
			</popup>
		</div>
		<!-- <loader v-if="showLoader" /> -->
	</div>
</template>
<style scoped>
.primary{
	color:#152a43;
}
.multiselect-wrapper {
	min-height: 20px !important;
}
.multiselect-tags-search-wrapper {
	margin: 0px !important;
}
.anime-left {
	animation-name: moveLeft;
	animation-duration: 1s;
	animation-iteration-count: 1;
	animation-fill-mode: backwards;
}
@keyframes moveLeft {
	0% {
		opacity: 1;
		transform: translateX(100vw);
	}
	100% {
		transform: translateX(0);
	}
}
.anime-right {
	animation-name: moveRight;
	animation-duration: 1s;
	animation-iteration-count: 1;
	animation-fill-mode: backwards;
}
@keyframes moveRight {
	0% {
		opacity: 1;
		transform: translateX(-100vw);
	}
	100% {
		transform: translateX(0);
	}
}
.progress1 {
	border: 2px solid #e9e9e9;
	border-radius: 16px;
	margin-right: 0.5px;
	background: #e9e9e9;
	position: relative;
	top: -8px;
	/* margin-top: 3px; */
}
.progress {
	background: #152a43 !important;
}
.setBox {
	width: initial;
}
.marginprev {
	margin-left: -16px;
}
.marginnext {
	margin-right: -16px;
}
.box {
	border: 2px solid red;
}
.adjust {
	flex-shrink: 0;
}
.button-adjust {
	position: relative;
	top: -30px;
	z-index: 1;
	cursor: pointer;
}
.borderBottom {
	position: relative;
	z-index: 2;
	cursor: pointer;
}
.z-index {
	z-index: 100;
}
::-webkit-scrollbar {
	width: 3px !important;
}
/* .accept{
    width:100px
} */
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import useVuelidate from '@vuelidate/core';
import { emitter, toast } from '../../main';
import * as XLSX from 'xlsx';
import tableData from '@/components/tableData.vue';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import popup from '@/components/popup.vue';
// import loader from '@/components/loader.vue';
import toolTip from "@/components/toolTip.vue";

export default defineComponent({
	data(): any {
		return {
			headers:['_id','number','frameworkTitle','family','class','priority'],
			v$: useVuelidate(),
			// objToBeModified: {},
			// dummyObj: {},
			showFilters: false,
			carouselHead: [],
			index: 0,
			selectedFrame: 0,
			popUpFrameWork: false,
			columnObject: false,
			styling: { left: '', bottom: '' },
			pageItems: 6,
			currentItems: 1,
			showLoader: false,
			showBlock: false,
			showDropdown: false,
			filters: {},
			rowData: [],
			controlTypeArray: [
				{ label: 'Preventive', id: 1 },
				{ label: 'Detective', id: 2 },
				{ label: 'Corrective', id: 3 },
				{ label: 'Deterrent', id: 4 },
				{ label: 'Directive', id: 5 },
				{ label: 'Recovery', id: 6 },
				{ label: 'Compensating', id: 7 }
			],
			optionsValue: [
				{
					id: 1,
					value: 'Map',
					route: '/manageControlMap',
					presentRoute: '/org/frameworks'
				}
			],
			currentGroupIndex: 0, // Current group index for small screens
            tabsPerGroup: 3, // Number of tabs per group for small screens
            isSmallScreen: false, // Flag for screen size
            canScrollLeft: false, // Whether tabs can scroll left
            canScrollRight: true, // Whether tabs can scroll right
            totalGroups: 0 ,
			priorityArray: [
				{ label: 'Very Low', id: 1 },
				{ label: 'Low', id: 2 },
				{ label: 'Moderate', id: 3 },
				{ label: 'High', id: 4 },
				{ label: 'Critical', id: 5 }
			],
			tableRow: [],
			visibleTabs:[],
			frameworkObj: {
				controlNumber: '',
				controlTitle: '',
				noOfKpis: '0',
				controlFrameWork: '',
				controlFamily: '',
				controlPriority: '',
				controlClass: '',
				controlType: '',
				controlAuditMethodology: '',
				controlAuditSuccessCriteria: '',
				controlMaintenanceTask: '',
				controlPolicies: '',
				controlGuidance: ''
			},
			toolTipActive : false,
			toolTipText : '',
			type: [
				{
					id: 1,
					value: 'Primary'
				},
				{
					id: 2,
					value: 'Secondary'
				}
			],
			dataLoading:true,
			selectedTabIndex:''
		};
	},
    async created(){
		this.dataLoading = true;
		await this.getAllControls();
		console.log("this.carouselHead",this.carouselHead)
        this.visibleTabs = [...this.carouselHead];
 
        this.handleResize(); // Initial setup
        window.addEventListener('resize', this.handleResize);
        this.updateButtonStates(); // Initial update to button states
		const savedTabKey = sessionStorage.getItem('selectedTab');
		if (savedTabKey) {
		const savedTab = this.carouselHead.find((tab: any) => tab.value == savedTabKey);
			console.log("savedTab",savedTab)
			if (savedTab) {
					this.toggleTabs(savedTab.value);
			}
			else{
				this.toggleTabs(0);
				this.selectedFrame = 0;
			}
		}
		else{
			this.toggleTabs(0);
			this.selectedFrame = 0;
		}
	emitter.off('activiteToolTip');
		emitter.on('activiteToolTip', (data: any) => {
			this.toolTipActive = true;
			this.toolTipText = data.tipText
		});
		emitter.off('deActiviteToolTip');
		emitter.on('deActiviteToolTip', (data: any) => {
			this.toolTipActive = false;
			this.toolTipText = ''
		});
	 },
	async mounted() {
		emitter.on('frameworksPopup', (isData: any) => {
			let comingId = isData.entityData._id;
			let showObj = this.rowData.find((obj: any) => obj._id === comingId);
			this.showEditPopUp(showObj);
		});
		this.dataLoading = false;
		emitter.on('*', (type, booleanValue) => {
			if (type == 'apps-sidemenubar' || type == 'toggle-sidebar') {
				this.showBlock == true ? (this.showBlock = false) : '';
			} else {
				if (this.showDropdown === true) {
					this.showBlock == true ? (this.showBlock = booleanValue) : '';
				} else if (this.showDropdown === false) {
					this.showBlock == true ? '' : (this.showBlock = booleanValue);
					this.showDropdown = true;
				}
			}
		});
	},
	components: {
		tableData,
		popup,
		// loader,
		toolTip
	},
	computed: {
		// tabHead(): any {
        //     return this.carouselHead.filter((row: any, index: any) => {
        //         let start = (this.currentItems - 1) * this.pageItems;
        //         this.index = 0;
        //         let end = this.currentItems * this.pageItems;

        //         if (index >= start && index < end) return true;
        //     });
        // },
		...mapGetters({ userInfo: 'userInfo' }),
		// cantGoBack(): any {
		// 	return this.currentItems === 1;
		// },
		// cantGoForward(): any {
		// 	return this.carouselHead.length / this.pageItems <= this.currentItems;
		// }
	},
	methods: {
        updateButtonStates() {
            const tabContainer = this.$refs.tabContainer;
            if (tabContainer) {
                if (!this.isSmallScreen) {
                    this.canScrollLeft = tabContainer.scrollLeft > 0;
                    this.canScrollRight = tabContainer.scrollLeft + tabContainer.clientWidth < tabContainer.scrollWidth;
                } else {
                    this.canScrollLeft = this.currentGroupIndex > 0;
                    this.canScrollRight = this.currentGroupIndex < this.totalGroups - 1;
                }
            }
        },
       
        scrollTabs(direction:any) {
            if (direction === 'left' && this.currentGroupIndex > 0) {
                this.currentGroupIndex--;
            } else if (direction === 'right' && this.currentGroupIndex < this.totalGroups - 1) {
                this.currentGroupIndex++;
            }
			console.log("this.totalGroups",this.currentGroupIndex)
            this.updateVisibleTabs('tab');
        },
 
        handleResize() {
            const width = window.innerWidth;
            if (width <= 427) {
                this.tabsPerScreen = 1;
            } else if (width <= 569) {  
                this.tabsPerScreen = 3;
            } else if (width <= 1024) {
                this.tabsPerScreen = 5;
            } else {
                this.tabsPerScreen = this.carouselHead.length; // Show all tabs for larger screens
            }
            this.isSmallScreen = (width <= 1366); // For logic buttons ref

            // Calculate total groups
            this.totalGroups = Math.ceil(this.carouselHead.length / this.tabsPerScreen);

            // Determine the group containing the selected tab
			const savedTabKey = sessionStorage.getItem('selectedTab');
			this.selectedFrame = savedTabKey
            this.updateCurrentGroupIndex();
            this.updateVisibleTabs('normal');
            this.updateButtonStates();
        },

        updateCurrentGroupIndex() {
            const selectedTabIndex = this.carouselHead.findIndex((tab:any) => {return tab.value == this.selectedFrame});
            if (selectedTabIndex >= 0) {
                this.currentGroupIndex = Math.floor(selectedTabIndex / this.tabsPerScreen);
            } else {
                this.currentGroupIndex = 0; // Reset to first group if selected tab not found
            }
        },

        updateVisibleTabs(from:any) {
            if (this.tabsPerScreen === this.carouselHead.length) {
                this.visibleTabs = [...this.carouselHead]; // Show all tabs
            } else {
                const startIdx = this.currentGroupIndex * this.tabsPerScreen;
                const endIdx = startIdx + this.tabsPerScreen;
				console.log("jkhajkf",this.currentGroupIndex)
				console.log("jkhajkf1",this.tabsPerScreen)
                this.visibleTabs = this.carouselHead.slice(startIdx, endIdx);
            }

            if(from=='tab'){
                this.toggleTabs(this.visibleTabs[0]?.value)
            }
        },
		checkType(){
			if(this.frameworkObj.controlPriority == undefined || this.frameworkObj.controlPriority == null || typeof this.frameworkObj.controlPriority === "string"){
               return true;
			}else{
				return false;
			}
		},
		async nextPage() {
			if ((this.currentItems * this.pageItems) < this.carouselHead.length) {
				this.currentItems = this.currentItems + 1;
				this.selectedFrame = this.tabHead[0].value || null;
				console.log("selectedTab",this.tabHead);
				if (this.selectedFrame) {
					await this.getAllControls(this.selectedFrame);
					await this.toggleTabs(this.selectedFrame);
				}
				this.updateCarouselClass();
			}
       },
		async prevPage() {
			if (this.currentItems >= 1) {
				const savedTabKey = sessionStorage.getItem('selectedTab');
				this.currentItems = this.currentItems - 1;
				let selectedTab:any = this.tabHead.find((data:any) => {
					return data.value == savedTabKey
				});
				console.log("selectedTab",this.tabHead);
				if (selectedTab!=undefined) {
					this.selectedFrame = selectedTab.value ;
				}else{
					this.selectedFrame = 0
				}
				// console.log("23456",this.carouselHead);
				if (this.selectedFrame) {
					await this.getAllControls(this.selectedFrame);
					await this.toggleTabs(this.selectedFrame);
				}
				//  else{
				// 	this.toggleTabs(0);
				//  }
				this.updateCarouselClass(); 
			}
        },
		updateCarouselClass() {
			const x = window.innerWidth;
			let className = '';

			if (x > 1280) {
			className = 'page-items-7';
			this.pageItems = 7;
			} else if (x >= 1024 && x <= 1280) {
			className = 'page-items-6';
			this.pageItems = 6;
			} else if (x >= 899 && x < 1024) {
			className = 'page-items-5';
			this.pageItems = 5;
			} else if (x >= 640 && x < 899) {
			className = 'page-items-4';
			this.pageItems = 6;
			} else if(x >= 512 && x < 639){
			className = 'page-items-1';
			this.pageItems = 3;
			}else{
				this.pageItems = 1;
			}

			const carouselContainer = this.$refs.carouselContainer;
			if (carouselContainer) {
			carouselContainer.className = '';
			carouselContainer.classList.add(className);
			}

			// Adjust currentItems if it exceeds the number of pages
			const totalItems = this.carouselHead.length;
			const totalPages = Math.ceil(totalItems / this.pageItems);
			if (this.currentItems > totalPages) {
			this.currentItems = totalPages;
			}
			if (this.currentItems < 1) {
			this.currentItems = 1;
			}
		},
		showEditPopUp(framework: any) {
			this.popUpFrameWork = true;
			// this.dummyObj = { ...framework };
			// this.objToBeModified = framework;
			// console.log("framework", framework)
			Object.keys(framework).forEach((prop) => {
				console.log("framework[prop]", framework[prop])
				framework[prop] == '' || framework[prop] == null ? framework[prop] = 'N/A' : framework[prop] = framework[prop];
			});
			console.log("framework",framework)
			this.frameworkObj = { ...framework };
			// this.frameworkObj.map((obj: any)=>{
			// 	obj.
			// })
		},
		async toggleTabs(frame: any) {
			this.dataLoading = true;
			this.defaultFrame = frame;
			this.selectedFrame = frame;
			this.tableRow = [];
			await this.getAllControls(frame);
			this.dataLoading = false;
			sessionStorage.setItem('selectedTab', frame);
			console.log("this.selectedFrame",this.selectedFrame)
		},
		filterOpen() {
			if (this.columnObject == false) {
				this.showFilters = !this.showFilters;
				emitter.emit('visible', this.showFilters);
			}
		},
		groupBy(arr: any[]) {
			return arr.reduce((group: any, product: any) => {
				const { controlFrameWork } = product;
				group[controlFrameWork] = group[controlFrameWork] ?? [];
				group[controlFrameWork].push(product);
				return group;
			}, {});
		},
		onCancelbtn() {
			this.v$.$reset();
			this.popUpFrameWork = false;
			Object.keys(this.frameworkObj).forEach((prop) => {
				Array.isArray(this.frameworkObj[prop]) ? (this.frameworkObj[prop] = []) : (this.frameworkObj[prop] = '');
			});
		},
		async getAllControls(frameName: any) {
			emitter.emit('visible', false);
			this.showFilters = false;
			try {
				this.isLoading = false;
				const result = await this.$http.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/allControls`);
				this.isLoading = true;
				this.columnObject = false;
				this.rowData = result.data;
				let jsonArray: any = [];
				let arr: any = [];
				let jObj: any=[]
				let iControls: any = []
				this.rowData.forEach((item: any) => {
					if (item.controlFrameWork && item.controlNumber && Object.keys(item).includes('controlFrameWork') && Object.keys(item).includes('controlNumber') && !item.isInternalControl) {
						if(item.controlFrameWork !== 'Active Controls'){
							jObj.push(item);
							jsonArray = this.groupBy(jObj);
						}
					} else{
						iControls.push(item)
					}
					arr.push(item);
				});
				// console.log("iControls", iControls)
				if(iControls.length != 0){
					let obj = {'Internal Controls': iControls}
					Object.assign(jsonArray, obj);
				}
				console.log("jsonArray", jsonArray)
				this.carouselHead = [];
				let displayFrameName = '';
				Object.keys(jsonArray).forEach((key, index) => {
					if (frameName === index) {
						displayFrameName = key;
					}
					let obj:any = {value: index, displayName: key }
					console.log("sadffg",obj)
					this.carouselHead.push({value: index, displayName: key });
				});
				// this.tableRow = arr
				// 	.filter((control: any) => control.controlFrameWork && control.controlFrameWork === displayFrameName)
				// 	.map((control: any) => ({
				// 		_id: control._id,
				// 		// frameWork: control.controlFrameWork,
				// 		number: control.controlNumber,
				// 		title: control.controlTitle,
				// 		family: control.controlFamily,
				// 		class: control.controlClass,
				// 		priority: Number(control.controlPriority) ? parseInt(control.controlPriority) : control.controlPriority
				// 		// 'No .of Kpis': control.kpis ? control.kpis.length: '0'
				// 	}));
				// this.tableRow = [];
				// arr.forEach((control: any) => {
				// 	if (control.controlFrameWork && control.controlFrameWork === displayFrameName && !Object.keys(control).includes('isInternalControl')) {
				// 		const obj: any = {
				// 			_id: control._id,
				// 			// frameWork: control.controlFrameWork,
				// 			number: control.controlNumber,
				// 			title: control.controlTitle,
				// 			family: control.controlFamily,
				// 			class: control.controlClass,
				// 			priority: Number(control.controlPriority) ? parseInt(control.controlPriority) : control.controlPriority
				// 			// 'No .of Kpis': control.kpis ? control.kpis.length: '0'
				// 		};
				// 		this.tableRow.push(obj);
				// 	} else if(Object.keys(control).includes('isInternalControl') && control.isInternalControl == true)  {
				// 		const obj: any = {
				// 			_id: control._id,
				// 			// frameWork: control.controlFrameWork,
				// 			number: control.controlNumber,
				// 			title: control.controlTitle,
				// 			family: control.controlFamily,
				// 			class: control.controlClass,
				// 			priority: Number(control.controlPriority) ? parseInt(control.controlPriority) : control.controlPriority
				// 			// 'No .of Kpis': control.kpis ? control.kpis.length: '0'
				// 		};
				// 		this.tableRow.push(obj);
				// 	}
				// });
				this.tableRow = [];
				let array = Object.values(jsonArray)
                array.forEach((controlValues:any, index: any)=>{
                    controlValues.forEach((control:any)=>{
                        if(frameName === index){
                            this.tableRow.push({
								_id: control._id,
								// frameWork: control.controlFrameWork,
								number: control.controlNumber?control.controlNumber:'N/A',
								frameworkTitle: control.controlTitle?control.controlTitle:'N/A',
								family: control.controlFamily?control.controlFamily:'N/A',
								class: control.controlClass?control.controlClass:'N/A',
								// priority: control.controlPriority != undefined || control.controlPriority != null || typeof control.controlPriority === "number" ? control.controlPriority : 'N/A'
								// priority:control.controlPriority?Number(control.controlPriority) ? parseInt(control.controlPriority) : control.controlPriority:'N/A''
								priority : (typeof control.controlPriority === 'number' && !isNaN(control.controlPriority)) ? control.controlPriority : 'N/A'

								// 'No .of Kpis': control.kpis ? control.kpis.length: '0'});
							})
							// console.log('control.controlPriority',control.controlPriority,Number(control.controlPriority),parseInt(control.controlPriority))
						}
					})
                })
				if (this.rowData.length === 0) {
					this.columnObject = true;
					this.tableRow.push({ _id: '', number: '', frameworkTitle: '', family: '', class: '', priority: '' });
				}
			} catch (e) {
				this.isLoading = false;
				toast.error(`Something went wrong`, {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
		}
	},
	beforeUnmount() {
	sessionStorage.removeItem('selectedTab')
	}
});
</script>
