<template>
	<div class="pt-3 pl-5 pr-5">
		<div v-if="thisScreen === 'rootScreen'" class="mb-2">
			<breadCrumb :routesArray="routesArray"></breadCrumb>
		</div>
		<div class="row w-full mb-3 justify-between flex" style="align-items: center">
			<h1 class="title">Frameworks</h1>
		</div>
		
		<div v-if="allControlSetFrameworks.length > 0" class="row card1 w-full mt-4 relative h-full">
		
			<div class="heightOfCard bg-white noScrollbar overflow-y-auto border-lightgrey border-l border-r border-b border-t rounded">
				<div class=" noScrollbar overflow-y-auto" :class="thisScreen === 'rootScreen'?'heightOfCard2':'heightOfCard1'">
				<div :class="userInfo.currentRole === 10205 ? 'card-middle' : ''" class="flex flex-wrap pt-4 mb-10 noScrollbar overflow-y-auto" > 
					<div class="frameWorkCard ml-5 mb-5" v-for="obj in allControlSetFrameworks" :key="obj" @click="changeBoxInput(obj._id, obj.controlFrameWork)">
						<div v-if="Object.keys(obj).includes('controlImageUrl')" class="h-full rounded relative flex justify-center cursor-pointer items-center">
							<input type="checkbox" class="cursor-pointer checkbox absolute top-3 left-3" v-model="selectedFrameWorks" :value="obj._id" />
							<img class="imageFrame" :src="obj.controlImageUrl" />
						</div>
						<div v-else-if="obj.controlFrameWork == 'InternalControls'" class="h-full bg-white border border-lightgrey rounded relative flex justify-center cursor-pointer items-center">
							<input type="checkbox" class="cursor-pointer checkbox absolute top-3 left-3" v-model="selectedFrameWorks" :value="obj._id" checked disabled />
							<p class="text-base capitalize font-semibold text-black">{{ obj.controlFrameWork }}</p>
						</div>
						<div v-else class="h-full bg-white border border-lightgrey rounded relative flex justify-center cursor-pointer items-center">
							<input type="checkbox" class="cursor-pointer checkbox absolute top-3 left-3" v-model="selectedFrameWorks" :value="obj._id" />
							<p class="text-base capitalize font-semibold text-black">{{ obj.controlFrameWork }}</p>
						</div>
					</div>
				</div></div>
				<div v-if="allControlSetFrameworks.length > 0">
					<div class="flex w-full bottom-5 right-0 bg-white rounded px-3 py-4 justify-end" style="box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);">
						<button :class="disableStatus ? 'btndisabled pointer-events-none' : 'btnprimary'" class="mr-1.5" @click="onCancelBtn()">Cancel</button>
						<button :class="disableStatus ? 'btndisabled pointer-events-none' : 'btnprimary'" :disabled="clickOnButton" @click="ApplyControls(selectedFrameWorks)">Apply</button>
					</div>
				</div>
			</div>

		</div>
		
		<div v-else-if="dataLoading == true" class="flex justify-center items-center w-full mt-8 bg-white h-10 border border-lightgrey rounded"><p class="text-xs font-normal"><dots-loader/></p></div>
		<div v-else class="flex justify-center items-center w-full mt-8 bg-white h-10 border border-lightgrey rounded"><p class="text-xs font-normal">No Records Found</p></div>
	</div>
</template>
<style scoped>
.card-middle {
	height: calc(100vh - 221px);
}
.heightOfCard{
	height: calc(100vh - 115px);
}
.heightOfCard1{
	height: calc(100vh - 180px);
}
.heightOfCard2{
	height: calc(100vh - 210px);
}
.card-start {
	overflow-y: auto;
	height:68vh;
	padding-bottom : 30px;
}
.card-start::-webkit-scrollbar {
	width: 0 !important;
	height: 0 !important;
	background: transparent;
}
.headingCard {
	height: 10vh;
}
.drop {
	box-shadow: 0px -25px 20px -20px rgba(0, 0, 0, 0.05), 0px -21px 255px -255px rgba(121, 119, 119, 0.05);
}
.button-fixed {
	height: 12vh;
}
.card1 {
	height: 76.5vh;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
}
.cardStyling {
	background-color: #fff;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
	box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.1); /* Top shadow */
	position: absolute;
	bottom: 0%;
}
.imageFrame {
	height: 140px;
	width: 190px;
	border: 1px solid #e9e9e9;
	border-radius: 4px;
}
.frameWorkCard {
	width: 190px;
	height: 140px;
}
.w-40 {
	width: calc(100% - 170px);
}
.w-10 {
	width: calc(100% - 2.5rem);
}
.noScrollbar::-webkit-scrollbar {
  display: none;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { toast } from '@/main';
import breadCrumb from '@/components/breadCrumb.vue';
import dotsLoader from '@/components/dotsLoader.vue';

export default defineComponent({
	data(): any {
		return {
			mainTenant: '',
			selectedFrameWorks: [],
			tenantsSelect: [],
			tenants: [],
			allControls: [],
			allRootControlSets: {},
			allOrganisationControlSets: {},
			allControlSetFrameworks: [],
			thisScreen: '',
			previousSelectedFrameworks: [],
			presentTenantObj: {},
			frameWorkInfo: [],
			isSidebarToggle: true,
			addedIds:'',
			routesArray:[],
			clickOnButton: false,
			dataLoading:true,
		};
	},
 
	computed: {
		...mapGetters({ userInfo: 'userInfo', toggleState: 'toggle' }),
		disableStatus: function (): boolean {
			const arraysEqual = (arr1: any[], arr2: any[]): boolean => {
				if (arr1.length !== arr2.length) return false;
				return arr1.every((value, index) => arr2.includes(value));
			};
			return arraysEqual(this.selectedFrameWorks, this.previousSelectedFrameworks);
		}
	},
	components:{
		breadCrumb,
		dotsLoader
	},
	methods: {
		changeBoxInput(frameworkId: any, obj: any) {
			if (this.selectedFrameWorks.includes(frameworkId)&& obj != 'InternalControls')  {
				let result = this.selectedFrameWorks.filter((name: any) => {
					return !(name == frameworkId);
				});
				this.selectedFrameWorks = result;
			} else {
				if (obj != 'InternalControls') {
					this.selectedFrameWorks.push(frameworkId);
				}
			}
		},
		async getAllTenants() {
			await this.$http.get(`${process.env.VUE_APP_AUTH_API_URL}/tenants/getAll`).then((res: any) => {
				res.data.map((org: any) => {
					const obj = { id: org._id, name: org.organizationName };
					this.tenants.push(obj);
					const object = { value: org._id, label: org.organizationName };
					this.tenantsSelect.push(object);
				});
			});
		},
		groupBy(arr: any, key: any) {
			let groupBy = arr.reduce((group: any, product: any) => {
				const keyValue = product[key];
				group[keyValue] = group[keyValue] ?? [];
				group[keyValue].push(product);
				return group;
			}, {});
			return groupBy;
		},
		onCancelBtn() {
			this.selectedFrameWorks = [...this.previousSelectedFrameworks];
		},
		async getRootControlFrameWorks() {
			this.allRootControlSets = {};
			this.allControlSetFrameworks = [];
			await this.$http.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/root/controlset/getall`).then((res: any) => {
				let arr;
				arr = res.data;
				this.allControls = [...res.data];
				this.allRootControlSets = this.groupBy(arr, 'controlFrameWork');

				this.allControls.map((ctrlObject: any) => {
					let obj;
					if (Object.keys(ctrlObject).includes('controlImageUrl')) {
						obj = { _id: ctrlObject._id, controlFrameWork: ctrlObject.controlFrameWork, controlImageUrl: ctrlObject.controlImageUrl };
					} else {
						obj = { _id: ctrlObject._id, controlFrameWork: ctrlObject.controlFrameWork };
					}
					this.frameWorkInfo.push(obj);
					this.allControlSetFrameworks.push(obj);
				});
			});
			await this.getOrgControlFrameWorks(this.$route.params.tenantId);
		},

		async getOrgControlFrameWorks(tenantId: any) {
			this.selectedFrameWorks = []
			this.allOrganisationControlSets = {};
			if (this.thisScreen == 'orgScreen') {
				this.allControlSetFrameworks = [];
			}
			if (this.thisScreen == 'rootScreen') {
				this.allControlSetFrameworks.forEach((obj: any)=>{
					let frame
					if(obj.controlFrameWork == "InternalControls"){
						frame = obj._id
						// if(!this.selectedFrameWorks.includes(obj._id)){
						// 	this.selectedFrameWorks.push(frame);
						// }
						this.selectedFrameWorks.push(frame);
					}
					
				})
				
			}

			await this.$http.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/tenant/${tenantId}/controls/get`).then((res: any) => {
				let arr;
				arr = res.data;
				arr.map((obj: any) => {
					let findObj = this.frameWorkInfo.find((frame: any) => {
						return frame._id == obj.selectedFramework;
					});
					obj.frameWorkName = findObj.controlFrameWork;
				});
				console.log("arr", arr)

				if (this.thisScreen == 'orgScreen') {
					let names = new Set();
					for (let row of arr) {
						if (row.isActive) {
							names.add(row.selectedFramework);
						}
					}
					this.selectedFrameWorks = [...names];
				}
				this.allOrganisationControlSets = this.groupBy(arr, 'frameWorkName');
				// this.selectedFrameWorks =[]
				Object.keys(this.allOrganisationControlSets).map((key: any) => {
					let findFrameWorkObj = this.frameWorkInfo.find((frame: any) => {
						return frame.controlFrameWork == key;
					});
					this.thisScreen == 'orgScreen' ? this.allControlSetFrameworks.push(findFrameWorkObj) : '';
					this.thisScreen == 'rootScreen' ? this.selectedFrameWorks.push(findFrameWorkObj._id) : '';
				});
				this.previousSelectedFrameworks = [...this.selectedFrameWorks];
				if(this.thisScreen == 'orgScreen'){
					this.allControlSetFrameworks.forEach((intro: any)=>{
						if(intro.controlFrameWork =='InternalControls' && !this.selectedFrameWorks.includes(intro._id)){
							this.selectedFrameWorks.push(intro._id);
						}
					})
					// this.selectedFrameWorks.push("65c070a62a2351687a19afcc")
				}
			});
		},

		async ApplyControls(frameWorkNames: any) {
			this.clickOnButton = true;
			let showToast = '';
			this.dataLoading = true;
			

			if (this.thisScreen == 'orgScreen') {
				// if(){
				let removedIds = this.previousSelectedFrameworks.filter((id: any) => {
					return !this.selectedFrameWorks.includes(id);
				});
				if (removedIds.length > 0) {
				
					let payload: any = [{ selectedFrameWorks: removedIds, createdBy: this.userInfo._id, createdAt: new Date() }];

					try{
						await this.$http.post(`${process.env.VUE_APP_CONTROLS_API_URL}/api/tenant/${this.$route.params.tenantId}/${1}/delete`, payload).then(async (res: any) => {
						showToast = 'deleted';
					});
					}catch (e) {
						toast.error('error', {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
					} finally {
						this.clickOnButton = false;
					}
				}
				let addedIds = this.selectedFrameWorks.filter((id: any) => {
					return !this.previousSelectedFrameworks.includes(id);
				});
				if (addedIds.length > 0) {
					let payload: any = [{ selectedFrameWorks: addedIds, createdBy: this.userInfo._id, createdAt: new Date() }];
                     
                  try{
					await this.$http
						.post(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/rootcontrols/${this.$route.params.tenantId}/create`, payload)
						.then(async (res: any) => {
							showToast = 'saved';
						})
				    }catch (e) {
						toast.error('error', {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
					} finally {
						this.clickOnButton = false;
					}


						// .catch((error: any) => {
						// 	toast.error(error, {
						// 		timeout: 1000,
						// 		closeOnClick: true,
						// 		closeButton: 'button',
						// 		icon: true
						// 	});
						// });
				}
				if (showToast === 'deleted') {
					toast.info(`Updated Successfully`, {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
					});
				} else if (showToast === 'saved') {
					toast.info(`Updated Successfully`, {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
					});
				}
				await this.getOrgControlFrameWorks(this.$route.params.tenantId);
			} else if (this.thisScreen == 'rootScreen') {

				let removedIds = this.previousSelectedFrameworks.filter((id: any) => {
					return !this.selectedFrameWorks.includes(id);
				});
				if (removedIds.length > 0) {
					let payload: any = [{ selectedFrameWorks: removedIds, createdBy: this.userInfo._id, createdAt: new Date() }];

					try{
						await this.$http.post(`${process.env.VUE_APP_CONTROLS_API_URL}/api/tenant/${this.$route.params.tenantId}/${2}/delete`, payload).then(async (res: any) => {
						toast.info(`Deleted Successfully`, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
					});
						}catch (e) {
						toast.error('error', {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
					} finally {
						this.clickOnButton = false;
					}
				}

			 	this.addedIds = this.selectedFrameWorks.filter((id: any) => {
					
					let internalFrameObj =  this.allControlSetFrameworks.find((obj: any)=>{
						return obj._id == id
					})
					
					return !this.previousSelectedFrameworks.includes(id);					
				});
				
				
				if (this.addedIds.length > 0) {
					await this.getOrgControlFrameWorks(this.$route.params.tenantId);
					let totalLength = Object.keys(this.allOrganisationControlSets).length;
				
					if(totalLength==0){
						let internalArr: any
						Object.keys(this.allRootControlSets).map((frame: any)=> {
							if(frame === 'InternalControls'){
								internalArr = Object.values(this.allRootControlSets[frame]);
							}
						})
					   this.addedIds.push(internalArr[0]._id)
					}
				    let payload: any = [{ selectedFrameWorks: this.addedIds, createdBy: this.userInfo._id, createdAt: new Date() }];
					console.log("payload", payload);
						try{
							await this.$http
						.post(`${process.env.VUE_APP_CONTROLS_API_URL}/api/tenant/${this.mainTenant}/controls/add`, payload)
						.then(async (res: any) => {
								toast.info(`Added Successfully`, {
									timeout: 1000,
									closeOnClick: true,
									closeButton: 'button',
									icon: true
								});
							})
						}catch (e) {
							toast.error('error', {
								timeout: 1000,
								closeOnClick: true,
								closeButton: 'button',
								icon: true
							});
						} finally {
							this.clickOnButton = false;
						}
				}
				await this.getRootControlFrameWorks();
			}
			this.dataLoading = false;
		}
	},

	async mounted() {
		this.dataLoading = true;
		if (this.$route.path.includes('root')) {
			this.thisScreen = 'rootScreen';
		} else if (this.$route.path.includes('org')) {
			this.thisScreen = 'orgScreen';
		}
		this.mainTenant = this.$route.params.tenantId;
		await this.getAllTenants();

		let userDomain = this.userInfo.email.substring(this.userInfo.email.lastIndexOf('@') + 1);
		let userOrgDetails = userDomain.split('.')[0];

		let presentTenantObjFind = this.tenantsSelect.find((obj: any) => {
			return obj.value == this.$route.params.tenantId;
		});
		presentTenantObjFind != undefined ? (this.presentTenantObj = presentTenantObjFind) : (this.presentTenantObj = {});

		await this.getRootControlFrameWorks();
		// this.routesArray = [
		// 	{ name: "Tenants", routeName: "tenantFrameWorks" },
        // 	{ name: this.tenantsSelect[0].label, routeName: "root-Frameworks" },
		// ]
		this.routesArray = [
			{ name: "Tenants", routeName: "tenantFrameWorks" }
		];
         this.dataLoading = false;
		// Iterate over this.tenantsSelect and add each element to this.routesArray
		let root = this.tenantsSelect.find((obj: any)=>{return obj.value == this.$route.params.tenantId})
		this.routesArray.push({
			name: root != undefined ? root.label : '',
			routeName: `root-Frameworks`,
		});
		// for (let i = 0; i < this.tenantsSelect.length; i++) {
		// 	this.routesArray.push({
		// 		name: this.tenantsSelect[i].value == this.$route.params.tenantId ? this.tenantsSelect[i].label : '',
		// 		routeName: `root-Frameworks`
		// 	});
		// }

	}
});
</script>

