<template>
	<div class="flexcontainer pt-3 pl-5 pr-5">
		<div v-if="isLoading">
			<div class="row w-full flex justify-between">
				<h1 class="title">KPI's</h1>
				<!-- <input type="file" accept=".xls,.xlsx" class="hidden" ref="file" @change="fileReader($event)" /> -->
				<div class="flex flex-row-reverse">
					<button class="flex" @click="filterOpen" :class="!columnObject ? 'filterButton' : 'filterButtondisabled pointer-events-none'">
						Filters
						<img src="@/assets/filter.svg" class="h-3 ml-2.5" />
					</button>
					<button class="mr-3 btnprimary" @click="addKPI()">Add</button>
					<button class="btnprimary mr-2" @click="clickToOpen()">Import</button>
					<input type="file" class="hidden" accept=".xls,.xlsx" ref="file" @change="fileReader($event)" />
				</div>
			</div>
			<div>
				<tableData :tableRowData="tableRow" :headersData="headers" :loadData="dataLoading"></tableData>
			</div>
			<popup v-if="showPopup" :popUp="showPopup">
				<template v-slot:header>
					<div class="flex items-center justify-between py-4 px-2 rounded">
						<h1 class="pl-2">Manage KPI</h1>
						<button
							class="float-right text-3xl"
							@click="
								onCancelbtn();
								this.showPopup = false;
							"
						>
							<img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
						</button>
					</div>
				</template>
				<fieldset>
					<div>
						<div>
							<div class="statuscontainer popupbodycontainer">
								<div class="formcontainer flex flex-col">
									<!-- <div class="row flex flex-row w-full upperspacing">
										<div class="w-full">
											<label class="controllabel">KPI ID</label>
											<input
												type="text"
												class="inputboxstyling"
												v-model="allKpis.kpi_id"
												placeholder="KPI ID"
												disabled
												
											/>
											
										</div>
									</div> -->
									<div class="row flex flex-row w-full upperspacing">
										<div class="w-full">
											<label class="controllabel">KPI Name</label>
											<textarea
												type="text"
												class="inputboxstyling h-20 max-h-48"
												v-model.trim="allKpis.description"
												@input="v$.allKpis.description.$touch()"
												placeholder="KPI Name"
												:class="{
													'is-invalid': v$.allKpis.description.$error || isDuplicateData.description
												}"
											></textarea>
											<div v-if="v$.allKpis.description.$error" class="text-red-500">
												<div class="error-text" v-if="v$.allKpis.description.required.$invalid">Required</div>
												<div v-else-if="v$.allKpis.description.required && v$.allKpis.description.restrictCharacters100.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
											</div>
											<div v-if="isDuplicateData.description" class="text-red-500">
												<div class="error-text text-xs">KPI Name already exists.</div>
											</div>
										</div>
									</div>
									<div class="formcontainer flex flex-col upperspacing">
										<div class="row flex flex-row w-full">
											<div class="w-6/12">
												<label class="controllabel">Weightage (%)</label>
												<input
													type="number"
													class="inputboxstyling"
													v-model.trim="allKpis.weightage"
													maxlength="3"
													ref="editWeight"
													placeholder="Weightage (%)"
													@input="weightageLessThanZero(allKpis.weightage) || weightageGreaterThanZero(allKpis) && handleInput"
													:class="{
														'is-invalid': v$.allKpis.weightage.$error || validtaionWeightageError || numberLessThanZero,
													}"
												/>
												<div v-if="v$.allKpis.weightage.$error || validtaionWeightageError || numberLessThanZero" class="text-red-500">
													<div class="error-text" v-if="v$.allKpis.weightage.required.$invalid">Weightage (%) is required</div>
													<div class="error-text" v-else-if="(validtaionWeightageError && !v$.allKpis.weightage.required.$invalid) || (numberLessThanZero == true && !v$.allKpis.weightage.required.$invalid)">Weightage(%) is Invalid</div>
												</div>
											</div>
											<!-- <div class="w-6/12 p-2">
											<label class="controllabel">Target (%)</label>
											<input
												type="number"
												class="inputboxstyling"
												v-model="allKpis.target"
												placeholder="Target (%)"
												maxlength="3"
												:class="{
													'is-invalid': v$.allKpis.target.$error
												}"
											/>
											<div v-if="v$.allKpis.target.$error" class="text-red-500">
												<div class="error-text" v-if="v$.allKpis.target.required">Target (%) is Required</div>
												<div class="error-text" v-else-if="v$.allKpis.target.numeric.$invalid">Target (%) is Invalid</div>
											</div>
										</div> -->
										</div>
									</div>
									<!-- <div class="row flex flex-row w-full">
										<div class="w-6/12 p-2">
											<label class="controllabel">Reporting Frequency</label>
											<select
												class="inputboxstyling"
												v-model="allKpis.reportingFrequency"
												@change="emptyTargetTimePeriod(allKpis)"
												:class="{
													'is-invalid': v$.allKpis.reportingFrequency.$error
												}"
											>
												<option value="" selected disabled hidden>Select</option>
												<option v-for="value in controlRecurringArray" :key="value" :value="value.id">{{ value.label }}</option>
											</select>
											<div v-if="v$.allKpis.reportingFrequency.$error" class="text-red-500">
												<div class="error-text" v-if="v$.allKpis.reportingFrequency.required">Required</div>
											</div>
										</div> -->
									<!-- <div class="w-6/12 p-2">
											<label class="controllabel">Target Time Period</label>
											<select
												class="inputboxstyling"
												v-model="allKpis.targetTimePeriod"
												:class="{
													'is-invalid': v$.allKpis.targetTimePeriod.$error
												}"
												:disabled="!allKpis.reportingFrequency"
											>
												<option value="" selected disabled hidden>Select</option>
												<option v-for="value in controlRecurringArray" :key="value" :value="value.id" :disabled="value.id < allKpis.reportingFrequency">{{ value.label }}</option>
											</select>
											<div v-if="v$.allKpis.targetTimePeriod.$error" class="text-red-500">
												<div class="error-text" v-if="v$.allKpis.targetTimePeriod.required">Required</div>
											</div>
										</div> -->
									<!-- </div> -->
									<!-- <h1 v-if="hideFormula">Metrics</h1> -->
									<!-- <div class="row flex w-full gap-16">
										<div class="w-4/6">
											<div class="p-2.5">
												<div v-for="(metric, metricIndex) in allKpis.metrics" :key="metricIndex">
													<label class="controllabel">{{ metric.key }}</label>
													<input
														type="text"
														class="inputboxstyling"
														ref="editMetrics"
														v-model="allKpis.metrics[metricIndex].label"
														:class="{
															'is-invalid': v$.allKpis.metrics.$error && !v$.allKpis.metrics.$each.$response.$data[metricIndex].label.required
														}"
													/>
												</div>
												<div class="text-red-500" v-if="v$.allKpis.metrics.$error">
													<div class="error-text" v-if="v$.allKpis.metrics.$dirty || allKpis.metrics.length != 2">Metrics A and B are required</div>
												</div>
											</div>
										</div>

										<div class="w-2/6 flex justify-center items-center" v-if="hideFormula">
											<div class="">
												<label class="controllabel font-normal text-sm mb-1">Formula</label>
												<input type="text" class="inputboxstyling" :disabled="true" v-model="allKpis.isformula" placeholder="Formula" />
											</div>
										</div>
									</div> -->
								</div>
							</div>
						</div>
						<div v-if="allKpis._id" class="buttonposition flex justify-center items-center mb-4 mx-0">
							<button class="mr-1.5" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="onCancelbtn()">Cancel</button>
							<button :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="addOrUpdate(allKpis)">Update</button>
						</div>
						<div v-else class="buttonposition flex justify-center items-center mb-4 mx-0">
							<!-- <button class="mr-3" :class="allKpis.metrics.length >= 2 ? 'btndisabled pointer-events-none' : 'btnprimary'" @click="addMetrics(allKpis)">Add Metrics</button> -->
							<button class="mr-1.5" :class="disableStatus ? 'btndisabled pointer-events-none' : 'btnprimary'" @click="onCancelbtn()">Cancel</button>
							<button :class="disableStatus ? 'btndisabled pointer-events-none' : 'btnprimary'" @click="addOrUpdate(allKpis)">Save</button>
						</div>
					</div>
				</fieldset>
			</popup>
		</div>
		<!-- <loader v-if="showLoader" /> -->
	</div>
</template>
<style scoped>
td:nth-child(3) > div,
th:nth-child(3) > div,
td:nth-child(5) > div,
th:nth-child(5) > div {
	display: flex;
	justify-content: center;
}
tbody:last-child {
	border-bottom: 1px solid #e9e9e9;
}
::-webkit-scrollbar {
	width: 3px !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
<script lang="ts">
import * as XLSX from 'xlsx';
import { required } from '@vuelidate/validators';
import _ from 'lodash';
import useVuelidate from '@vuelidate/core';
import { helpers } from "@vuelidate/validators";
import { defineComponent } from 'vue';
import { emitter, toast } from '@/main';
import popup from '@/components/popup.vue';
import tableData from '@/components/tableData.vue';
const restrictCharacters100 = helpers.regex(/^.{0,100}$/);
export default defineComponent({
	data(): any {
		return {
			headers:["_id", 'KPI Name','Weightage (%)','kpiDelete'],
			v$: useVuelidate(),
			isLoading: false,
			showLoader: false,

			showFilters: false,
			hideFormula: false,
			showPopup: false,
			columnObject: false,
			dataLoading:true,
			numberLessThanZero : false,
			validtaionWeightageError: false,
			dummyList: [],
			controlsList: [],
			tableRow: [],
			fullkeys: ['description', 'weightage'],

			dummyObj: [],
			kpiList: [],
			allKpis: {
				kpi_id:'',
				weightage: '',
				description: ''
			},
			controlRecurringArray: [
				{ label: 'Daily', id: 1 },
				{ label: 'Weekly', id: 2 },
				{ label: 'Monthly', id: 3 },
				{ label: 'Quarterly', id: 4 },
				{ label: 'Half-Yearly', id: 5 },
				{ label: 'Yearly', id: 6 }
			],
			addedKpiNamesList: [],
			addedKpiNamesListExcludindSelectedKpi: [],
			allInternalControls:[]
		};
	},
	validations() {
		return{
			allKpis: {
				weightage: { required },
				description: { required, restrictCharacters100 }
			}
		}
	},
	components: {
		tableData,
		// loader,
		popup
	},
	async mounted() {
		this.dataLoading = true;
		await this.getAllInternalControls();
		await this.fetchKpisInfo();
		// emitter.on('manageKPIpopupdata', (isData: any) => {
		// 	console.log("isData", isData)
		// 	let comingId = isData.entityData._id;
		// 	let showObj = this.kpiList.find((obj: any) => {
		// 		return obj._id === comingId;
		// 	});
		// 	console.log("showObj", showObj)
		// 	this.showEditPopUp(showObj);
		// });
		emitter.off('deleteKpiRowFromTable');
		emitter.on('deleteKpiRowFromTable',(data:any)=>{
			console.log("deleteKpiRowFromTable",data)
			if(data != undefined){
				this.deleteKpi(data);
			}
		})
		this.dataLoading = false;
	},
	computed: {	
		isDuplicateData: function (): any {
			// console.log("this.kpiList", this.kpiList)
			// console.log("this.allKpis.description", this.allKpis.description)
			if(this.kpiList.length != 0){
				return {
					description: this.kpiList.some((kpi:any) =>{ 
						return this.allKpis.description && kpi.description && JSON.stringify(kpi.description).replace(/\s+/g, '').toLowerCase() == JSON.stringify(this.allKpis.description).replace(/\s+/g, '').toLowerCase()
					}),
				};
			} else {
				return false
			}
		},
		disableStatus: function (): any {
			let output;
			if (this.editPop) {
				let objCopy = Object.assign({}, this.allKpis);
				if (_.isEqual(objCopy, this.dummyObj)) {
					output = false;
				} else {
					output = true;
				}
			} else {
				if (_.isEqual(this.allKpis, this.dummyObj)) {
					output = true;
				} else {
					output = false;
				}
			}
			return output;
		}
	},
	methods:{
		async deleteKpi(obj:any){
			// /api/root/kpis/:kpiId/delete
			let kpiId:any 
			console.log("kjasfhkjsadfhaskjdf",obj)
			if(this.allInternalControls != undefined){
				this.allInternalControls.forEach((data:any)=>{
					let keysOfObj:any = Object.keys(data)
					if(keysOfObj.includes('kpis')){
						console.log("data.kpis",data.kpis)
						if((data.kpis != undefined || data.kpis != '' || data.kpis != null)&& Array.isArray(data.kpis)){
							data.kpis.map((kpi:any)=>{
								let keysOfObjKpi:any = Object.keys(kpi)
								if(keysOfObjKpi.includes('referenceKpiId') && obj._id == kpi.referenceKpiId){
									kpiId = kpi.referenceKpiId
								} else if(keysOfObjKpi.includes('kpiType') && obj._id == kpi._id ) {
									kpiId = kpi._id 
								} else {
									kpiId = obj._id 
								}
							})
						}
					}
				})
			}
			if(kpiId != undefined){
				try{
					
					await this.$http
					.delete(
					`${process.env.VUE_APP_CONTROLS_API_URL}/api/root/kpis/${kpiId}/delete`
					)
					.then(async () => {
					toast.info("Deleted", {
						timeout: 5000,
						closeOnClick: true,
						closeButton: "button",
						icon: true,
					});
					await this.fetchKpisInfo();
					});

				} catch(error){
					
					toast.error("This kpi is realted to internalControl", {
						timeout: 5000,
						closeOnClick: true,
						closeButton: "button",
						icon: true,
					});
				}
			} else {
				
				toast.error("This kpi is realted to internalControl", {
					timeout: 5000,
					closeOnClick: true,
					closeButton: "button",
					icon: true,
				});
			}
		},
		async getAllInternalControls(){
			try{
				await this.$http.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/root/controls/getall`).then((result: { data: any }) => {
					// console.log("lkjhgf",result.data)
					this.allInternalControls = result.data
					// console.log("lkjhgfthis.allInternalControls",this.allInternalControls)
				});

			} catch (err){

			}
		},
		// emptyTargetTimePeriod(kpi: any) {
		// 	if (kpi.targetTimePeriod < kpi.reportingFrequency) {
		// 		kpi.targetTimePeriod = '';
		// 	}
		// },
		// showEditPopUp(obj: any) {
		// 	// if (Number.isInteger(obj.targetTimePeriod)) {
		// 	// 	this.allKpis.targetTimePeriod = this.controlRecurringArray.find((itemOne: any) => {
		// 	// 		return itemOne.id == obj.targetTimePeriod;
		// 	// 	}).label;
		// 	// } else {
		// 	// 	this.allKpis.targetTimePeriod = '';
		// 	// }
		// 	// if (Number.isInteger(obj.reportingFrequency)) {
		// 	// 	this.allKpis.reportingFrequency = this.controlRecurringArray.find((itemOne: any) => {
		// 	// 		return itemOne.id == obj.reportingFrequency;
		// 	// 	}).label;
		// 	// } else {
		// 	// 	this.allKpis.reportingFrequency = '';
		// 	// }
		// 	// obj.isformula = 'A/B';
		// 	console.log("obj", obj)
		// 	this.showPopup = true;
		// 	let id = uuidv4();
		// 	this.allKpis.kpi_id=id;
		// 	this.allKpis = { ...obj };
		// 	this.dummyObj = {...this.allKpis};
		// 	this.editPop = true;
		// 	this.hideFormula = true;
		// },
		// addMetrics(kpi: { metrics: { label: string; key: string; value: string }[] }) {
		// 	this.hideFormula = true;
		// 	// this.allKpis.isformula = 'A/B';
		// 	// if (this.currentCode === 65) {
		// 	// 	// If 'A' is already selected, switch to 'B'
		// 	// 	this.currentCode = 66; // 'B' (ASCII code for 'A' + 1)
		// 	// } else if (this.currentCode === 66) {
		// 	// 	// If 'B' is already selected, switch back to 'A'
		// 	// 	this.currentCode = 65; // 'A'
		// 	// } else {
		// 	// 	this.currentCode = 65; // Default to 'A' if currentCode is not 'A' or 'B'
		// 	// }
		// 	// let singleMetric = String.fromCharCode(this.currentCode);
		// 	// this.kpi.metrics.push({ label: '', key: singleMetric, value: '' });
		// 	kpi.metrics.push({ label: '', key: 'A', value: '' });
		// 	kpi.metrics.push({ label: '', key: 'B', value: '' });
		// },
		handleInput(event : any) {
			console.log('event',event)
			this.allKpis.weightage = event.target.value.replace(/[^\d]/g, '');
		},
		weightageLessThanZero(weightage : any){
			if(weightage < 0 || weightage == 0){
				this.numberLessThanZero = true
			}else{
				this.numberLessThanZero = false
			}
		},
		weightageGreaterThanZero(kpi: any){
			if (kpi.weightage <= 100) {
				this.validtaionWeightageError = false;
			} else {
				this.validtaionWeightageError = true;
			}
		},
		filterOpen() {
			this.showFilters = !this.showFilters;
			emitter.emit('visible', this.showFilters);
		},
		async fileReader(oEvent: any) {
			var oFile = oEvent.target.files[0];
			var reader = new FileReader();
			reader.onload = async (e: any) => {
				var data = e.target.result;
				data = new Uint8Array(data);
				var workbook = XLSX.read(data, { type: 'array' });
				/* DO SOMETHING WITH workbook HERE */
				var first_sheet_name = workbook.SheetNames[0];
				/* Get worksheet */
				var worksheet = workbook.Sheets[first_sheet_name];
				var jsonObj: any = XLSX.utils.sheet_to_json(worksheet, {
					raw: true,
					header: ['description', 'weightage'],
					range: 1
				});
				let total = jsonObj.length;
				let valid = 0;
				let arr: any[] = [];
				jsonObj.forEach((item: any) => {let mandatoryCondition = item.description && parseInt(item.weightage) <= 100;
					let check = this.kpiList.every((obj: any)=>{
					    return obj.description && item.description && obj.description != item.description
					})
					if (mandatoryCondition && check) {
						arr.push(item);
					}
				});
				valid = arr.length;
				if (valid != 0) {
					await this.addKPIsSource(arr);
					
					toast.info(`Imported ${total} rows, Valid rows: ${valid}, Ignored rows: ${total - valid}`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				} else {
					toast.error(`Imported ${total} rows, Valid rows: ${valid}, Ignored rows: ${total - valid}`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				}
				 // At the end, clear the oEvent
				oEvent = null;  // or oEvent = undefined;
				console.log("oEvent after clearing:", oEvent);
			};
			if(oFile){
				reader.readAsArrayBuffer(oFile);
			}
		},
		onCancelbtn() {
			this.v$.$reset();
			this.validtaionWeightageError = false;
      		this.numberLessThanZero = false;
			this.currentCode = 66;
			if (!this.allKpis._id) {
				Object.keys(this.allKpis).forEach((prop) => {
					if(prop != 'kpi_id'){
						Array.isArray(this.allKpis[prop]) ? (this.allKpis[prop] = []) : (this.allKpis[prop] = '');
					}
				});
				this.hideFormula = false;
			} else {
				this.allKpis = {...this.dummyObj};
			}
		},
		async addKPI() {
			Object.keys(this.allKpis).forEach((prop) => {
				Array.isArray(this.allKpis[prop]) ? (this.allKpis[prop] = []) : (this.allKpis[prop] = '');
			});
			let numeric = 1;
			this.kpiList.forEach((item:any) => {
				if(Object.keys(item).includes('kpi_id') && item.kpi_id){
					const number = item.kpi_id.split(' ');
					if (parseInt(number[2]) && !isNaN(parseInt(number[2]))) { 
						let intNumber = parseInt(number[2]);
						numeric = Math.max(numeric, intNumber);
						numeric++;
					}
				}
			});
			let id = 'KPI - ' + numeric;
			// this.allKpis.kpi_id=id;
			this.hideFormula = false;
			this.showPopup = true;
			this.editPop = false;
			this.dummyObj = _.cloneDeep(this.allKpis);
		},
		async fetchKpisInfo() {
			this.kpiList = [];
			this.tableRow = [];
			this.dataLoading = true;
			try {
				await this.$http.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/root/kpis/get`).then((result: { data: any }) => {
					this.isLoading = true;
					this.showLoader = true;
					this.kpiList = result.data;
					console.log("kpiList", this.kpiList)
					this.dummyList = this.kpiList;
				});
			} catch (e) {
				toast.error('error', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
			this.showLoader = false;
			await this.makeTable();
			this.dataLoading = false;
		},
		async makeTable() {
			if (this.kpiList.length > 0) {
				this.kpiList.forEach(async (eachKPI: any) => {
					let finalKPIObj: any = {
						_id: eachKPI._id,
						// departments: eachKPI.departments,
						'KPI Name': eachKPI.description,
						'Weightage (%)': parseInt(eachKPI.weightage)
						// 'Target (%)': parseInt(eachKPI.target),
						// 'Target Time Period': Number(eachKPI.targetTimePeriod) ? parseInt(eachKPI.targetTimePeriod) : eachKPI.targetTimePeriod,
						// 'Reporting Frequency': Number(eachKPI.reportingFrequency) ? parseInt(eachKPI.reportingFrequency) : eachKPI.reportingFrequency
					};
					await this.tableRow.push(finalKPIObj);
					this.dataLoading = false;
				});
			} else {
				let finalKPIObj = {
					'KPI Name': '',
					'Weightage (%)': ''
					// 'Target (%)': '',
					// 'Target Time Period': '',
					// 'Reporting Frequency': ''
				};
				await this.tableRow.push(finalKPIObj);
				this.dataLoading = false;
			}
			for (const row of this.tableRow) {
				for (const value of Object.values(row)) {
					if (value == '') {
						this.columnObject = true;
					} else {
						this.columnObject = false;
					}
				}
			}
		},
		clickToOpen() {
			this.$refs.file.value = null
			this.$refs.file.click();
		},
		async addOrUpdate(kpi: any) {
			this.v$.$touch();
			let check:any = !Object.values(this.isDuplicateData).includes(true)
			if (!this.v$.$invalid && check) {
				// this.allKpis.isformula = 'A/B';
				if (this.numberLessThanZero == false && this.validtaionWeightageError == false) {
					this.addAction = true;
					let addData;
					addData = this.allKpis;
					if (kpi._id) {
						await this.addKPIsSource(addData, 'Update');
					} else {
						await this.addKPIsSource([addData], 'Save');
					}

					Object.keys(kpi).forEach((prop) => {
						Array.isArray(kpi[prop]) ? (kpi[prop] = []) : (kpi[prop] = '');
					});
					this.hideFormula = false;
					this.showPopup = false;
				} else {
					const editWeightRef = this.$refs.editWeight;
					editWeightRef.focus();
				}
				this.showLoader = true;
				this.v$.$reset();
			} 
			// else {
			// 	toast.error(`error`, {
			// 		timeout: 1000,
			// 		closeOnClick: true,
			// 		closeButton: 'button',
			// 		icon: true
			// 	});
			// }
			// this.showPopup = false;
			this.showLoader = false;
		},
		async addKPIsSource(payload: any, status: any) {
			try {
				if (status == 'Update') {
					let kpiId = payload._id;
					delete payload._id;
					await this.$http.post(`${process.env.VUE_APP_CONTROLS_API_URL}/api/root/kpi/${kpiId}/update`, payload).then(async () => {
						// if (status == 'saving') {
						toast.info(`Updated Successfully`, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
						// }
					});
				} else {
					await this.$http.post(`${process.env.VUE_APP_CONTROLS_API_URL}/api/root/kpis/add`, payload).then(async (result: any) => {
						if (status == 'Save') {
							toast.info(`Saved Successfully`, {
								timeout: 1000,
								closeOnClick: true,
								closeButton: 'button',
								icon: true
							});
						}
					});
				}
				await this.fetchKpisInfo();
			} catch (e) {
				toast.error(`Something went wrong`, {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
		}
	}
});
</script>