<template>
  <div class="pt-3 pl-5 pr-5">
    <div>
      <div
        class="row w-full justify-between flex"
        style="align-items: center"
      >
        <h1 class="title">Active Controls</h1>
        <div class="cardbody flex">
          <!-- <div class="flex flex-row-reverse mr-2 relative">
            <input type="file" accept=".xls,.xlsx" class="hidden" ref="file" @change="fileReader($event)" />
						<button class="btnprimary btns flex justify-center items-center" @click="showBlock = !showBlock">Add <img src="@/assets/Collapse.svg" alt="" class="ml-2" /></button>
						<ul class="card shadow-sm z-50 absolute cursor-pointer top-10" v-if="showBlock && showDropdown">
							<li @click="openFileModal" class="px-4 py-2 cursor-pointer border-b h-7 border-lightgrey hover:bg-lightgrey text-sm flex items-center">
								<div>Import</div>
							</li>
							<li @click="addControls" class="px-4 py-2 cursor-pointer border-b h-7 border-lightgrey hover:bg-lightgrey text-sm flex items-center">
								<div>Create</div>
							</li>
						</ul>
					</div> -->
          <div class="flex flex-row-reverse items-center">
            <button class="flex" @click="filterOpen" :class="tableRow.length == 0 ? 'filterButtondisabled pointer-events-none' : 'filterButton'">
              Filters
              <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
            </button>
          </div>
        </div>
      </div>
      <div>
        <tableData
          :tableRowData="tableRow"
          :headersData="headers"
          :loadData="dataLoading"
        ></tableData>
        <div v-if="toolTipActive">
          <tool-tip :tipText="toolTipText"></tool-tip>
        </div>
      </div>
      <popup v-if="showModal" :popUp="showModal">
        <template v-slot:header>
          <div class="flex items-center justify-between py-4 px-2 rounded">
            <!-- <h1 class="pl-2">{{ controlObject.controlNumber }}</h1> -->
            <h1 class="pl-2">Active Control</h1>
            <button class="float-right text-3xl" @click="onCancelbtn('close')">
              <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
            </button>
          </div>
        </template>
        <div>
        <fieldset>
          <div class="pl-4 pb-4 pr-4">
            <div class="w-full mt-3">
              <div class="controllabel">Control Title</div>
              <div class="text-sm mt-2" v-if="controlObject.controlTitle != undefined">{{ controlObject.controlTitle }}</div>
              <div class="text-sm mt-2" v-else>N/A</div>
            </div>
            <div class="flex mt-3">
              <div class="w-1/2">
                <div class="controllabel">CCID</div>
                <div class="text-sm mt-2" v-if="controlObject.clientUniqueId != undefined">{{ controlObject.clientUniqueId }}</div>
                <div class="text-sm mt-2" v-else>N/A</div>
              </div>
              <!-- <div class="w-1/2">
                <div class="controllabel">Tier</div>
                <div class="text-sm mt-2">{{ controlObject.tier }}</div>
              </div> -->
              <div class="w-1/2">
                <div class="controllabel">Control Frequency</div>
                <div class="text-sm mt-2" v-if="controlObject.controlFrequency != undefined">{{ getControlFrequency(controlObject.controlFrequency) }}</div>
                <div class="text-sm mt-2" v-else>N/A</div>
              </div>
            </div>
            
            <div class="flex mt-3">
              <div class="w-1/2">
                <div class="controllabel">Control Number</div>
                <div class="text-sm mt-2" v-if="controlObject.controlNumber != undefined">{{ controlObject.controlNumber }}</div>
                <div class="text-sm mt-2" v-else>N/A</div>
              </div>
              <div class="w-1/2">
                <div class="controllabel">Control Class</div>
                <div class="text-sm mt-2" v-if="controlObject.controlClass != undefined">{{ controlObject.controlClass }}</div>
                <div class="text-sm mt-2" v-else>N/A</div>
              </div>
            </div>
            <div class="flex mt-3">
              <div class="w-1/2">
                <div class="controllabel">Owner</div>
                <div v-if="controlObject.owner != undefined" class="text-sm mt-2">{{ controlObject.owner }}</div>
                <div v-else>N/A</div>
              </div>
              <div class="w-1/2">
                <div class="controllabel">Priority</div>
                <div v-if="controlObject.controlPriority && typeof controlObject.controlPriority === 'number'"	class="rounded h-5 w-24 mt-2" :class="colorLabelBg(controlObject.controlPriority)">
									<div class="flex items-center justify-center text-xs" :class="colorLabelText(controlObject.controlPriority)">
										{{ getProirityLabel(controlObject.controlPriority) }}
									</div>
								</div>
                <div v-else class="text-sm">N/A</div>
              </div>
            </div>
            
            <div class="flex mt-3">
              <div class="w-1/2">
                <div class="controllabel">Control Family</div>
                <div class="text-sm mt-2" v-if="controlObject.controlFamily != undefined">{{ controlObject.controlFamily }}</div>
                <div class="text-sm mt-2" v-else>N/A</div>
              </div>
              <div class="w-1/2">
                <div class="controllabel">Control Type</div>
                <div class="text-sm mt-2" v-if="controlObject.controlType != undefined">{{ controlObject.controlType }}</div>
                <div class="text-sm mt-2" v-else>N/A</div>
              </div>
            </div>

            <div class="w-full mt-3">
              <div class="controllabel">Control Guidance</div>
              <div class="text-sm mt-2" v-if="Object.keys(controlObject).includes('controlGuidance') && (controlObject.controlGuidance != undefined || controlObject.controlGuidance != '')">{{ controlObject.controlGuidance }}</div>
              <div class="text-sm mt-2" v-else>N/A</div>
            </div>
            <div class="w-full  mt-3">
              <div class="controllabel">Audit Methodology</div>
              <div class="text-sm mt-2" v-if="Object.keys(controlObject).includes('controlAuditMethodology') && (controlObject.controlAuditMethodology != undefined || controlObject.controlAuditMethodology != '')">{{ controlObject.controlAuditMethodology }}</div>
              <div class="text-sm mt-2" v-else>N/A</div>
            </div>
            <div class="w-full  mt-3">
              <div class="controllabel">Audit Success Criteria</div>
              <div class="text-sm mt-2" v-if="Object.keys(controlObject).includes('controlAuditSuccessCriteria') && (controlObject.controlAuditSuccessCriteria != undefined || controlObject.controlAuditSuccessCriteria != '')">{{ controlObject.controlAuditSuccessCriteria }}</div>
              <div class="text-sm mt-2" v-else>N/A</div>
            </div>
            <div class="w-full  mt-3">
              <div class="controllabel">Policies</div>
              <div class="text-sm mt-2" v-if="Object.keys(controlObject).includes('controlPolicies') && (controlObject.controlPolicies != undefined || controlObject.controlPolicies != '')">{{ controlObject.controlPolicies }}</div>
              <div class="text-sm mt-2" v-else>N/A</div>
            </div>
            <div class="w-full  mt-3">
              <div class="controllabel">Maintenance Task</div>
              <div class="text-sm mt-2" v-if="Object.keys(controlObject).includes('controlMaintenanceTask') && (controlObject.controlMaintenanceTask != undefined || controlObject.controlMaintenanceTask != '')">{{ controlObject.controlMaintenanceTask }}</div>
              <div class="text-sm mt-2" v-else>N/A</div>
            </div>
            
            <div class="w-full  mt-3">
              <div class="controllabel">FrameWork</div>
              <div class="text-sm mt-2" v-if="Object.keys(controlObject).includes('controlOptionFrameWork') && (controlObject.controlOptionFrameWork != undefined || controlObject.controlOptionFrameWork != '')">{{ controlObject.controlOptionFrameWork }}</div>
              <div class="text-sm mt-2" v-else>N/A</div>
            </div>
            <div class="w-full  mt-3">
              <div class="controllabel">Controls</div>
              <div class="text-sm mt-2" v-if="Object.keys(controlObject).includes('frameworkControls') && (controlObject.frameworkControls != undefined || controlObject.frameworkControls != '')">{{ controlObject.frameworkControls }}</div>
              <div class="text-sm mt-2" v-else>N/A</div>
            </div>
            <!-- <div class="w-1/2 mt-3">
              <div class="controllabel">Link Kpi's</div>
              <div class="text-sm mt-2">{{ controlObject.KPIs }}</div>
            </div> -->
          </div>
        </fieldset>
          </div>
      </popup>
    </div>
    <!-- <loader v-if="showLoader" /> -->
  </div>
</template>
<style scoped>
.optionColors{
   color : #4D4D4D !important;
}
.multiselect-tag {
  font-size: 12px;
  font-weight: 600;
}
.multiselect-tag.is-disabled {
  background-color: #059669 !important;
}
.multiselect.is-disabled {
  background-color: white !important;
}
.tbodylast:last-child {
  border-bottom: 1px solid #e9e9e9;
}
::-webkit-scrollbar {
  width: 3px !important;
}
.borderRed {
  border: 1px solid red !important;
}
</style>
<script lang="ts">
import { emitter, toast } from "@/main";
import * as XLSX from 'xlsx';
import _ from "lodash";
import { defineComponent } from "vue";
// import loader from "../../components/loader.vue";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { groupBy } from "lodash";
import Multiselect from 'vue-multiselect';
import popup from "@/components/popup.vue";
import tableData from "@/components/tableData.vue";
import { requiredIf } from '@vuelidate/validators';
import toolTip from "@/components/toolTip.vue";
import { mapGetters } from "vuex";
export default defineComponent({
  data(): any {
    return {
        headers: [
        "_id",
        "clientUniqueId",
        "activeControlsTitle",
        "tier",
        "controlOwner",
        "controlPriority",
        "KPIs",
      ],
      filteredControlFamilyOptions:[],
      allEntityOptions:[],
      v$: useVuelidate(),
      showBlock: false,
			showDropdown: false,
      isGlobal: false,
      isAdding:false,
      isLoading: false,
      showLoader: false,
      toolTipActive : false,
      showFilters: false,
      showModal: false,
      toolTipText : '',
      dummyList: [],
      controlsList: [],
      departmentsList: [],
      selectedControlForKPI: {},
      kpiListForControl: [],
      selectedControl: {},
      ControlFamilyArray:[],
      departmentSelected:true,
      filters: {},
      clickOnButton: false,
      tableRow: [],
      controlFamilies:[],
      columnNames: {
        // controlNumber: "Control Number",
        controlTitle: "Control Title",
        controlFamily: "Control Family",
        controlClass: "Control Class",
        kpi: "KPI's",
        link: "link",
      },
      nonTableRow: [],
      allDeptOptions:[],
      columnObject: false,
      priority: ["Critical", "High", "Moderate", "Low"],
      priorityArray: [
        { label: "Very Low", id: 1 },
        { label: "Low", id: 2 },
        { label: "Moderate", id: 3 },
        { label: "High", id: 4 },
        { label: "Critical", id: 5 },
      ],
      editObject: {},
      deptOptions: [],
      filteredDeptOptions: [],
      controlDepartmentArr: [],
      controlTypeArray: [
        { label: "Preventive", id: 1 },
        { label: "Detective", id: 2 },
        { label: "Corrective", id: 3 },
        { label: "Deterrent", id: 4 },
        { label: "Directive", id: 5 },
        { label: "Recovery", id: 6 },
        { label: "Compensating", id: 7 },
      ],
      controlFrequency: [
				{ label: 'Transactional', id: 1 },
				{ label: 'Daily', id: 2 },
				{ label: 'Weekly', id: 3 },
				{ label: 'Monthly', id: 4 },
				{ label: 'Quarterly', id: 5 },
				{ label: 'Semi Annualy', id: 6 },
				{ label: 'Annualy', id: 7 },
				{ label: 'Ad-Hoc', id: 8 }
			],
      optionsValue: [
        {
          id: 1,
          value: "KPI",
          route: "/manageKPI",
          presentRoute: "/org/controls/active",
        },
      ],
      allEntities:[],
      frameWorksList: [],
      frameworkControls: [],
      dummyControlList:[],
      relatedFrameControls: [],
      entitiesArray:[],
      internalControlsNames: [],
      controlObject: {
        controlFrequency:"",
        // controlNumber: "",
        controlPriority: "",
        controlTitle: "",
        controlClass: "",
        controlFamily: "",
        controlGuidance: "",
        controlAuditMethodology: "",
        controlAuditSuccessCriteria: "",
        controlMaintenanceTask: "",
        controlPolicies: "",
        controlType: "",
        departments: [],
        // entity:'',
        controlOptionFrameWork: '',
				frameworkControls: ''
      },
      dataLoading:true,
      optionsUserArray:[],
      allControlsList:[],
      users:[],
      fullKeys:['departments', 'controlTitle', 'controlGuidance', 'controlClass', 'controlFamily', 'controlType', 'controlPriority', 'controlFrameWork'],
    };
  },
 validations(){
        return{
			controlObject:{
				departments:{required},
        // entity:{
        //   required: requiredIf(() => {
				// 		return (this.controlObject.entitytypecodeid == 20303);
				// 	})
        // },
        // controlNumber: { required },
        controlTitle: { required },
        controlFrequency: { required },
				frameworkControls:{
					required: requiredIf(() => {
						return Object.keys(this.controlObject).includes('controlOptionFrameWork') && this.controlObject.controlOptionFrameWork != '';
					})
				},
				
			},
        }
    },
  
  components: {
    // loader,
    popup,
    Multiselect,
    tableData,
    toolTip
  },
  async mounted() {
    // 		await this.orgFetchControlsInfo();

    await this.getAllControls();
    	emitter.on('*', (type, booleanValue) => {
			if (type == 'apps-sidemenubar' || type == 'toggle-sidebar') {
				this.showBlock == true ? ((this.showBlock = false), (this.showDropdown = false)) : '';
			} 
      else if( type == 'deActiviteToolTip' || type == 'activiteToolTip'){
        return ;
      }
      else {
				if (this.showBlock == true) {
					if (this.showDropdown == false) {
						this.showDropdown = true;
					} else {
						this.showDropdown = false;
						this.showBlock = false;
					}
				} else {
					this.showDropdown = false;
				}
			}
		});
	  emitter.off("activeControlPopup");
    emitter.on("activeControlPopup", (isData: any) => {
      let comingId = isData.entityData._id;
      let showObj = this.allControlsList.find((obj: any) => {
        return obj._id === comingId;
      });
      this.showEditPopUp(showObj, isData.indexx);
      console.log("showObj",showObj)
    });
    emitter.on('close-dropdown',()=>{this.showBlock = false}) 
  },
  async created() {
    this.dataLoading = true;
    await this.getAllUsers();
    // await this.getallEntities();
    await this.allActiveControls();
    // await this.fetchDepartmentsList();
    // await this.fetchControlsInfo();
    emitter.off('activiteToolTip');
    emitter.on('activiteToolTip', (data: any) => {
        this.toolTipActive = true;
        this.toolTipText = data.tipText
    });
    emitter.off('deActiviteToolTip');
    emitter.on('deActiviteToolTip', (data: any) => {
        this.toolTipActive = false;
        this.toolTipText = ''
    });
    this.dataLoading = false;
  },
  computed:{
        ...mapGetters({
            userInfo: 'userInfo',
            dbPriority:'dbPriority',
        }),
    disableStatus: function (): any {
			let disableStatusCondition;
			if (this.controlObject._id) {
				if (JSON.stringify(this.controlObject) == JSON.stringify(this.editObject)) {
					disableStatusCondition = false;
				} else {
					disableStatusCondition = true;
				}
			} else {
				let val: any = [];
				Object.values(this.controlObject).forEach((value: any) => {
					val.push(value);
				});
				for (let n of val) {
					if (n != '') {
						disableStatusCondition = true;
					}
				}
			}
			return disableStatusCondition;
		}

  },
  methods: {
    asyncFind(query:any){
      if(query == ''){
          this.allDeptOptions = []
      }else if(query.length >= 1){
          this.allDeptOptions = this.filteredDeptOptions.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
      }
    },
    selectedOption(opt:any){
      let isNewOption = !this.ControlFamilyArray.includes(opt);
      isNewOption==true ? this.ControlFamilyArray.push(opt) : ''
    },
    asyncFind2(query:any){
      if(query == ''){
          this.allEntityOptions = []
      }else if(query.length >= 1){
          this.allEntityOptions = this.entitiesOptions.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
      }
    },
    getProirityLabel(val:any){
        if (val === 'N/A') { 
            return ''; 
        } else {
            let a = this.dbPriority.find((obj: any) => obj.value == val);
            return (val && typeof val === 'number') ? a.key : '';
        }
    },
    async allActiveControls(frameName: any) {
        await this.$http.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/controls/active/getall`).then((res: any) => {
            this.allControlsList = res.data;
        });
        this.tableRow = []
        
        this.allControlsList.map((control: any) => {
          let keysOfObject:any = Object.keys(control)
          let ObjectIncludedTier:any
          // let priority:any = this.getProirityLabel(control.controlPriority)
          let kpisLength:any
          let owner:any = this.optionsUserArray.find((user:any)=>{return user.value == control.owner})
          if(owner == undefined || owner == '' || owner == null){
            owner = 'N/A'
          } else{
            owner = owner.label
          }
          if(keysOfObject.includes('activityId')){
            ObjectIncludedTier = 'Activity'
          } else if(keysOfObject.includes('systemId')){
            ObjectIncludedTier = 'System'
          } else {
            ObjectIncludedTier ='N/A'
          }
          if(keysOfObject.includes('kpis')){
            kpisLength = control.kpis.length
          } else {
            kpisLength = 0
          }
          let obj:any = {
            _id: control._id,
            clientUniqueId: control.clientUniqueId != undefined || control.clientUniqueId != '' ? control.clientUniqueId :'N/A',
            activeControlsTitle: control.controlTitle != undefined || control.controlTitle != '' ? control.controlTitle : 'N/A',
            tier: ObjectIncludedTier != undefined || ObjectIncludedTier != '' ? ObjectIncludedTier : 'N/A',
            controlOwner: control.owner != undefined || control.owner != '' ? owner : 'N/A',
            controlPriority: control.controlPriority != undefined || control.controlPriority != '' ? control.controlPriority : 'N/A',
            KPIs: kpisLength != undefined || kpisLength != '' ? kpisLength : 'N/A',
          }
          this.tableRow.push(obj)
        });
		},
    async getAllUsers() {
        await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
            this.users = res.data;
        });
        for (var i = 0; i < this.users.length; i++) {
            this.optionsUserArray.push({ label: this.users[i].name, value: this.users[i]._id });
        }
    },
    async getallEntities() {
      this.entitiesOptions = [];
      let result: any;
      await this.$http
        .get(
          `${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`
        )
        .then((res: any) => {
          result = res.data;
          this.allEntities = res.data;
        });
      for (var i = 0; i < result.length; i++) {
        this.entitiesArray.push({
          label: result[i].entityName,
          value: result[i]._id,
        });
      }
    },
    asyncFindControlFamily(query:any) {
        query = query.trim();
        if (query == '') {
				this.filteredControlFamilyOptions = [];
			} else {
				this.filteredControlFamilyOptions = this.ControlFamilyArray.filter((item:any) => 
					item?.toLowerCase().includes(query.toLowerCase())
				);
				let isNewOption = !this.ControlFamilyArray.some((item:any) => 
					item?.toLowerCase() === query.toLowerCase()
				);
				if (isNewOption && query !== '') {
					this.filteredControlFamilyOptions.push(query);
				}
			}
        // // let isNewOption = !this.ControlFamilyArray.includes(query);
        // let isNewOption = !(this.ControlFamilyArray.some((item:any) => {return item?.toLowerCase().includes(query.toLowerCase())}))

  	    // if(isNewOption == true && query !=''){
        //   // this.ControlFamilyArray.push(query);
        //   this.filteredControlFamilyOptions=[]
        //   this.filteredControlFamilyOptions.push(query)
        // }else if (query == '') {
        //     this.filteredControlFamilyOptions = [];
        // } else if (query.length > 1) {
        //     this.filteredControlFamilyOptions = this.ControlFamilyArray.filter((item:any) => {return item?.toLowerCase().includes(query.toLowerCase())});
        // }
    },
    addCustomTag(newTag:any) {
      // if (!this.filteredControlFamilyOptions.includes(newTag)) {
        this.filteredControlFamilyOptions.push(newTag);
      // }
    },
    getControlNumberAndTitle(name: any){
      const result: any = Object.values(this.jsonArray).flatMap(x => x).find((y: any) => y.controlNumber === name);
      if (result) {
        return `${result.controlNumber}--${result.controlTitle}`;
      }
    },
    getFrameworkControls(name:any){
      // this.controlObject.frameworkControls = '';
      console.log("kjhgjhg",this.jsonArray)
      this.frameworkControls=[];
      if(this.jsonArray != undefined){
        let array=Object.values(this.jsonArray)
        array.forEach((x:any)=>{
          x.forEach((y:any)=>{
            if(y.controlFrameWork == name){
              this.frameworkControls.push(`${y.controlNumber}` + `--` + `${y.controlTitle }`);
            }
          })
        })
      }
		},
    async getAllControls(frameName: any) {
			emitter.emit('visible', false);
			this.showFilters = false;
			try {
				this.isLoading = false;
				const result = await this.$http.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/allControls`);
				this.isLoading = true;
				this.columnObject = false;
				this.rowData = result.data;
				//  jsonArray: any = [];
				let arr: any = [];
        if((this.rowData != null || this.rowData != undefined) && this.rowData.length != 0){
          this.rowData.forEach((item: any) => {
            if (item.controlFrameWork && item.controlNumber && Object.keys(item).includes('controlFrameWork') && Object.keys(item).includes('controlNumber') && !Object.keys(item).includes('isInternalControl')) {
              arr.push(item);
              this.jsonArray = this.groupByFrameworks(arr);
            }
          });
          console.log("lkjhg",this.jsonArray)
          // let array=Object.values(this.jsonArray)
          // array.forEach((data:any)=>{
          // 	data.forEach(())
          // })
          
          let displayFrameName = '';	
          if(this.jsonArray != undefined){
            Object.keys(this.jsonArray).forEach((key, index) => {
              if (frameName === index) {
                displayFrameName = key;
              }
              this.frameWorksList.push(key);
            });
          }
          // Object.values(jsonArray).forEach((value, index) => {
          // 	this.frameworkControls = value
          // 	// if (frameName === index) {
          // 	// 	displayFrameName = key;
          // 	// }
          // 	// this.frameWorksList.push({value: index, displayName: key });
          // });

          // this.frameworkControls = [];
          // this.frameworkControls = arr
          //   .filter((control: any) => control.controlFrameWork && control.controlFrameWork === displayFrameName)
          //   .map(
          //     (control: any) => ({ value: control._id, label: control.controlNumber + control.controlTitle })
          //     // .push({value: control._id, label: control.controlNumber + control.controlTitle})
          //   );
        }
			} catch (e) {
				this.isLoading = false;
				toast.error(`Something went wrong`, {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
		},
    groupByFrameworks(arr: any[]) {
			return arr.reduce((group: any, product: any) => {
				const { controlFrameWork } = product;
				group[controlFrameWork] = group[controlFrameWork] ?? [];
				group[controlFrameWork].push(product);
				return group;
			}, {});
		},
    	addControls() {
			// (this.controlObject.controlFrameWork = ''),
			// 	(this.controlObject.controlMapFrameWork = ''),
			// 	(this.controlObject.controlNumber = ''),
			// 	(this.controlObject.controlPriority = ''),
			// 	(this.controlObject.controlTitle = ''),
			// 	(this.controlObject.controlClass = ''),
			// 	(this.controlObject.controlFamily = ''),
			// 	(this.controlObject.controlGuidance = ''),
			// 	(this.controlObject.controlAuditMethodology = ''),
			// 	(this.controlObject.controlAuditSuccessCriteria = ''),
			// 	(this.controlObject.controlMaintenanceTask = ''),
			// 	(this.controlObject.controlPolicies = ''),
			// 	(this.controlObject.controlType = ''),
			// 	(this.controlObject.controlFrequency = '');
			// 	this.controlObject.controlOptionFrameWork='';
			// 	this.controlObject.frameworkControls=[];

			this.v$.$reset();
			// this.editObject = {};
			Object.keys(this.controlObject).forEach((prop) => {
				this.controlObject[prop] = '';
			});
			Object.keys(this.editObject).forEach((prop) => {
				this.editObject[prop] = '';
			});
      // if(this.controlObject._id){
        delete this.controlObject._id;
      // }
			this.showModal = true;
		},
    getControlFrequency(id:any){
      if(id != undefined || id != '' || id != null){
        let frequency:any = this.controlFrequency.find((data:any)=>{return data.id == id})
        return frequency.label
      } else {
        return 'N/A'
      }
    },
    onCancelbtn(status: any) {
      // this.showModal = false;
			this.v$.$reset();
			this.showBlock = false;
			if (status === 'close') {
				Object.keys(this.controlObject).forEach((prop) => {
          Array.isArray(this.controlObject[prop])
            ? (this.controlObject[prop] = [])
            : (this.controlObject[prop] = "");
        });
        delete this.controlObject._id
				this.showModal = false;
			} else if (status == 'cancel') {
       Object.keys(this.controlObject).forEach((prop) => {
          Array.isArray(this.controlObject[prop])
            ? (this.controlObject[prop] = [])
            : (this.controlObject[prop] = "");
        });
				this.controlObject = { ...this.editObject };
       
        
			}
      this.isGlobal = false;
		},
    async updateControl(controlObject: any) {
			this.v$.$touch();
			if (!this.v$.$invalid) {
        this.clickOnButton = true;
        let copyOfControlObject:any = {...controlObject}
				// let uniqueControlNumber = this.dummyControlList.some((item: any) => item.controlNumber === copyOfControlObject.controlNumber);

				// if (uniqueControlNumber) {
					this.showModal = false;
          this.isGlobal = false
					let objId = copyOfControlObject._id;
          if(copyOfControlObject.controlOptionFrameWork && copyOfControlObject.frameworkControls){
            let arr = []
            let frameWork
            if(copyOfControlObject.controlOptionFrameWork){
              frameWork = copyOfControlObject.controlOptionFrameWork
            }
            
            // let controls = copyOfControlObject.frameworkControls
            let controls 
            if(copyOfControlObject.frameworkControls){
              let parts = copyOfControlObject.frameworkControls.split("--");
              controls = parts[0];
            }
            delete copyOfControlObject.frameworkControls
            delete copyOfControlObject.controlOptionFrameWork
            let obj = {
              framework: frameWork,
              controls: [controls]
            }
            arr.push(obj)
            copyOfControlObject.frameworks = arr
          }
          copyOfControlObject.departments = copyOfControlObject.departments.value
          if(this.controlObject.entitytypecodeid == 20303){
             copyOfControlObject.entity = copyOfControlObject.entity.value
          }
					// delete copyOfControlObject._id;
					try {
						await this.$http.post(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/controlset/update/${objId}`, _.omit(copyOfControlObject, ['_id'])).then((result: any) => {
							toast.info(`Updated Successfully`, {
								timeout: 1000,
								closeOnClick: true,
								closeButton: 'button',
								icon: true
							});
						});
					} catch (e) {
						toast.error(`Something went wrong`, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
					}finally {
              this.clickOnButton = false;
          }
          this.v$.$reset();
					await this.fetchControlsInfo();
				// } else {
				// 	this.showModal = false;
				// 	toast.error(`Control Number should be unique`, {
				// 		timeout: 1000,
				// 		closeOnClick: true,
				// 		closeButton: 'button',
				// 		icon: true
				// 	});
				// }
			}
		},
    async orgFetchControlsInfo() {
			this.controlsList = [];
			this.showLoader = true;
			this.isLoading = false;
			await this.$http
				.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/allControls?frameWork=InternalControls`)
				.then((result: { data: any }) => {
					this.isLoading = true;
					this.showLoader = false;
					result.data.forEach((item: any) => {
						if (item.isInternalControl) {
							this.controlsList.push(item);
						}
					});
          this.editObject = {...this.controlObject}
				})
				.catch((error: any) => {
					this.isLoading = false;
					(this.showLoader = false),
						toast.error(`Save failed with ${error}`, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
				});
			//this.makeTable();
		},
    async saveCustomControl(controlObject: any) {
			this.v$.$touch();
			if (!this.v$.controlObject.$invalid) {
				// let uniqueControlNumber: any;
				// uniqueControlNumber = this.dummyControlList.some((item: any) => item.controlNumber === controlObject.controlNumber);
				let arr = []
        let frameWork
        if(controlObject.controlOptionFrameWork){
          frameWork = controlObject.controlOptionFrameWork
        }
				
				// let controls = controlObject.frameworkControls
        let controls 
        if(controlObject.frameworkControls){
          let parts = controlObject.frameworkControls.split("--");
          controls = parts[0];
        }
				delete controlObject.frameworkControls
				delete controlObject.controlOptionFrameWork
        if(frameWork != "" && controls != ""){
          let obj = {
					  framework: frameWork,
            controls: [controls]
          }
          arr.push(obj)
          controlObject.frameworks = arr
        }
				// if (uniqueControlNumber == false) {
					this.showModal = false;
					let payload;
					let framework = 'InternalControls';
					payload = {
						controlFrameWork: framework,
						controlsList: [{ ...controlObject, isInternalControl: true }]
					};

					await this.addControlSource([payload], 'toast', controlObject);
				// } else {
				// 	this.showModal = false;
				// 	toast.error(`Control Number should be unique`, {
				// 		timeout: 1000,
				// 		closeOnClick: true,
				// 		closeButton: 'button',
				// 		icon: true
				// 	});
				// }
			}
		},
    // async saveCustomControl(controlObject: any) {
		// 	this.v$.$touch();
		// 	if (!this.v$.controlObject.$invalid) {
    //     this.clickOnButton = true;
    //     let copyOfControlObject: any = {...controlObject}
    //     console.log("copyOfControlObject", copyOfControlObject)
		// 		let uniqueControlNumber: any;
		// 		// uniqueControlNumber = this.dummyControlList.some((item: any) => item.controlNumber === copyOfControlObject.controlNumber);
    //     if ((this.dummyControlList != undefined || this.dummyControlList != null) && this.dummyControlList.length != 0) {
    //       // uniqueControlNumber = this.dummyControlList.some((control: any) => {
    //       //   console.log("control", control)
    //       //   if(copyOfControlObject.departments.type == 20301 && control.departments == copyOfControlObject.departments.value){
    //       //     console.log("33333333")
    //       //     return control.controlNumber == copyOfControlObject.controlNumber;
    //       //   } else {
    //       //     console.log("11111111")
    //       //     if(control.departments == copyOfControlObject.departments.value && control.entity == copyOfControlObject.entity){
    //       //       console.log("22222222")
    //       //       return control.controlNumber == copyOfControlObject.controlNumber;
    //       //     }
    //       //   }
    //       // });
    //       uniqueControlNumber = this.dummyControlList.some((control: any) => {
    //         // console.log("control", control);
            
    //         const isDepartmentMatch = control.departments == copyOfControlObject.departments.value;
    //         const isControlNumberMatch = control.controlNumber == copyOfControlObject.controlNumber;

    //         if (copyOfControlObject.departments.type === 20301) {
    //             console.log("Checking for type 20301");
    //             return isDepartmentMatch && isControlNumberMatch;
    //         } else if (copyOfControlObject.departments.type === 20303){
    //             console.log("Checking for type 20303");
    //             return isDepartmentMatch && control.entity == copyOfControlObject.entity && isControlNumberMatch;
    //         }
    //     });

    //     }
		// 		let arr = []
    //     let frameWork
    //     if(copyOfControlObject.controlOptionFrameWork){
    //       frameWork = copyOfControlObject.controlOptionFrameWork
    //     }
		// 		copyOfControlObject.entity = copyOfControlObject.entity == 'Global'? 'global' : copyOfControlObject.entity.value
		// 		// let controls = copyOfControlObject.frameworkControls
    //     let controls 
    //     if(copyOfControlObject.frameworkControls){
    //       let parts = copyOfControlObject.frameworkControls.split("--");
    //       controls = parts[0];
    //     }
		// 		delete copyOfControlObject.frameworkControls
		// 		delete copyOfControlObject.controlOptionFrameWork
    //     if(frameWork != "" && controls != ""){
    //       let obj = {
		// 			  framework: frameWork,
    //         controls: [controls]
    //       }
    //       arr.push(obj)
    //       copyOfControlObject.frameworks = arr
    //     }
    //     console.log("uniqueControlNumber", uniqueControlNumber)
		// 		if (uniqueControlNumber == false) {
		// 			this.showModal = false;
    //       this.isGlobal = false;
		// 			let payload;
		// 			let framework = 'InternalControls';
		// 			payload = {
		// 				controlFrameWork: framework,
		// 				controlsList: [{ ...copyOfControlObject, isInternalControl: true }]
		// 			};
		// 			await this.addControlSource([payload], 'toast', copyOfControlObject);
		// 		} else {
		// 			this.showModal = false;
    //       this.isGlobal = false;
		// 			toast.error(`Control Number should be unique`, {
		// 				timeout: 1000,
		// 				closeOnClick: true,
		// 				closeButton: 'button',
		// 				icon: true
		// 			});
		// 		}
		// 	}
		// },
    async addControlSource(payload: any, action: any, controlObject: any) {
			try {
        // let departmentId = controlObject.departments
        payload[0].controlsList.forEach((obj: any)=>{
          obj.controlNumber = ''
          delete obj.departments
          delete obj.entity
        })
        delete payload.frameworkControls
				delete payload.controlOptionFrameWork
        // this.rowData.map((obj:any) =>{
        //   if(obj.controlNumber){
        //     this.internalControlsNames.push(JSON.stringify(obj.controlNumber).toLowerCase())
        //   }
        // })
        // console.log("internalControlsNames", this.internalControlsNames)
        // if(this.internalControlsNames.includes(JSON.stringify(payload[0].controlsList[0].controlNumber).toLowerCase())){
        //   console.log("iiiinnnnnnnnnnn")
        //   let controlRouteId = this.rowData.find((obj: any)=>{return payload[0].controlsList[0].controlNumber == obj.controlNumber})
        //   console.log("controlRouteId",controlRouteId)
        //   let payloadForControlIds: any = [];
        //   let departmentRouteId = controlObject.departments;
        //   // let selectedTeamObjs: any = this.departmentsList.filter((obj: any)=>{
        //   //   return obj._id == controlObject.entity
        //   // })
        //   let departmentRouteObj = this.departmentsList.find((obj: any)=>{
        //     return obj._id == departmentRouteId.value
        //   })
        //   console.log("departmentRouteObj111111111", departmentRouteObj)
        //   if(departmentRouteObj.entityType == 20301){
        //     payloadForControlIds = [
        //       {
        //         departmentId: departmentRouteId.value,
        //         // entityId: entityRouteId,
        //         entitytypecodeid: 20301,
        //         controlIds: [controlRouteId._id]
        //       }
        //     ];
        //   } else {
        //     payloadForControlIds = [
        //       {
        //         departmentId: departmentRouteId.value,
        //         entityId: departmentRouteObj.entityId,
        //         entitytypecodeid: 20303,
        //         controlIds: [controlRouteId._id]
        //       }
        //     ];
        //   }
        //   await this.$http.post(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/controls/manageDepartments`, payloadForControlIds).then(() => {
              
        //   });
        //   if (action == 'toast') {
        //       toast.info(`Saved Successfully`, {
        //         timeout: 1000,
        //         closeOnClick: true,
        //         closeButton: 'button',
        //         icon: true
        //       });
        //     }
        // } else {
          console.log("OUTWTTTTTTTTTTTTTT", payload)
          await this.$http.post(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/controlset/create`, payload).then(async (result: any) => {
            let payloadForControlIds: any = [];
            let controlRouteId: any[] = [];
            controlRouteId = result.data.insertedIds[0];
            let departmentRouteId = controlObject.departments;
            // let entityRouteId = controlObject.entity;
            let departmentRouteObj = this.departmentsList.find((obj: any)=>{
              return obj._id == departmentRouteId.value
            })
            console.log("departmentRouteObj22222222222", departmentRouteObj)
            if(departmentRouteObj.entityType == 20301){
              payloadForControlIds = [
                {
                  departmentId: departmentRouteId.value,
                  // entityId: entityRouteId,
                  entitytypecodeid: 20301,
                  controlIds: [controlRouteId]
                }
              ];
            } else {
              payloadForControlIds = [
                {
                  departmentId: departmentRouteId.value,
                  entityId: departmentRouteObj.entityId,
                  entitytypecodeid: 20303,
                  controlIds: [controlRouteId]
                }
              ];
            }
            await this.$http.post(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/controls/manageDepartments`, payloadForControlIds).then(() => {
              
            });
            if (action == 'toast') {
              toast.info(`Saved Successfully`, {
                timeout: 1000,
                closeOnClick: true,
                closeButton: 'button',
                icon: true
              });
            }
          })
        // }
        await this.fetchControlsInfo();
        await this.makeTable();
			} catch (e) {
				toast.error(`Something went wrong`, {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}finally {
          this.clickOnButton = false;
      }
		},
    showEditPopUp(data: any, index: any) {
      let keysOfObject:any = Object.keys(data)
        let ObjectIncludedTier:any
        let kpisLength:any
        let owner:any = this.optionsUserArray.find((user:any)=>{return user.value == data.owner})
        if(owner == undefined || owner == '' || owner == null){
          data.owner = 'N/A'
        } else{
          data.owner = owner.label
        }
        if(keysOfObject.includes('activityId')){
          ObjectIncludedTier = 'Activity'
        } else if(keysOfObject.includes('systemId')){
          ObjectIncludedTier = 'System'
        } else {
          ObjectIncludedTier ='N/A'
        }
        if(keysOfObject.includes('kpis')){
          kpisLength = data.kpis.length
        } else {
          kpisLength = 0
        }
        let framework:any 
        let frameworkControls:any
        if(data.frameworks && data.frameworks != undefined){
            data.frameworks.map((frameworkObj:any) => {
                framework = frameworkObj
            });
            framework.controls.map((control:any)=>{
                frameworkControls = control
            })
            data.controlOptionFrameWork = framework.framework != undefined ? framework.framework : ''
            data.frameworkControls = frameworkControls != undefined || frameworkControls != null ? frameworkControls :''
        }
        console.log("dat111a",data)
        // let obj:any = {
        //   clientUniqueId: data.clientUniqueId != undefined || data.clientUniqueId != '' ? data.clientUniqueId :'N/A',
        //   controlTitle: data.controlTitle != undefined || data.controlTitle != '' ? data.controlTitle : 'N/A',
        //   tier: ObjectIncludedTier != undefined || ObjectIncludedTier != '' ? ObjectIncludedTier : 'N/A',
        //   controlOwner: data.owner != undefined || data.owner != '' ? owner : 'N/A',
        //   controlPriority: data.controlPriority != undefined || data.controlPriority != '' ? data.controlPriority : '',
        //   KPIs: kpisLength != undefined || kpisLength != '' ? kpisLength : 'N/A',
        // }
      this.controlObject = { ...data };
      this.editObject = { ...this.controlObject };
      this.showModal = true;
      this.getFrameworkControls(data.controlOptionFrameWork)
    },
    openFileModal() {
      this.$router.push({ name: 'controlsImport' });
			// this.$refs.file.click();
			// this.showBlock = false;
		},
    makeTable() {
      this.tableRow = [];
      if (this.controlsList.length > 0) {
        this.controlsList.forEach((item: any) => {
          const kpisLength = item.kpis ? item.kpis.length : 0;
          let frameName;
          let tableObj:any
          frameName = (item.frameworks != undefined && item.frameworks.length != 0) ? item.frameworks[0].framework : "N/A";
          let deptName = this.departmentsList.find((eachdepartment: any) => {return item.departments == eachdepartment._id});
          let owner :any
          if(deptName != undefined){
            owner = this.optionsUserArray.find((user:any)=>{return user.value == deptName.teamOwner})
          }
          console.log("deptName111111111111", owner)
          let entityName:any
          if(deptName && deptName.entityType == 20301){
            entityName = 'Global'
          }
          else if (deptName && deptName.entityType == 20303){
              entityName = deptName.entityName != undefined ? deptName.entityName : 'N/A'
          }
          tableObj = {
            _id: item._id,
            departments: deptName != undefined ? deptName.teamName : 'N/A',
            frameWork: frameName ? frameName : 'N/A',
            cCID: item.clientUniqueId != undefined ? item.clientUniqueId : 'N/A',
            activeControlsTitle: item.controlTitle  != undefined ? item.controlTitle : 'N/A',
            Class: item.controlClass ? item.controlClass : 'N/A',
            owner: owner != undefined ? owner.label : 'N/A',
            KPIs: kpisLength,
            Entity: entityName != undefined ? entityName : 'N/A' 
          }
          this.tableRow.push(tableObj);
          this.dataLoading = false;
        });

        this.columnObject = false;
      } else {
        this.columnObject = true;
        // this.tableRow.push({
        //   _id: "",
        //   departments: "",
        //   frameWork: "",
        //   cCID: "",
        //   activeControlsTitle: "",
        //   Class: "",
        //   "KPIs": "",
        // });
      }
    },
    fileReader(oEvent: any): any {
			var oFile = oEvent.target.files[0];
			var reader = new FileReader();
			reader.onload = async (e: any) => {
				let data: any = e.target.result;
				data = new Uint8Array(data);
				var workbook = XLSX.read(data, { type: 'array' });
				var first_sheet_name = workbook.SheetNames[0];
				var worksheet = workbook.Sheets[first_sheet_name];
				var jsonObj = XLSX.utils.sheet_to_json(worksheet, {
					raw: true,
					header: this.fullKeys,
					range: 1
				});

				let total = jsonObj.length;
				let valid = 0;
				const jsonArray: any = [];
				let framework = '';
				const uniqueControlNumbers = new Set<string>();
				let booleanValue = true;
				for (let i = 0; i < jsonObj.length; i++) {
					let item: any;
					item = jsonObj[i];
					let arr = ['departments', 'controlNumber', 'controlFamily', 'controlClass', 'controlTitle', 'controlPriority', 'controlType'];
					let mandatoryCondition = arr.every((key: any) => {
						return Object.keys(item).includes(key) && item[key];
					});
					let PriorityObj = this.priorityArray.find((obj: any) => {
						return obj.label == item.controlPriority;
					});
					item.controlPriority = PriorityObj.id;

					// let TypeObj = this.controlTypeArray.find((obj: any) => {
					// 	return obj.label == item.controlType;
					// });
					// item.controlType = TypeObj.id;
          let controlCheck
          if((this.dummyControlList != undefined || this.dummyControlList != null) && this.dummyControlList.length > 0){
            controlCheck = this.dummyControlList.some((control: any)=>{
              return control.controlNumber === item.controlNumber
            })
          }
          console.log("controlCheck", controlCheck)
          let deptId = this.departmentsList.find((obj: any) => {
            return obj.teamName === item.departments
					});
          if(deptId != undefined || deptId != null){
            item.departments = deptId._id;
          }
					if (mandatoryCondition && (deptId != undefined || deptId != null) && !controlCheck) {
						if (!uniqueControlNumbers.has(item.controlNumber)) {
							booleanValue = false;
							framework = 'InternalControls';
							// objectArr.push(item);
              jsonArray[item.departments] = jsonArray[item.departments] || [];
							jsonArray[item.departments].push(item);
							uniqueControlNumbers.add(item.controlNumber);
						}
					}
				}
				if (booleanValue) {
					toast.error(`Control Number should be unique`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				}
        console.log("jsonArray", jsonArray)
				let controlSets: any = [];
				let controlsFinal: any = [];
				Object.keys(jsonArray).forEach((key) => {
					// const temp = {
					// 	controlFrameWork: framework,
					  jsonArray[key].map((item: any) => {
              controlsFinal.push({ ...item, isInternalControl: true })
            });
					// };
					valid = valid + controlsFinal.length;
					
				});
        const temp = {
          controlFrameWork: framework,
          controlsList: controlsFinal
        };
        controlSets.push(temp);
				// this.addControl = true;
        console.log("controlSets", controlSets)
				await this.addControlSource(controlSets, 'toast');
				if (controlSets.length > 0) {
					toast.info(`Imported ${total} rows, Valid rows: ${valid}, Ignored rows: ${total - valid}`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				} else {
					toast.error(`Something went wrong`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				}
			};
			reader.readAsArrayBuffer(oFile);
		},
    async fetchControlsInfo() {
      this.controlsList = [];
      this.tableRow = [];
      this.dataLoading = true;
      this.dummyList = [];
      this.showLoader = true;
      await this.$http
        .get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/controls/active`)
        .then((result: { data: any }) => {
          this.isLoading = true;
          this.showLoader = false;
          // _.each(
          //   result.data,
          //   (control: { department: any; departments: string | any[] }) => {
          //     // if (control.departments.length > 0) {
          //     //   _.each(control.departments, (dept: any) => {
          //     //     control.department = dept;
          //     //   });
          //     // }
          //     let updatedObject = Object.assign({}, control);
          //     controlList.push(updatedObject);
          //   }
          // );
          this.controlsList = result.data;
          this.dummyControlList = [...result.data];
          this.familyData = result.data.filter((obj: any) => {
            return Object.keys(obj).includes("controlFamily");
          });
          const groupedControlsFamily = groupBy(
            this.familyData,
            "controlFamily"
          );
          const listOfFamily = Object.keys(groupedControlsFamily);
          listOfFamily.forEach((key) => {
            if (key != "" || key != null || key != undefined) {
              this.ControlFamilyArray.push(key);
            }
          });
          this.dummyList = {...this.controlsList};
        })
        .catch((error: any) => {
          this.isLoading = false;
          (this.showLoader = false),
            this.$toasted.error(error, {
              theme: "toasted-primary",
              duration: 1000,
            });
        });
      await this.makeTable();
    },
    async fetchDepartmentsList() {
      this.showLoader = true;
      await this.$http
        .get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`)
        .then((res: { data: any }) => {
          this.isLoading = true;
          this.showLoader = false;
//           res.data = [
//     {
//         "_id": "66fbe72919c32f06aaaa5276",
//         "teamName": "Development Team",
//         "displayName": "Development Team 1",
//         "entityType": 20303,
//         "teamOwner": "66d4a36360fe55695a7212f3",
//         "entityId": "66f6ac8996fa7ac24639ad23",
//         "entityName": "My Entity 1",
//         "entityDisplayName": "MyEntity1",
//         "entityOwner": "6226dcaaf974bdd1169d5d85"
//     },
//     {
//         "_id": "66fbe72919c32f06aaaa5277",
//         "teamName": "Development Team",
//         "displayName": "Development Teamm",
//         "entityType": 20303,
//         "teamOwner": "66dadbed60fe55695a721337",
//         "entityId": "66f6acbf96fa7ac24639ad24",
//         "entityName": "My Entity 2",
//         "entityDisplayName": "MyEntity2",
//         "entityOwner": "6226dcaaf974bdd1169d5d85"
//     },
//     {
//         "_id": "66fbea9819c32f06aaaa5279",
//         "teamName": "Information Tech",
//         "displayName": "IT",
//         "entityType": 20303,
//         "teamOwner": "64e2fbf7bc8473611a0df291",
//         "entityId": "66f4f7d94ecb1064f6cec2cc",
//         "entityName": "Mannelabs Goa",
//         "entityDisplayName": "Goa Entity",
//         "entityOwner": "66d4a36360fe55695a7212f3"
//     },
//     {
//         "_id": "66fbea9819c32f06aaaa527a",
//         "teamName": "Information Tech",
//         "displayName": "IT",
//         "entityType": 20303,
//         "teamOwner": "64e2fbf7bc8473611a0df291",
//         "entityId": "66f4f79d4ecb1064f6cec2cb",
//         "entityName": "Mannelabs-BNGLR",
//         "entityDisplayName": "Mannelabs-BNGLR",
//         "entityOwner": "66de792260fe55695a72134c"
//     },
//     {
//         "_id": "66fbf97419c32f06aaaa527b",
//         "teamName": "Global Team",
//         "displayName": "Global Ux",
//         "entityType": 20301,
//         "globalHead": "64e2fbf7bc8473611a0df291",
//         "type": 20301
//     },
//     {
//         "_id": "66fe2716b706952aeddf113e",
//         "teamName": "Testing Team",
//         "displayName": "QA Test",
//         "entityType": 20303,
//         "teamOwner": "66d4a36360fe55695a7212f3",
//         "entityId": "66f6ac8996fa7ac24639ad23",
//         "entityName": "My Entity 1",
//         "entityDisplayName": "MyEntity1",
//         "entityOwner": "6226dcaaf974bdd1169d5d85"
//     },
//     {
//         "_id": "66fe2716b706952aeddf113f",
//         "teamName": "Testing Team",
//         "displayName": "QA Test",
//         "entityType": 20303,
//         "teamOwner": "66c6bec260fe55695a7212a2",
//         "entityId": "66f6acbf96fa7ac24639ad24",
//         "entityName": "My Entity 2",
//         "entityDisplayName": "MyEntity2",
//         "entityOwner": "6226dcaaf974bdd1169d5d85"
//     },
//     {
//         "_id": "66fe2ab5b706952aeddf1140",
//         "teamName": "Team Testing",
//         "displayName": "TESTING",
//         "entityType": 20301,
//         "globalHead": "66c6bec260fe55695a7212a2",
//         "type": 20301
//     },
//     {
//         "_id": "66fe2ae3b706952aeddf1141",
//         "teamName": "Team Security",
//         "displayName": "Security",
//         "entityType": 20303,
//         "teamOwner": "6226dcaaf974bdd1169d5d85",
//         "entityId": "66f4f7664ecb1064f6cec2ca",
//         "entityName": "Mannelabs Hyd",
//         "entityDisplayName": "Mannelabs Hyd",
//         "entityOwner": "6226dcaaf974bdd1169d5d85"
//     },
//     {
//         "_id": "66fe2ae3b706952aeddf1142",
//         "teamName": "Team Security",
//         "displayName": "Security",
//         "entityType": 20303,
//         "teamOwner": "6226dcaaf974bdd1169d5d85",
//         "entityId": "66f4f7d94ecb1064f6cec2cc",
//         "entityName": "Mannelabs Goa",
//         "entityDisplayName": "Goa Entity",
//         "entityOwner": "66d4a36360fe55695a7212f3"
//     },
//     {
//         "_id": "66fe2ae3b706952aeddf1143",
//         "teamName": "Team Security",
//         "displayName": "Security",
//         "entityType": 20303,
//         "teamOwner": "66d4a36360fe55695a7212f3",
//         "entityId": "66f4f8064ecb1064f6cec2cd",
//         "entityName": "Mannelabs Delhi",
//         "entityDisplayName": "Delhi Entity",
//         "entityOwner": "66c6bec260fe55695a7212a2"
//     },
//     {
//         "_id": "66fe2ae3b706952aeddf1144",
//         "teamName": "Team Security",
//         "displayName": "Security",
//         "entityType": 20303,
//         "teamOwner": "6226dcaaf974bdd1169d5d85",
//         "entityId": "66f4f82a4ecb1064f6cec2ce",
//         "entityName": "Mannelabs Sydney",
//         "entityDisplayName": "Sydney mannelabs",
//         "entityOwner": "66de792260fe55695a72134c"
//     },
//     {
//         "_id": "66fe2ae3b706952aeddf1145",
//         "teamName": "Team Security",
//         "displayName": "Security",
//         "entityType": 20303,
//         "teamOwner": "6226dcaaf974bdd1169d5d85",
//         "entityId": "66f4f79d4ecb1064f6cec2cb",
//         "entityName": "Mannelabs-BNGLR",
//         "entityDisplayName": "Mannelabs-BNGLR",
//         "entityOwner": "66de792260fe55695a72134c"
//     },
//     {
//         "_id": "66fe2ae3b706952aeddf1146",
//         "teamName": "Team Security",
//         "displayName": "Security",
//         "entityType": 20303,
//         "teamOwner": "6226dcaaf974bdd1169d5d85",
//         "entityId": "66f4fb674ecb1064f6cec2d5",
//         "entityName": "Mannelabs Chennai",
//         "entityDisplayName": "Chennai",
//         "entityOwner": "66de792260fe55695a72134c"
//     },
//     {
//         "_id": "66fe2d00b706952aeddf1147",
//         "teamName": "Cyber",
//         "displayName": "Crystalline",
//         "entityType": 20301,
//         "globalHead": "66c6bec260fe55695a7212a2"
//     },
//     {
//         "_id": "66fe2d77b706952aeddf1148",
//         "teamName": "Team 1",
//         "displayName": "TEAM",
//         "entityType": 20303,
//         "teamOwner": "6226dcaaf974bdd1169d5d85",
//         "entityId": "66f4f7664ecb1064f6cec2ca",
//         "entityName": "Mannelabs Hyd",
//         "entityDisplayName": "Mannelabs Hyd",
//         "entityOwner": "6226dcaaf974bdd1169d5d85"
//     },
//     {
//         "_id": "66fe2d77b706952aeddf1149",
//         "teamName": "Team 1",
//         "displayName": "TEAM11",
//         "entityType": 20303,
//         "teamOwner": "66d4a36360fe55695a7212f3",
//         "entityId": "66f4f7d94ecb1064f6cec2cc",
//         "entityName": "Mannelabs Goa",
//         "entityDisplayName": "Goa Entity",
//         "entityOwner": "66d4a36360fe55695a7212f3"
//     },
//     {
//         "_id": "6703593cb706952aeddf1152",
//         "teamName": "Testing Team",
//         "displayName": "Manual Testing",
//         "entityType": 20303,
//         "teamOwner": "66c6bec260fe55695a7212a2",
//         "entityId": "670358eab706952aeddf1151",
//         "entityName": "Testing_Test",
//         "entityDisplayName": "Test1",
//         "entityOwner": "66c6bec260fe55695a7212a2"
//     },
//     {
//         "_id": "67036a6db706952aeddf1156",
//         "teamName": "Development Team",
//         "displayName": "My Entity 1",
//         "entityType": 20303,
//         "teamOwner": "66c6bec260fe55695a7212a2",
//         "entityId": "66f4f7d94ecb1064f6cec2cc",
//         "entityName": "Mannelabs Goa",
//         "entityDisplayName": "Goa Entity",
//         "entityOwner": "66d4a36360fe55695a7212f3"
//     },
//     {
//         "_id": "67036a6db706952aeddf1157",
//         "teamName": "Development Team",
//         "displayName": "My Entity 1",
//         "entityType": 20303,
//         "teamOwner": "66c6bec260fe55695a7212a2",
//         "entityId": "66f4f7664ecb1064f6cec2ca",
//         "entityName": "Mannelabs Hyd",
//         "entityDisplayName": "Mannelabs Hyd",
//         "entityOwner": "6226dcaaf974bdd1169d5d85"
//     },
//     {
//         "_id": "67036a98b706952aeddf1158",
//         "teamName": "Development Team",
//         "displayName": "Development Team",
//         "entityType": 20301,
//         "globalHead": "66c6bec260fe55695a7212a2"
//     }
// ]
          this.departmentsList = res.data;
        })
        .catch((error: any) => {
          this.isLoading = false;
          (this.showLoader = false),
            toast.error(`Save failed with ${error}`, {
              timeout: 1000,
              closeOnClick: true,
              closeButton: "button",
              icon: true,
            });
        });
      this.deptOptions = this.departmentsList.map((item: any) => {
        return { value: item._id, label: item.teamName, type: item.entityType ? item.entityType : item.type };
      });
      this.filteredDeptOptions = [];
      this.departmentsList.map((item: any) => {
        let myItem:any = this.filteredDeptOptions.find((obj:any)=>{return obj.value == item._id});
        if(myItem == undefined){
          this.filteredDeptOptions.push({ value: item._id, label: item.teamName, type: item.entityType ? item.entityType : item.type  })
        }
      });
    },
    filterOpen() {
      if (this.columnObject == false) {
        this.showFilters = !this.showFilters;
        emitter.emit("visible", this.showFilters);
      }
    },
    
    colorLabelText(value: any) {
            if (value == 1) {
                return ["text-lightgreen font-semibold"];
            } else if (value == 2) {
                return ["text-green font-semibold"];
            } else if (value == 3) {
                return ["text-yellow font-semibold"];
            } else if (value == 4) {
                return ["text-error font-semibold"];
            } else if (value == 5) {
                return ["text-darkred font-semibold"];
            }
        },
        colorLabelBg(value: any) {
            if (value == 1) {
                return ["bg-veryLowColor border-solid border border-lightgreen"];
            } else if (value == 2) {
                return ["bg-lowColor border-solid border border-green"];
            } else if (value == 3) {
                return ["bg-moderateColor border-solid border border-yellow"];
            } else if (value == 4) {
                return ["bg-highColor border-solid border border-error"];
            } else if (value == 5) {
                return ["bg-veryHighColor border-solid border border-darkred"];
            }
        },
  },
});
</script>