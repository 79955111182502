<template>
	<div class="pr-5 pl-5 pt-3">
		<div class="row w-full mb-3 justify-between flex" style="align-items: center">
			<h1>Systems</h1>
			<div class="cardbody">
				<div class="flex flex-row-reverse items-center">
					<div class="flex flex-row-reverse items-center">
						<button class="flex" @click="filterOpen" :class="tableRow.length == 0 ? 'filterButtondisabled pointer-events-none' : 'filterButton'">
							Filters
							<img src="@/assets/filter.svg" class="h-3 ml-2.5" />
						</button>
					</div>
				</div>
			</div>
		</div>
        <div class="mt-3">
            <p class="headerText">
            Data Oragnisation is the practice of categorizing and classifyinf data to make it more usable.Similar to a file folder,where we keep important documents ,you'll need to arrange your data in the most logical and orderly fashion ,so you -- and anyone else who accesses it -- can easily find what they're looking for .Data organization is the practice  of categorizing and classifying data to make it more usable. Similar to a file folder, when we keep important documents,you'll need to arrange your data in the most logical and orderly fashion.
            </p>
        </div>
		<div>
			<tableData :tableRowData="tableRow" :headersData="headers" :tableRouteArr="optionsValue" :loadData="dataLoading"></tableData>
			<div v-if="toolTipActive">
				<tool-tip :tipText="toolTipText"></tool-tip>
			</div>
		</div>
		
	</div>
</template>
<style scoped>
</style>
<script lang="ts">
import { emitter, toast } from '@/main';
import { defineComponent } from 'vue';
import useVuelidate from '@vuelidate/core';
import tableData from '@/components/tableData.vue';
import popup from '@/components/popup.vue';
import toolTip from '@/components/toolTip.vue';
// import loader from '@/components/loader.vue';
export default defineComponent({
	data(): any {
		return {
			headers:['systemName','sytemCategory','model','vendorCategory','vendor','no.of controls'],

			v$: useVuelidate(),
			departmentsList: [],
			tableRow: [],
			activityObj: {},
			orgUsersList: [],
			optionsUserArray: [],
			controlsCountList: [],
			columnObject: false,
			showActivityPopup: false,
			showLoader: false,
			showFilters: false,
			criticalityArray: [
				{ label: 'Very Low', id: 1 },
				{ label: 'Low', id: 2 },
				{ label: 'Moderate', id: 3 },
				{ label: 'High', id: 4 },
				{ label: 'Critical', id: 5 }
			],
			toolTipActive : false,
			toolTipText : '',
			optionsValue: [
				{
					id: 1,
					value: 'Select',
					route: '/departmentControls',
					presentRoute: '/org/controls/controlMapping'
				}
			], 
			dataLoading:true,
      systems:[],
      vendorList:[],
		};
	},
	components: {
		tableData,
		// loader,
		popup,
		toolTip
	},
	mounted() {
		this.dataLoading = false;
		for (const row of this.tableRow) {
			for (const value of Object.values(row)) {
				if (value == '') {
					this.columnObject = true;
				} else {
					this.columnObject = false;
				}
			}
		}
		emitter.off('controlMappingData')
        emitter.on("controlMappingData", (isData: any) => {
			let comingId = isData.entityData._id;
			let showObj = this.departmentsList.find((obj: any) => obj._id === comingId);
			// this.showEditPopUp(showObj);
        });
		emitter.off('activiteToolTip');
		emitter.on('activiteToolTip', (data: any) => {
			this.toolTipActive = true;
			this.toolTipText = data.tipText
		});
		emitter.off('deActiviteToolTip');
		emitter.on('deActiviteToolTip', (data: any) => {
			this.toolTipActive = false;
			this.toolTipText = ''
		});
	},
	methods: {
		filterOpen() {
			if(this.columnObject == false){
				this.showFilters = !this.showFilters;
				emitter.emit('visible', this.showFilters);
			}
		},
		showEditPopUp(obj: any) {
			console.log("obj", obj)
        //    this.$router.push({ name: 'departmentControls', params: { id:  obj._id } });
			this.showActivityPopup = true;
			let a = this.criticalityArray.find((value: any)=>{return obj.criticality== value.id}).label;
			let owner = this.optionsUserArray.find((user: any) => user.value === obj.teamOwner || obj.globalHead==user.value)?.label || 'N/A';
			this.activityObj = {...obj}
			this.activityObj.teamOwner = owner
			this.activityObj.criticality = a
		},
    async getAllVendors(){
        // this.vendorCategoryDisplayList = [];
        this.vendorList = [];
        // this.vendorLabelList = []
        try{
            await this.$http
                .get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/20003`)
                .then((response: any) => {
                    
                    let arr:any = response.data;
                    this.vendorList = arr
                    // this.vendorList = [];

                    // arr.map((item:any)=>{
                    //     this.vendorCategoryDisplayList.push(item.vendorCategory);
                    //     let a = {value:item._id,label:item.name};
                    //     this.vendorLabelList.push(a)
                    // })
                })

        } catch(error: any) {
            toast.error('Error', {
              timeout: 1000,
              closeOnClick: true,
              closeButton: 'button',
              icon: true
            });
        };
    },
    async getAllSystems() {
        let entityId='20005'
        let systems1:any
        try{
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/${entityId}`).then((res: any) => {
              this.systems = res.data;
            });
            this.systems.map((data:any)=>{
                // headers:['systemName','category','model','vendorCategory','vendor','no.of controls'],
                let vendor:any = this.vendorList.find((obj:any)=>{ return obj._id == data.vendor})
                let lengthofMappedControls:any
                let category:any
                let model:any

                if(Object.keys(data).includes('mappedControls')){
                    lengthofMappedControls = data.mappedControls.length
                } else {
                    lengthofMappedControls = 0
                }

                if(Object.keys(data).includes('model')){
                  model = data.model
                } else {
                  model = "N/A"
                }




                let obj:any = {
                    _id:data._id,
                    systemName: data.systemName != undefined && data.systemName != '' ? data.systemName : 'N/A',

                    sytemCategory: data.systemCategory != undefined && data.systemCategory != '' ? data.systemCategory : 'N/A',

                    model:data.model != undefined && data.model != '' ? model : 'N/A',

                    vendorCategory: data.vendorCategory != '' && data.vendorCategory != undefined ? data.vendorCategory : 'N/A',

                    vendor:data.hostingType != undefined && data.hostingType != '' ? data.hostingType : 'N/A',

                    "no.of controls":lengthofMappedControls,
                }
                this.tableRow.push(obj)
                console.log("obj",obj);
            })
        } catch (error:any){
            toast.error('Error', {
                timeout: 1000,
                closeOnClick: true,
                closeButton: 'button',
                icon: true
            });
        }
        
    },
	},
	async created() {
		this.dataLoading = true;
        await this.getAllVendors()
        await this.getAllSystems();
	}
});
</script>

