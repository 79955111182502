<template>
    <div class="pt-3 pb-10 pl-5 pr-5">
        <div class="mb-2">
            <breadCrumb :routesArray="routesArray"></breadCrumb>
        </div>
        <div class="flex justify-between items-center h-8">
            <div>
                <h1 v-if="systemObj.systemName != undefined || systemObj.systemName != ''">{{systemObj.systemName}}</h1>
                <h1 v-else>System</h1>
            </div>
            <div class="flex items-center">
                <button v-if="selectedTabIndex == 0" @click="customPopupPopup" class="btnprimary mr-3 flex items-center">Add</button>
                <button
                    class="flex filterButton"
                    @click="filtersOpen()"
                >
                    Filters
                    <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                </button>
            </div>
        </div>

        <div class="mt-3">
			<p class="headerText">
				This course helps you to Understand current data protection legislation and underlying principles. It also helps you to Understand what Privacy Awareness is, what PII is, PII Processing, Individual Rights, Cross Border Transfers, PII Breaches, Privacy By Design. This course helps you
				to understand what Information Security is, awareness on information security, different kinds of security breaches happening in daily life, physical security, email security, Knowing your responsibilities when dealing with Information Security.
			</p>
		</div>

        <div class="mt-5 bg-white border border-lightgrey relative rounded">
            <div class="px-5 pb-5 pt-5 w-full h-full">
                <div class="group-buttons" v-if="isSmallScreen">
                    
                    <div class="absolute left-0 cursor-pointer" @click="scrollTabs('left')" :class="{ 'disabled pointer-events-none': (selectedTabIndex === 0 || currentGroupIndex === 0) && isSmallScreen }">
                        <img src="@/assets/leftArrowFilled.svg" class="arrow left-0" />
                    </div>
                    <div class="absolute right-0 cursor-pointer" @click="scrollTabs('right')" :class="{ 'disabled pointer-events-none': (selectedTabIndex === carouselHead.length - 1 || currentGroupIndex >= totalGroups - 1) && isSmallScreen }">
                        <img src="@/assets/rightArrowFilled.svg" class="arrow right-0" />
                    </div>
                </div>
                
                <div>
                    <div>
                        <div class="flex flex-row borderBottom cursor-pointer tab-container" ref="tabContainer" @scroll="handleScroll">
                            <div class="pb-1" :class="selectedTabIndex == tab.id ? 'adjust header font-semibold text-primary':'text-sm text-disabledgrey'" v-for="(tab, index) in visibleTabs" :key="index" @click="toggleTabs(tab)">
                                <div>
                                    <div ref="actualIndex" class="cursor-pointer">{{ tab.displayName }}</div>
                                    <div :class="{ progress: selectedTabIndex === tab.id }" class="h-1 p-0.75 relative rounded"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="progressbarB rounded h-1"></div>
                </div>

                <div class="w-full">
                    <div v-if="selectedTabIndex == 0" class="noScrollbar tabStructure1 overflow-y-auto">
                        <div class="tableCornerRadius">
                            <div class="table-container">
                                <table>
                                    <thead>
                                        <th class="header cursor-pointer w-12" >
                                            <div class="flex items-center w-4">
                                                <input type="checkbox" v-model="selectAllOptionActiveControls" class="checkbox cursor-pointer" :disabled="sortedDataActiveControls.length == 0" @change="selectAllActiveControls($event)" />
                                            </div>
                                        </th>
                                        <th scope="col" v-for="column in activeControlHeaders" @click="sort(column)" :class="getHeaderProperties0('width',column)" :key="column" class="bg-white capitalize">
                                            <div class="flex items-center" :class="getHeaderProperties0('widthInner',column)">
                                                <div class="pl-5" v-if="column == 'controlPriority'">
                                                    {{ 'Priority' }}
                                                </div>
                                                <div v-else>{{ getHeaderProperties0('name',column) }}</div>
                                                <div class="sort cursor-pointer" :class="className == column ? classSort : 'opacity-50'"></div>
                                            </div>
                                        </th>
                                        <tr v-if="showFilters">
                                            <td></td>
                                            
                                            <td v-for="(name, index) in activeControlHeaders" class="bg-white" :key="index">
                                                <select v-if="name == 'classificationType'"  class="widthForSelect" :class="allFilters[name] == null ? 'inputboxstylingForSelect' : 'stylingForInputboxstylingForSelect'" v-model="allFilters[name]">
                                                    <option value="" selected disabled hidden>Select</option>
                                                    <option v-for="pair in controlTypeValues" :key="pair" :value="pair.id">{{ pair.value }}</option>
                                                </select>
                                                <select v-else-if="name == 'controlPriority'"  class="widthForSelect" :class="allFilters[name] == null ? 'inputboxstylingForSelect' : 'stylingForInputboxstylingForSelect'" v-model="allFilters[name]">
                                                    <option value="" selected disabled hidden>Select</option>
                                                    <option v-for="pair in dbPriority" :key="pair" :value="pair.value">{{ pair.key }}</option>
                                                </select>
                                                <select v-else-if="name == 'controlFrequency'"  class="widthForSelect" :class="allFilters[name] == null ? 'inputboxstylingForSelect' : 'stylingForInputboxstylingForSelect'" v-model="allFilters[name]">
                                                    <option value="" selected disabled hidden>Select</option>
                                                    <option v-for="pair in controlFrequencyArray" :key="pair" :value="pair.id">{{ pair.label }}</option>
                                                </select>

                                                <input v-else type="text" class="searchForInputBoxStyling filter-font-size" placeholder="Search" v-model="allFilters[name]" />
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody v-if="sortedDataActiveControls.length == 0">
                                        <tr class="cursor-pointer rowHeight">
                                            <td class="relative h-10" :colspan="activeControlHeaders.length+1">
                                                <div class="flex justify-center"><p class="text-xs font-normal">No Records Found</p></div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody class="nonEmptyTable" v-else>
                                        <tr v-for="(controlObject,index) in sortedDataActiveControls" :key="index" class="cursor-pointer rowHeight">
                                            <td>
                                                <input class="checkbox" type="checkbox" v-model="checkedActiveControls" @change="changedActiveControlInput()" :value="controlObject._id" />
                                            </td>
                                            <td scope="col" v-for="column in activeControlHeaders" @click="showControlDetailsPopup(controlObject)" @mouseout="doFalse()" :key="column">
                                                <div v-if="column == 'classificationType'">
                                                    <div>{{ getControlTypeValue( controlObject['classificationType'] ) }}</div>
                                                </div>

                                                <div v-else-if="column == 'controlFrequency'">
                                                    <div>{{ getFrequencyValue( controlObject['controlFrequency'] ) }}</div>
                                                </div>

                                                <div v-else-if="column == 'controlPriority'">
                                                    <div
                                                        class="flex items-center justify-center rounded h-5 w-24"
                                                        :class="colorLabelBg(controlObject[column])"
                                                    >
                                                        <div :class="colorLabelText(controlObject[column])">
                                                            {{ getProirityLabel(controlObject[column]) }}
                                                        </div>
                                                    </div>
                                                    
                                                </div>

                                                <div v-else>
                                                    <div class="table-ellipsis">
                                                        <p class="fullText" @mousemove="toolTip(column, $event, index, controlObject, 'text')">
                                                            {{ controlObject[column] }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div v-if="sortedDataActiveControls.length > 0">
                                <pagination :dynamicPagination="dynamicPagination" @currentPageUpdate="paginationUpdations" :sortedData="sortedDataActiveControls"></pagination>
                            </div>
                        </div>
                        <div v-if="toolTipActive">
                            <tool-tip :tipText="toolTipText"></tool-tip>
                        </div>
                    </div>

                    <div v-else-if="selectedTabIndex == 1" class="noScrollbar tabStructure1 overflow-y-auto">
                        <div class="tableCornerRadius">
                            <div class="table-container">
                                <table>
                                    <thead>
                                        <th class="header cursor-pointer" >
                                            <div class="flex items-center">
                                                <input type="checkbox" v-model="selectAllOptionAllControls" class="checkbox cursor-pointer" :disabled="allControlsList.length == 0" @change="selectAllAllControls($event)" />
                                            </div>
                                        </th>
                                        <th scope="col" v-for="column in allControlHeaders" @click="sort(column)" :class="getHeaderProperties1('width',column)" :key="column" class="bg-white capitalize">
                                            <div class="flex items-center"  :class="getHeaderProperties1('widthInner',column)">
                                                <div class="pl-5" v-if="column == 'controlPriority'">
                                                    {{ 'Priority' }}
                                                </div>
                                                <div v-else>{{ getHeaderProperties1('name',column) }}</div>
                                                <div class="sort cursor-pointer" :class="className == column ? classSort : 'opacity-50'"></div>
                                            </div>
                                        </th>
                                        <tr v-if="showFilters">
                                            <td></td>
                                            
                                            <td v-for="(name, index) in allControlHeaders" class="bg-white" :key="index">
                                                <select v-if="name == 'classificationType'"  class="widthForSelect" :class="allFilters[name] == null ? 'inputboxstylingForSelect' : 'stylingForInputboxstylingForSelect'" v-model="allFilters[name]">
                                                    <option value="" selected disabled hidden>Select</option>
                                                    <option v-for="pair in controlTypeValues" :key="pair" :value="pair.id">{{ pair.value }}</option>
                                                </select>
                                                <select v-else-if="name == 'controlPriority'"  class="widthForSelect" :class="allFilters[name] == null ? 'inputboxstylingForSelect' : 'stylingForInputboxstylingForSelect'" v-model="allFilters[name]">
                                                    <option value="" selected disabled hidden>Select</option>
                                                    <option v-for="pair in dbPriority" :key="pair" :value="pair.value">{{ pair.key }}</option>
                                                </select>

                                                <input v-else type="text" class="searchForInputBoxStyling filter-font-size" placeholder="Search" v-model="allFilters[name]" />
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody v-if="sortedDataAllControls.length == 0">
                                        <tr class="cursor-pointer rowHeight">
                                            <td class="relative h-10" :colspan="allControlHeaders.length + 1">
                                                <div class="flex justify-center"><p class="text-xs font-normal">No Records Found</p></div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody class="nonEmptyTable" v-else>
                                        <tr v-for="(controlObject,index) in sortedDataAllControls" :key="index" class="cursor-pointer rowHeight">
                                            <td>
                                                <input class="checkbox" type="checkbox" v-model="checkedAllControls" @change="changedAllControlInput()" :value="controlObject._id" />
                                            </td>
                                            <td scope="col" v-for="column in allControlHeaders" @click="showControlDetailsPopup(controlObject)" @mouseout="doFalse()" :key="column">
                                                <div v-if="column == 'classificationType'">
                                                    <div>{{ getControlTypeValue( controlObject['classificationType'] ) }}</div>
                                                </div>

                                                <div v-else-if="column == 'controlPriority'">
                                                    <div
                                                        class="flex items-center justify-center rounded h-5 w-24"
                                                        :class="colorLabelBg(controlObject[column])"
                                                    >
                                                        <div :class="colorLabelText(controlObject[column])">
                                                            {{ getProirityLabel(controlObject[column]) }}
                                                        </div>
                                                    </div>
                                                    
                                                </div>

                                                <div v-else>
                                                    <div class="table-ellipsis">
                                                        <p class="fullText" @mousemove="toolTip(column, $event, index, controlObject, 'text')">
                                                            {{ controlObject[column] }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div v-if="sortedDataAllControls.length > 0">
                                <pagination :dynamicPagination="dynamicPagination" @currentPageUpdate="paginationUpdations" :sortedData="sortedDataAllControls"></pagination>
                            </div>
                        </div>
                        <div v-if="toolTipActive">
                            <tool-tip :tipText="toolTipText"></tool-tip>
                        </div>
                    </div>
                </div>
            </div>
            
            <div v-if="selectedTabIndex == 0" class="bottom-7 bg-white h-14 flex justify-end items-center w-full rounded-b-sm" style="box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);">
                <div class="buttonposition flex justify-end items-center px-5 mx-5">
                    <button class="btnprimary mr-1.5" @click="cancelActiveControls()" :class="arraysAreEqual ? 'btndisabled pointer-events-none' : 'btnprimary'">Cancel</button>
                    <button class="btnprimary" @click="mapTheSelectedControlsToSystem(10303)" :class="arraysAreEqual ? 'btndisabled pointer-events-none' : 'btnprimary'">Save</button>
                </div>
                <!-- changeActiveControlsToActivity -->
            </div>

            <div v-if="selectedTabIndex == 1" class="bottom-7 bg-white h-14 flex justify-end items-center w-full rounded-b-sm" style="box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);">
                <div class="buttonposition flex justify-end items-center px-5 mx-5">
                    <button @click="onCancelAllControls()" :class="checkedAllControls.length == 0 ? 'btndisabled pointer-events-none' : 'btnprimary'" class="btnprimary mr-1.5" >Cancel</button>
                    <button @click="mapTheSelectedControlsToSystem(10301)" :class="checkedAllControls.length == 0 ? 'btndisabled pointer-events-none' : 'btnprimary'" class="btnprimary">Save</button>
                </div>
            </div>
        </div>
    </div>

    
    <popup v-if="customPopup" :popUp="customPopup">

        <template v-slot:header>
            <div class="flex items-center justify-between py-4 px-2 rounded">
                <h1 v-if="customControlObject != undefined && customControlObject.classificationType == 3 " class="pl-2">Custom Control</h1>
                <h1 v-else class="pl-2">Control</h1>
                <button class="float-right text-3xl" @click="onCancelbtnCustom('close')">
                    <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                </button>
            </div>
        </template>
        
        <div>
            <fieldset :disabled="customControlObject._id">
                <div>
                    <div class="statuscontainer popupbodycontainer">
                        <div class="formcontainer flex flex-col">
                            <div class="row flex flex-row w-full upperspacing">
                                <div class="w-full">
                                    <label class="controllabel">Title</label>
                                    <textarea 
                                        type="text-area"
                                        class="inputboxstyling mt-2"
                                        placeholder="Title"
                                        @input="v$.customControlObject.controlTitle.$touch()" 
                                        v-model.trim="customControlObject.controlTitle"
                                        :class="{
                                            'is-invalid': v$.customControlObject.controlTitle.$error
                                        }"
                                    ></textarea>
                                    <div v-if="v$.customControlObject.controlTitle.$error" class="text-red-500">
                                        <div class="error-text" v-if="v$.customControlObject.controlTitle.required.$invalid">Required</div>
                                        <div v-else-if="v$.customControlObject.controlTitle && v$.customControlObject.controlTitle.restrictCharacters100.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
                                    </div>
                                </div>
                            </div>
                
                            <div class="formcontainer flex flex-col upperspacing">
                                <div class="row flex flex-row w-full">
                                    <div class="w-6/12">
                                    <label class="controllabel">Number</label>
                                        <input
                                            type="text"
                                            class="inputboxstyling mt-2"
                                            v-model.trim="customControlObject.controlNumber"
                                            @input="v$.customControlObject.controlNumber.$touch()"
                                            placeholder="Number"
                                            disabled
                                        
                                        />
                                    </div>

                                    <div class="w-3/6 leftspacing">
                                        <label class="controllabel">Frequency</label>

                                        <select class="mt-2" :class="{ 'is-invalid': v$.customControlObject.controlFrequency.$error,'inputboxstylingForSelectAtPopup': customControlObject.controlFrequency === '','stylingForInputboxstylingForSelectAtPopup': customControlObject.controlFrequency !== '' }" v-model="customControlObject.controlFrequency">
                                            <option value="" selected disabled hidden>Select</option>
                                            <option class="optionColors" v-for="(value, index) in controlFrequencyArray" :key="index" :value="value.id">{{ value.label }}</option>
                                        </select>
                                        <div v-if="v$.customControlObject.controlFrequency.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.customControlObject.controlFrequency.required.$invalid">Required</div>
                                        </div>
                                
                                    </div>
                                
                                </div>
                            </div>

                            <div class="formcontainer flex flex-col upperspacing">
                                <div class="row flex flex-row w-full">
                                    <div class="w-6/12">
                                        <div>
                                        <label class="controllabel">Type</label>
                                        <div class="mt-2"> 
                                        <input
                                            type="text"
                                            class="inputboxstyling"
                                            @input="v$.customControlObject.controlType.$touch()"
                                            
                                            v-model.trim="customControlObject.controlType"
                                            placeholder="Type"
                                            :class="{
                                                'is-invalid': v$.customControlObject.controlType.$error
                                            }"
                                        />
                                        <div v-if="v$.customControlObject.controlType.$error" class="text-red-500">
                                            <div v-if="v$.customControlObject.controlType && v$.customControlObject.controlType.restrictCharacters300.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
                                        </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="w-6/12 col-md-6 leftspacing">
                                    <label class="controllabel">Priority</label>
                                    <select class="mt-2" v-model="customControlObject.controlPriority" :class="{'inputboxstylingForSelectAtPopup': customControlObject.controlPriority === '','stylingForInputboxstylingForSelectAtPopup': customControlObject.controlPriority !== ''}">
                                        <option value="" selected disabled hidden>Select</option>
                                        <option class="optionColors" v-for="(value, index) in dbPriority" :key="index" :value="value.value">{{ value.key }}</option>
                                    </select>
                                </div>
                            </div>
                            </div>

                            <div class="formcontainer flex flex-col upperspacing">
                                <div class="row flex flex-row w-full">
                                    <div class="w-6/12">
                                        <label class="controllabel">Family</label>
                                        <multiselect
                                            v-model="customControlObject.controlFamily"
                                            :options="filteredControlFamilyOptions"
                                            :showNoOptions="false"
                                            @search-change="asyncFindControlFamily"
                                            :multiple="false"
                                            :searchable="true"
                                            :hide-selected="true"
                                            open-direction="bottom"
                                            :hideArrow="false"
                                            placeholder="Family"
                                            class="custom-multiselect userSelect mt-2"
                                        >
                                            <template v-slot:noResult>
                                                <span class="text-xs">No Families Found</span>
                                            </template>
                                        </multiselect>
                                    </div>
                                    <div class="w-3/6 leftspacing">
                                        <label class="controllabel">Class</label>
                                        <input
                                            type="text"
                                            class="inputboxstyling mt-2"
                                            @input="v$.customControlObject.controlClass.$touch()"
                                            placeholder="Class"
                                            :class="{
                                                'is-invalid': v$.customControlObject.controlClass.$error
                                            }"
                                            v-model.trim="customControlObject.controlClass"
                                        />
                                        <div v-if="v$.customControlObject.controlClass.$error" class="text-red-500">
                                            <div v-if="v$.customControlObject.controlClass && v$.customControlObject.controlClass.restrictCharacters300.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row flex flex-row w-full upperspacing">
                                <div class="w-full">
                                    <label class="controllabel">Audit Methodology</label>
                                    <textarea
                                        type="text-area"
                                        class="inputboxstyling mt-2"
                                        @input="v$.customControlObject.controlAuditMethodology.$touch()"
                                        placeholder="Audit Methodology"
                                        v-model.trim="customControlObject.controlAuditMethodology"
                                        :class="{
                                            'is-invalid': v$.customControlObject.controlAuditMethodology.$error
                                        }"
                                    >
                                    </textarea>
                                    <div v-if="v$.customControlObject.controlAuditMethodology.$error" class="text-red-500">
                                        <div v-if="v$.customControlObject.controlAuditMethodology && v$.customControlObject.controlAuditMethodology.restrictCharacters300.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
                                    </div>
                                </div>
                            </div>

                            <div class="row flex flex-row w-full upperspacing">
                                <div class="w-full">
                                    <label class="controllabel">Audit Success Criteria</label>
                                    <textarea
                                        type="text-area"
                                        class="inputboxstyling h-24 mt-2"
                                        placeholder="Audit Success Criteria"
                                        v-model.trim="customControlObject.controlAuditSuccessCriteria"
                                        @input="v$.customControlObject.controlAuditSuccessCriteria.$touch()"
                                        :class="{
                                            'is-invalid': v$.customControlObject.controlAuditSuccessCriteria.$error
                                        }"
                                    
                                    >
                                    </textarea>
                                    <div v-if="v$.customControlObject.controlAuditSuccessCriteria.$error" class="text-red-500">
                                        <div v-if="v$.customControlObject.controlAuditSuccessCriteria && v$.customControlObject.controlAuditSuccessCriteria.restrictCharacters300.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
                                    </div>
                                </div>
                            </div>

                            <div class="row flex flex-row w-full upperspacing">
                                <div class="w-full">
                                    <label class="controllabel">Maintenance Task</label>
                                    <textarea
                                        type="text-area"
                                        class="inputboxstyling h-24 mt-2"
                                        placeholder="Maintenance Task"
                                        v-model.trim="customControlObject.controlMaintenanceTask"
                                        @input="v$.customControlObject.controlMaintenanceTask.$touch()"
                                        :class="{
                                            'is-invalid': v$.customControlObject.controlMaintenanceTask.$error
                                        }"
                                    
                                    >
                                    </textarea>
                                    <div v-if="v$.customControlObject.controlMaintenanceTask.$error" class="text-red-500">
                                        <div v-if="v$.customControlObject.controlMaintenanceTask && v$.customControlObject.controlMaintenanceTask.restrictCharacters300.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
                                    </div>
                                </div>
                            </div>

                            <div class="row flex flex-row w-full upperspacing">
                                <div class="w-full">
                                    <label class="controllabel">Policies</label>
                                    <textarea
                                        type="text-area"
                                        class="inputboxstyling h-24 mt-2"
                                        placeholder="Policies"
                                        v-model.trim="customControlObject.controlPolicies"
                                        @input="v$.customControlObject.controlPolicies.$touch()"
                                        :class="{
                                            'is-invalid': v$.customControlObject.controlPolicies.$error
                                        }"
                                    
                                    >
                                    </textarea>
                                    <div v-if="v$.customControlObject.controlPolicies.$error" class="text-red-500">
                                        <div v-if="v$.customControlObject.controlPolicies && v$.customControlObject.controlPolicies.restrictCharacters300.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
                                    </div>
                                </div>
                            </div>

                            <div class="row flex flex-row w-full upperspacing">
                                <div class="w-full">
                                    <label class="controllabel">Guidance</label>
                                    <textarea
                                        type="text-area"
                                        class="inputboxstyling h-24 mt-2"
                                        placeholder="Guidance"
                                        v-model.trim="customControlObject.controlGuidance"
                                        @input="v$.customControlObject.controlGuidance.$touch()"
                                        :class="{
                                            'is-invalid': v$.customControlObject.controlGuidance.$error
                                        }"
                                    
                                    >
                                    </textarea>
                                    <div v-if="v$.customControlObject.controlGuidance.$error" class="text-red-500">
                                        <div v-if="v$.customControlObject.controlGuidance && v$.customControlObject.controlGuidance.restrictCharacters300.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
                                    </div>
                                </div>
                            </div>

                            <div class="w-full col-md-6 upperspacing">
                                <label class="controllabel">Frameworks</label>
                                <multiselect
                                    v-model="customControlObject.controlOptionFrameWork"
                                    @select="getFrameworkControls(customControlObject.controlOptionFrameWork)"
                                    :multiple="false"
                                    :options="frameWorksList"
                                    :searchable="true"
                                    :hide-selected="true"
                                    open-direction="bottom"
                                    :close-on-select="true"
                                    :show-labels="false"
                                    placeholder="Frameworks"
                                    class="custom-multiselect mt-2"
                                >
                                    <template v-slot:noResult>
                                        <span class="text-xs">No Frameworks Found</span>
                                    </template>
                                </multiselect>
                            </div>

                            <div class="w-full col-md-6 upperspacing">
                                <label class="controllabel">Controls</label>
                                <multiselect
                                    v-model="customControlObject.frameworkControls"
                                    :multiple="false"
                                    :options="frameworkControls"
                                    :searchable="true"
                                    :hide-selected="true"
                                    open-direction="bottom"
                                    :close-on-select="true"
                                    :show-labels="false"
                                    placeholder="Controls"
                                    class="custom-multiselect mt-2"
                                    
                                >
                                    <template v-slot:noResult>
                                        <span class="text-xs">No Controls Found</span>
                                    </template>
                                </multiselect>
                            </div>

                        </div>
                    </div>
                </div>
            </fieldset>
        </div>

        <div v-if="!customControlObject._id" class="buttonposition flex justify-center items-center my-4 mx-0">
            <button class="btnprimary mr-1.5" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" :disabled="clickOnButton"  @click="onCancelbtnCustom('cancel')">Cancel</button>
            <button class="btnprimary" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" :disabled="clickOnButton" @click="saveCustomControl(customControlObject)">Save</button>          
        </div>

    </popup>

</template>

<style scoped>
.fullText{
    white-space: break-spaces;
    font-size: 12px;
}
table {
  /* table-layout: fixed; */
  /* width: 100%; */
  background: #FFFFFF;
}
.w-40 {
    width: calc(100% - 170px);
}
.w-10 {
    width: calc(100% - 2.5rem);
}
.nonEmptyTable tr:last-child {
  border-bottom: 1px solid #e9e9e9;
}
.noScrollbar::-webkit-scrollbar {
  display: none;
}
.table-ellipsis {
  display:-webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.tab-container {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  gap: 60px !important; 
}
.midHead {
    width: 160px !important;
}
.widthForSelect{
    width : 112px !important;
}
.redBorder {
	border: 1px solid red !important;
	border-radius: 5px !important;
}
.optionColors{
   color : #4D4D4D !important;
}
.borderRed {
    border: 1px solid red !important;
}
th {
  background-color: #FFFFFF;
  font-family: 'Open Sans' !important;
  cursor: default;
  display: table-cell;
  font-size: 14px !important;
  font-weight: 600;
  height: 60px;
  line-height: 19.5px;
  padding: 0px 15px;
  /* border-bottom: 1px solid #e9e9e9 !important; */
  text-align: left;
  color: black;
 
}
td {
  font-size: 12px;
  font-weight: 400;
  height: 60px !important;
  line-height: 19.5px;
  padding: 0px 15px ;
  text-align: left;
  text-indent: 0px;
  margin: auto;
  box-sizing: border-box;
  font-family: "Open Sans" !important;
  color: black;
  border-bottom:0px;
}
.progress {
  background: #152a43 !important;
  z-index: 999;
  top: 2px;
}
</style>
<script lang="ts">
import _ from 'lodash';
import popup from '@/components/popup.vue';
import useVuelidate from '@vuelidate/core';
import { required, helpers, requiredIf, email } from '@vuelidate/validators';
const numeric = helpers.regex(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i);
import { emitter, toast } from '@/main';
import store from '@/store';
import { mapGetters } from 'vuex';
import { defineComponent } from 'vue';
import breadCrumb from '@/components/breadCrumb.vue';
import toolTip from '@/components/toolTip.vue';
import pagination from '@/components/pagination.vue';
import Multiselect from 'vue-multiselect';
import moment from 'moment';
const restrictCharacters100 = helpers.regex(/^.{0,100}$/);
const restrictCharacters300 = helpers.regex(/^.{0,300}$/);
const restrictCharacters30 = helpers.regex(/^.{0,30}$/);

export default defineComponent({
    data(): any {
        return {
            v$: useVuelidate(),
            selectedTabObj: { displayName: 'Process', name: 'Process', id: 0 },
            carouselHead: [
                { displayName: 'Activated Controls', name: 'activatedControls', id: 0 },
                { displayName: 'All Controls', name: 'allControls', id: 1 },
            ],
            selectedTabIndex: null,
            showFilters: false,
            sideButton: false,
            clickOnButton: false,
           
            tenants: [],
            tenantsSelect: [],
            orgUsersList: [],
            usersList: [],
            optionsUserArray: [],
            presentSystemObj: {},

            allControlHeaders:['controlNumber','controlTitle','controlFamily','controlClass','classificationType','controlPriority'],
            tableProps1:{
                headerNames:[{key:'controlNumber',value:'ID'},{key:'controlTitle',value:'Title'},{key:'controlFamily',value:'Family'},{key:'controlClass',value:'Class'},{key:'classificationType',value:'Type'},{key:'controlPriority',value:'Priority'}],
                
                headerWidth:[{key:'controlNumber',value:'w-1/12'},{key:'controlTitle',value:'w-1/4'},{key:'controlFamily',value:'w-1/5'},{key:'controlClass',value:'w-1/5'},{key:'classificationType',value:'w-1/6'},{key:'controlPriority',value:''}],

                innerHeaderWidth:[{key:'controlNumber',value:'w-20'},{key:'controlTitle',value:'w-36'},{key:'controlFamily',value:'w-32'},{key:'controlClass',value:'w-28'},{key:'classificationType',value:'w-28'},{key:'controlPriority',value:'w-28'}],
            },

            activeControlHeaders:['clientUniqueId','controlTitle','createdAt','controlFrequency','classificationType','controlPriority'],
            tableProps0:{
                headerNames:[{key:'clientUniqueId',value:'CCID'},{key:'controlTitle',value:'Title'},{key:'createdAt',value:'Date of Activation'},{key:'controlFrequency',value:'Frequency'},{key:'classificationType',value:'Type'},{key:'controlPriority',value:'Priority'}],
                
                headerWidth:[{key:'clientUniqueId',value:'w-1/12'},{key:'controlTitle',value:'w-1/3'},{key:'createdAt',value:'w-1/6'},{key:'controlFrequency',value:'w-1/6'},{key:'classificationType',value:'w-1/6'},{key:'controlPriority',value:'w-1/6'}],

                innerHeaderWidth:[{key:'clientUniqueId',value:'w-20'},{key:'controlTitle',value:'w-44'},{key:'createdAt',value:'w-20'},{key:'controlFrequency',value:'w-28'},{key:'classificationType',value:'w-28'},{key:'controlPriority',value:'w-28'}],
            },
            
           
            routesArray: [],

            allControlsList: [],
            allControlsListDisplay:[],
            allControlsListDummy:[],

            activeControlsList: [],
            activeControlsListDisplay:[],
            activeControlsListDummy:[],

            checkedAllControls: [],
            selectAllOptionAllControls: false,

            selectAllOptionActiveControls:false,
            checkedActiveControls:[],
            defaultCheckedActiveControls:[],

            pop: false,
            styling: { left: '', bottom: '' },
            tipText: '',
            tipColumn: '',
            tipIndex: '',
            
            className: '',
            classSort: '',
            currentSort: 'name',
            currentSortDir: 'asc',

            pageSize: 20,
            currentPage: 1,
            dynamicPagination: '',
            allFilters: {
                classificationType:'',
                controlPriority:'',
                controlFrequency:''
            },

            controlTypeValues:[
                {
                    id:1,
                    value:'Mandatory'
                },
                {
                    id:2,
                    value:'Optional'
                },
                {
                    id:3,
                    value:'Custom'
                }
            ],

            controlFrequencyArray: [
				{ label: 'Transactional', id: 1 },
				{ label: 'Daily', id: 2 },
				{ label: 'Weekly', id: 3 },
				{ label: 'Monthly', id: 4 },
				{ label: 'Quarterly', id: 5 },
				{ label: 'Semi Annualy', id: 6 },
				{ label: 'Annualy', id: 7 },
				{ label: 'Ad-Hoc', id: 8 }
			],

          
            visibleTabs: [], // Tabs visible on screen
            currentGroupIndex: 0, // Current group index for small screens
            tabsPerGroup: 3, // Number of tabs per group for small screens
            isSmallScreen: false, // Flag for screen size
            canScrollLeft: false, // Whether tabs can scroll left
            canScrollRight: true, // Whether tabs can scroll right
            totalGroups: 0, // Total number of groups for small screens

            toolTipActive : false,
            toolTipText : '',
            dataLoading:true,

            isSidebarToggle:true,

            customControlObject: {
                controlFrequency:"",
                controlNumber: "",
                controlPriority: "",
                controlTitle: "",
                controlClass: "",
                controlFamily: "",
                controlGuidance: "",
                controlAuditMethodology: "",
                controlAuditSuccessCriteria: "",
                controlMaintenanceTask: "",
                controlPolicies: "",
                controlType: "",
                controlOptionFrameWork: '',
                frameworkControls: '',
            },
            dummyCustomObj:{},
            customPopup:false,
            frameWorksList:[],
            frameJson:{},
            frameworkControls:[],
            filteredControlFamilyOptions:[],
            ControlFamilyArray:[],

            totalOrgControls:[],
            systems:[],
            systemObj:{},
            allActiveControlsForSystem: []
        };
    },
    components: {
        popup,
        Multiselect,
        breadCrumb,
        toolTip,
        pagination
    },
    validations() {
        return {
            customControlObject:{
                controlTitle: { required ,restrictCharacters100},
                controlFrequency: { required },
                controlClass: { restrictCharacters300 },
                // controlFamily: { restrictCharacters30 },
                controlGuidance: { restrictCharacters300 },
                controlAuditMethodology: { restrictCharacters300 },
                controlAuditSuccessCriteria: { restrictCharacters300 },
                controlMaintenanceTask: { restrictCharacters300 },
                controlPolicies: { restrictCharacters300 },
                controlType: { restrictCharacters300 },
            },
        };
    },
    computed: {
        ...mapGetters({
            userInfo: 'userInfo',
            dbPriority:'dbPriority',
        }),

        sortedDataAllControls: function (): any {
            return [...this.allControlsListDisplay]
                .filter((row: any) => {
                    for (const key in this.allFilters) {
                        const inputValue = this.allFilters[key];
                        if (inputValue !== '' && row[key]) {
                            if (row[key] == 'N/A') {
                                return false;
                            } 
                            if (Number.isInteger(row[key])) {
                                if (row[key] !== Number(inputValue)) {
                                    return false;
                                }
                            } else {
                                if (!row[key].toLowerCase().includes(inputValue.toLowerCase())) {
                                    return false;
                                }
                            }
                        }
                    }
                    return true;
                })
                .sort((a, b) => {
                    let modifier = 1;
                    if (this.currentSortDir === 'desc') modifier = -1;
                    if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                    if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                    return 0;
                })
                .filter((row, index) => {
                    this.dynamicPagination = index + 1;
                    let start = (this.currentPage - 1) * this.pageSize;
                    let end = this.currentPage * this.pageSize;
                    if (index >= start && index < end) return true;
                });
        },

        sortedDataActiveControls: function (): any {
            return [...this.activeControlsListDisplay]
                .filter((row: any) => {
                    for (const key in this.allFilters) {
                        const inputValue = this.allFilters[key];
                        if (inputValue !== '' && row[key]) {
                            if (row[key] == 'N/A') {
                                return false;
                            } 
                            if (Number.isInteger(row[key])) {
                                if (row[key] !== Number(inputValue)) {
                                    return false;
                                }
                            } else {
                                if (!row[key].toLowerCase().includes(inputValue.toLowerCase())) {
                                    return false;
                                }
                            }
                        }
                    }
                    return true;
                })
                .sort((a, b) => {
                    let modifier = 1;
                    if (this.currentSortDir === 'desc') modifier = -1;
                    if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                    if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                    return 0;
                })
                .filter((row, index) => {
                    this.dynamicPagination = index + 1;
                    let start = (this.currentPage - 1) * this.pageSize;
                    let end = this.currentPage * this.pageSize;
                    if (index >= start && index < end) return true;
                });
        },

        disableStatus: function (): any {
			let disableStatusCondition;

            if(this.customControlObject._id){
                if (JSON.stringify(this.customControlObject) == JSON.stringify(this.dummyCustomObj)) {
					disableStatusCondition = false;
				} else {
					disableStatusCondition = true;
				}
            }else{
                let val: any = [];
                Object.values(this.customControlObject).forEach((value: any) => {
                    val.push(value);
                });
                for (let n of val) {
                    if (n != '') {
                        disableStatusCondition = true;
                    }
                }
            }

			return disableStatusCondition;
		},

        arraysAreEqual: function (): any {
            const sortedArray1 = [...this.checkedActiveControls].sort();
            const sortedArray2 = [...this.defaultCheckedActiveControls].sort();
            
            if (sortedArray1.length !== sortedArray2.length) {
                return false;
            }

            for (let i = 0; i < sortedArray1.length; i++) {
                if (sortedArray1[i] !== sortedArray2[i]) {
                return false;
                }
            }

            return true;
        }
    },
    methods: {
        getFrameworkControls(name:any){
            this.frameworkControls=[];
            let array=Object.values(this.frameJson)
            array.forEach((x:any)=>{
                x.forEach((y:any)=>{
                if(y.controlFrameWork == name){
                    this.frameworkControls.push(`${y.controlNumber}` + `--` + `${y.controlTitle }`);
                }
                })
            })
		},
        showControlDetailsPopup(data:any){
            this.customPopup = true;
            let framework:any 
            let frameworkControls:any
            if(data.frameworks && data.frameworks != undefined){
                data.frameworks.map((frameworkObj:any) => {
                    framework = frameworkObj
                });
                framework.controls.map((control:any)=>{
                    frameworkControls = control
                })
                data.controlOptionFrameWork = framework.framework != undefined ? framework.framework : ''
                data.frameworkControls = frameworkControls != undefined || frameworkControls != null ? frameworkControls :''
            }
            this.customControlObject = {...data};
            this.dummyCustomObj = {...data}
            this.getFrameworkControls(data.controlOptionFrameWork)
        },
        cancelActiveControls(){
            this.checkedActiveControls = [...this.defaultCheckedActiveControls];
            if(this.checkedActiveControls.length == this.activeControlsList.length){
                this.selectAllOptionActiveControls = true;
            }else{
                this.selectAllOptionActiveControls = false;
            }
        },
        asyncFindControlFamily(query:any) {
            query = query.trim();
            if (query == '') {
                    this.filteredControlFamilyOptions = [];
            } else {
                this.filteredControlFamilyOptions = this.ControlFamilyArray.filter((item:any) => 
                    item?.toLowerCase().includes(query.toLowerCase())
                );
                let isNewOption = !this.ControlFamilyArray.some((item:any) => 
                    item?.toLowerCase() === query.toLowerCase()
                );
                if (isNewOption && query !== '') {
                    this.filteredControlFamilyOptions.push(query);
                }
            }
        },
        customPopupPopup(){
            this.customPopup = true;
            this.customControlObject = {
                controlFrequency:"",
                controlNumber: "",
                controlPriority: "",
                controlTitle: "",
                controlClass: "",
                controlFamily: "",
                controlGuidance: "",
                controlAuditMethodology: "",
                controlAuditSuccessCriteria: "",
                controlMaintenanceTask: "",
                controlPolicies: "",
                controlType: "",
                controlOptionFrameWork: '',
                frameworkControls: '',
            }
        },
        onCancelbtnCustom(prop:any){
            this.v$.$reset();
            
            if (prop === 'close') {
                this.customPopup = false;
                this.dummyCustomObj = {}
			}

            if(this.customControlObject._id){
                this.customControlObject = {...this.dummyCustomObj}
            }else{
                Object.keys(this.customControlObject).forEach((prop) => {
                Array.isArray(this.customControlObject[prop])
                    ? (this.customControlObject[prop] = [])
                    : (this.customControlObject[prop] = "");
                });
            }
            
        },
        onCancelAllControls(){
            this.checkedAllControls = [];
            this.selectAllOptionAllControls = false;
        },
        colorLabelText(value: any) {
            if (value == 1) {
                return ["text-lightgreen font-semibold"];
            } else if (value == 2) {
                return ["text-green font-semibold"];
            } else if (value == 3) {
                return ["text-yellow font-semibold"];
            } else if (value == 4) {
                return ["text-error font-semibold"];
            } else if (value == 5) {
                return ["text-darkred font-semibold"];
            }
        },
        colorLabelBg(value: any) {
            if (value == 1) {
                return ["bg-veryLowColor border-solid border border-lightgreen"];
            } else if (value == 2) {
                return ["bg-lowColor border-solid border border-green"];
            } else if (value == 3) {
                return ["bg-moderateColor border-solid border border-yellow"];
            } else if (value == 4) {
                return ["bg-highColor border-solid border border-error"];
            } else if (value == 5) {
                return ["bg-veryHighColor border-solid border border-darkred"];
            }
        },
        getProirityLabel(val:any){
            if (val === 'N/A') { 
                return 'N/A'; 
            } else {
                let a = this.dbPriority.find((obj: any) => obj.value == val);
                return (val && typeof val === 'number') ? a.key : 'N/A';
            }
        },
        filtersOpen(){
             this.showFilters = !this.showFilters;
                if (this.showFilters == true) {
                    this.allControlsListDummy = this.allControlsListDisplay;
                } else {
                    this.allControlsListDisplay = this.allControlsListDummy;
                    for (const key in this.allFilters) {
                        this.allFilters[key] = '';
                    }
                }
        },
        getHeaderProperties1(prop:any,columnName:any){
            if(prop=='name'){
                let a = this.tableProps1.headerNames.find((obj:any)=>{return obj.key == columnName});
                if(a!= undefined){
                    return a.value
                }else{
                    return columnName
                }

            }else if(prop=='width'){
                let a = this.tableProps1.headerWidth.find((obj:any)=>{return obj.key == columnName});
                if(a!= undefined){
                    return a.value
                }else{
                    return ''
                }
            }else if(prop=='widthInner'){
                let a = this.tableProps1.innerHeaderWidth.find((obj:any)=>{return obj.key == columnName});
                if(a!= undefined){
                    return a.value
                }else{
                    return ''
                }
            }
        },

        getHeaderProperties0(prop:any,columnName:any){
            if(prop=='name'){
                let a = this.tableProps0.headerNames.find((obj:any)=>{return obj.key == columnName});
                if(a!= undefined){
                    return a.value
                }else{
                    return columnName
                }

            }else if(prop=='width'){
                let a = this.tableProps0.headerWidth.find((obj:any)=>{return obj.key == columnName});
                if(a!= undefined){
                    return a.value
                }else{
                    return ''
                }
            }else if(prop=='widthInner'){
                let a = this.tableProps0.innerHeaderWidth.find((obj:any)=>{return obj.key == columnName});
                if(a!= undefined){
                    return a.value
                }else{
                    return ''
                }
            }
        },
        doFalse() {
            emitter.emit('deActiviteToolTip')
        },
        getHeaderAlignment(column:any){
            // {key:'clientUniqueId',value:'CCID'},{key:'controlTitle',value:'Title'},{key:'createdAt',value:'Activated At'},{key:'controlFrequency',value:'Frequency'},{key:'classificationType',value:'Type'},{key:'controlPriority',value:'Priority'}
            if(column == 'clientUniqueId'){
                return 'w-24'
            } else if(column == 'controlTitle'){
                return 'w-24'
            } else if(column == 'createdAt'){
                return 'w-28'
            } else if(column == 'controlFrequency'){
                return 'w-28'
            } else if(column == 'classificationType'){
                return 'w-28'
            } else if(column == 'controlPriority'){
                return 'w-28'
            } else{
                return ''
            }
            

        },
        paginationUpdations(currentPage: any, pageSize: any) {
            this.currentPage = currentPage;
            this.pageSize = pageSize;
        },
        sort: function (s: any) {
            this.className = s;
            if (s === this.currentSort) {
                this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
            }
            this.currentSort = s;
            this.classSort = this.currentSortDir === 'asc' ? 'sort_asc' : 'sort_desc';
        },
        selectAllAllControls(e: any) {
            this.checkedAllControls = [];
            const checked = e.target.checked;
            if (checked) {
                for (let obj of this.allControlsList) {
                    this.checkedAllControls.push(obj._id);
                }
            } else {
                this.checkedAllControls = [];
            }
        },
        selectAllActiveControls(e:any){
            this.checkedActiveControls = [];
            const checked = e.target.checked;
            if (checked) {
                for (let obj of this.activeControlsList) {
                    this.checkedActiveControls.push(obj._id);
                }
            } else {
                this.checkedActiveControls = [];
            }
        },
        changedAllControlInput(){
            if(this.checkedAllControls.length == this.allControlsList.length){
                this.selectAllOptionAllControls = true;
            }else{
                 this.selectAllOptionAllControls = false;
            }
        },
        changedActiveControlInput(){
            if(this.checkedActiveControls.length == this.activeControlsList.length){
                this.selectAllOptionActiveControls = true;
            }else{
                 this.selectAllOptionActiveControls = false;
            }
        },
        toolTip(column: any, e: any, index: any, row: any, status: any) {
            this.toolTipText = row[column];
            this.tipColumn = column;
            this.tipIndex = index;

            // switch (status) {
            //     case 'text':
            this.handleTextTooltip(e);
            //         break;
            // }
        },
        handleTextTooltip(e: any) {
            const container = e.currentTarget.closest('.table-ellipsis');
            if (container && this.isTextOverflowed(container)) {
                let obj:any = {
                    tipText : this.toolTipText
                }

                emitter.emit('activiteToolTip',obj)
            }
        },
        isTextOverflowed(element: any) {
            return element.scrollHeight > element.clientHeight;
        },
        async toggleTabs(tabId: any) {
            this.dataLoading = true
            this.selectedTabIndex = tabId.id;
            sessionStorage.setItem('selectedTab', tabId.id);
            this.selectedTabObj = tabId;

            if (tabId.id == 0) {
                this.currentPage = 1
                this.filtersOpen();
                emitter.emit('visible', false);
                this.showFilters = false;
                await this.getAllInternalControlsForSystem();
                await this.getActiveControlsForSystem();
            } else if (tabId.id == 1) {
                this.currentPage = 1
                this.filtersOpen();
                emitter.emit('visible', false);
                this.showFilters = false;
                await this.getAllInternalControlsForSystem();
                await this.getActiveControlsForSystem();
            }

            this.dataLoading = false
        },
        async getallUsers() {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
                this.usersList = res.data;
                this.orgUsersList = res.data;
                for (var i = 0; i < this.orgUsersList.length; i++) {
                    this.optionsUserArray.push({ label: this.orgUsersList[i].name, value: this.orgUsersList[i]._id });
                }
            });
        },
        async getAllTenants() {
            await this.$http.get(`${process.env.VUE_APP_AUTH_API_URL}/tenants/getAll`).then((res: any) => {
                res.data.map((org: any) => {
                    const obj = { id: org._id, name: org.organizationName };
                    this.tenants.push(obj);
                    const object = { value: org._id, label: org.organizationName };
                    this.tenantsSelect.push(object);
                });
            });
        },
        getControlTypeValue(a:any){
            let val = this.controlTypeValues.find((item:any)=>{ return item.id == a });
            if(val != undefined){
                return val.value
            }else{
                return 'N/A'
            }
        },

        getFrequencyValue(a:any){
            let val = this.controlFrequencyArray.find((item:any)=>{ return item.id == a });
            if(val != undefined){
                return val.label
            }else{
                return 'N/A'
            }
        },

        async getAllInternalControlsForSystem(){
            this.allControlsListDisplay = [];
            this.allControlsListDummy = [];
            this.ControlFamilyArray = []

            let systemId:any = this.$route.params.systemId
            try{
                await this.$http.get(`${process.env.VUE_APP_MONITOR_API_URL}/api/org/system/${systemId}/allcontrols/get`).then((result: { data: any }) => {
                    this.allControlsList = [...result.data];

                    result.data.map((obj:any)=>{
                        let a = {
                            "_id":obj._id,
                            "controlNumber": obj.controlNumber == '' ? 'N/A': obj.controlNumber,
                            'controlTitle': obj.controlTitle == '' ? 'N/A': obj.controlTitle,
                            'controlFamily': obj.controlFamily == '' ? 'N/A': obj.controlFamily,
                            'controlClass': obj.controlClass == '' ? 'N/A': obj.controlClass,
                            'classificationType': obj.classificationType,
                            'controlPriority': (isNaN(obj.controlPriority) || obj.controlPriority === '') ? 'N/A' : obj.controlPriority
                        }

                        if (Object.keys(obj).includes('controlFamily') && (obj.controlFamily != "" || obj.controlFamily != null || obj.controlFamily != undefined) && !this.ControlFamilyArray.includes(obj.controlFamily)) {
                            this.ControlFamilyArray.push(obj.controlFamily);
                        }

                        this.allControlsListDisplay.push({...a});
                        this.allControlsListDummy.push({...a})
                    })


                });
            } catch (e) {
                toast.error('error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }

        },

        async getActiveControlsForSystem(){
            this.activeControlsList = []
            this.activeControlsListDisplay = []
            this.activeControlsListDummy = [];
            this.defaultCheckedActiveControls = [];
            this.checkedActiveControls = []

            let dummy:any = []
            // /api/org/system/:systemId/active/controls/getall
            let systemId:any = this.$route.params.systemId
            try{
                await this.$http.get(`${process.env.VUE_APP_MONITOR_API_URL}/api/org/system/${systemId}/active/controls/getall`).then((result: { data: any }) => {
                    dummy = result.data;
                })
            } catch (e) {
                toast.error('error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }

            dummy.map((obj:any,index:any)=>{
                this.checkedActiveControls.push(obj._id)
                this.defaultCheckedActiveControls.push(obj._id);
                obj.createdAt = this.getDateFormat(obj.createdAt);
                if(obj && typeof obj.controlPriority === 'number'){
                    obj.controlPriority = obj.controlPriority
                } else {
                    obj.controlPriority = "N/A"
                }
                if(obj && (obj.controlFrequency === '' ||obj.controlFrequency === undefined|| obj.controlFrequency === "N/A")){
                    obj.controlFrequency = "N/A"
                } else {
                    obj.controlFrequency = obj.controlFrequency
                }
            })

            this.activeControlsList = [...dummy]
            this.activeControlsListDisplay = [...dummy];
            this.activeControlsListDummy = [...dummy];

            if(this.checkedActiveControls.length == this.activeControlsList.length && this.activeControlsList.length!=0){
                this.selectAllOptionActiveControls = true;
            }else{
                this.selectAllOptionActiveControls = false;
            }
            
        },
        getDateFormat(date: any) {
            if (date == "") {
                return "None";
            } else {
                let now = moment(date);
                return now.format("DD-MM-YYYY");
            }
        },

        async getAllControls(frameName: any) {
            this.totalOrgControls = [];
            let systemId:any = this.$route.params.systemId
			try {
				const result = await this.$http.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/allControls`);
				
				this.totalOrgControls = result.data;

				let arr: any = [];

                if((this.totalOrgControls != null || this.totalOrgControls != undefined) && this.totalOrgControls.length != 0){
                    this.totalOrgControls.forEach((item: any) => {
                        if (item.controlFrameWork && item.controlNumber && Object.keys(item).includes('controlFrameWork') && Object.keys(item).includes('controlNumber') && !Object.keys(item).includes('isInternalControl')) {
                            arr.push(item);
                            this.frameJson = this.groupByFrameworks(arr);
                        }
                    });
                    
                    let displayFrameName = '';	
                    if(this.frameJson != undefined){
                        Object.keys(this.frameJson).forEach((key, index) => {
                            if (frameName === index) {
                                displayFrameName = key;
                            }
                            this.frameWorksList.push(key);
                        });
                    }
                    console.log("oiuyg",this.frameWorksList);
                }
			} catch (e) {
				toast.error(`Something went wrong`, {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
		},
        groupByFrameworks(arr: any[]) {
			return arr.reduce((group: any, product: any) => {
				const { controlFrameWork } = product;
				group[controlFrameWork] = group[controlFrameWork] ?? [];
				group[controlFrameWork].push(product);
				return group;
			}, {});
		},
        
        updateButtonStates() {
            const tabContainer = this.$refs.tabContainer;
            if (tabContainer) {
                if (!this.isSmallScreen) {
                    this.canScrollLeft = tabContainer.scrollLeft > 0;
                    this.canScrollRight = tabContainer.scrollLeft + tabContainer.clientWidth < tabContainer.scrollWidth;
                } else {
                    this.canScrollLeft = this.currentGroupIndex > 0;
                    this.canScrollRight = this.currentGroupIndex < this.totalGroups - 1;
                }
            }
        },
        handleResize() {
            this.isSmallScreen = window.innerWidth <= 768;
            if (this.isSmallScreen) {
                this.calculateTabsPerGroup();
                if (this.currentGroupIndex > this.totalGroups - 1) {
                    this.scrollTabs('left');
                }
            } else {
                this.tabsPerGroup = this.calculateTabsForLargeScreen();
                this.updateVisibleTabs();
            }
            this.updateButtonStates();
        },
        calculateTabsPerGroup() {
            const tabContainerWidth = this.$refs.tabContainer.clientWidth;

            let sumWidth = 0;
            let count = 0;
            const tabs = this.carouselHead;

            for (let i = 0; i < tabs.length; i++) {
                const tabWidth = this.getTabWidth(tabs[i]);

                if (sumWidth + tabWidth > tabContainerWidth) {
                    break;
                }

                sumWidth += tabWidth;
                count++;
            }
            this.tabsPerGroup = count;
            this.updateVisibleTabs();
        },
        calculateTabsForLargeScreen() {
            const tabContainerWidth = this.$refs.tabContainer.clientWidth;

            const tabs = this.carouselHead;
            let sumWidth = 0;
            let count = 0;

            for (let i = 0; i < tabs.length; i++) {
                const tabWidth = this.getTabWidth(tabs[i]);

                if (sumWidth + tabWidth > tabContainerWidth) {
                    break;
                }

                sumWidth += tabWidth;
                count++;
            }
            return count;
        },
        getTabWidth(tab: any) {
            // Simulate tab width calculation; replace with your actual logic
            const dummyDiv = document.createElement('div');
            dummyDiv.innerHTML = tab.displayName;
            dummyDiv.style.display = 'inline-block';
            dummyDiv.style.visibility = 'hidden';
            document.body.appendChild(dummyDiv);
            const width = dummyDiv.clientWidth;
            document.body.removeChild(dummyDiv);
            return width + 24; // Adjust padding or margin as needed
        },
        async updateVisibleTabs() {
            if (!this.isSmallScreen) {
                console.log("isSmallScreen")
                this.visibleTabs = [...this.carouselHead];
            } else {
                console.log("startIdx")
                const startIdx = this.currentGroupIndex * this.tabsPerGroup;
                const endIdx = startIdx + this.tabsPerGroup;
                this.visibleTabs = this.carouselHead.slice(startIdx, endIdx);
                if (this.visibleTabs) {
                    console.log("visibleTabs", this.visibleTabs)
                    let savedTabKey: any = sessionStorage.getItem('selectedTab');
                    savedTabKey = parseInt(savedTabKey)
                    if (savedTabKey) {
                        const savedTab = this.visibleTabs.find((tab: any) => tab.id == savedTabKey);
                        if (savedTab) {
                            await this.toggleTabs(savedTab);
                        }else{
                            this.selectedTabObj = { displayName: 'Process', name: 'Process', id: 0 },
                            this.selectedIndex = 0;
                        }
                    }
                    else{
                        this.selectedTabObj = { displayName: 'Process', name: 'Process', id: 0 },
                        this.selectedIndex = 0;
                        await this.toggleTabs(this.selectedTabObj);
                    }
                }
                this.totalGroups = Math.ceil(this.carouselHead.length / this.tabsPerGroup);
            }
            this.updateButtonStates();
        },
        scrollTabs(direction: any) {
            const tabContainer = this.$refs.tabContainer;
            if (tabContainer) {
                if (!this.isSmallScreen) {
                    const scrollAmount = this.calculateScrollAmount(direction);
                    if (direction === 'left') {
                        tabContainer.scrollLeft -= scrollAmount;
                    } else if (direction === 'right') {
                        tabContainer.scrollLeft += scrollAmount;
                    }
                    this.updateButtonStates();
                } else {
                    if (direction === 'left' && this.currentGroupIndex > 0) {
                        this.currentGroupIndex--;
                    } else if (direction === 'right' && this.currentGroupIndex < this.totalGroups - 1) {
                        this.currentGroupIndex++;
                    }
                    this.updateVisibleTabs();
                    this.updateButtonStates(); // Update button states after changing currentGroupIndex
                }
            }
        },
        calculateScrollAmount(direction: any) {
            const tabContainer = this.$refs.tabContainer;
            const visibleTabs = this.visibleTabs;
            let sumWidth = 0;
            let scrollAmount = 0;

            if (direction === 'left') {
                for (let i = visibleTabs.length - 1; i >= 0; i--) {
                    const tabWidth = this.getTabWidth(visibleTabs[i]);
                    if (sumWidth + tabWidth > tabContainer.clientWidth) {
                        break;
                    }
                    sumWidth += tabWidth;
                    scrollAmount += tabWidth;
                }
            } else if (direction === 'right') {
                for (let i = 0; i < visibleTabs.length; i++) {
                    const tabWidth = this.getTabWidth(visibleTabs[i]);
                    if (sumWidth + tabWidth > tabContainer.clientWidth) {
                        break;
                    }
                    sumWidth += tabWidth;
                    scrollAmount += tabWidth;
                }
            }
            return scrollAmount;
        },
        scrollGroups(direction: any) {
            if (direction === 'left' && this.currentGroupIndex > 0) {
                this.currentGroupIndex--;
            } else if (direction === 'right' && this.currentGroupIndex < this.totalGroups - 1) {
                this.currentGroupIndex++;
            }
            this.updateVisibleTabs();
            this.updateButtonStates(); // Update button states after changing currentGroupIndex
        },
        handleScroll() {
            this.updateButtonStates();
        },


        async mapTheSelectedControlsToSystem(type:any){
            //  route:/org/api/system/:systemId/:type/contolsmapping

            // type:10301--add
            // 10303--delete

            // add payload:
            // 	{
            //    	 "mappedControls":["673320cbe4f8dc9530c07411"]
            // 	}
            // id should be InternalControls--id

            // delete payload:

            // {
            //     "mappedControls":["673320cbe4f8dc9530c07411"]
            // }-- id should be the document id in org-mapped-controls-List

            let payload:any ={
                "mappedControls":[]
            }

            if(type == 10301){
                payload.mappedControls = this.checkedAllControls
            }else if(type == 10303){
                let deselectedControls:any = this.defaultCheckedActiveControls.filter((id:any) => !this.checkedActiveControls.includes(id));
                payload.mappedControls = deselectedControls
            }
            let systemId:any = this.$route.params.systemId

            try {
				await this.$http.post(`${process.env.VUE_APP_MONITOR_API_URL}/org/api/system/${systemId}/${type}/contolsmapping`,payload).then(async (res: { data: any }) => {
                    toast.info('Saved', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    })

                    this.checkedAllControls = [];
                    this.checkedActiveControls = [];
                    this.defaultCheckedActiveControls = [];
                    await this.getActiveControlsForSystem();
                    await this.getAllInternalControlsForSystem();
                });
			} catch (e) {
				toast.error('error', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
            this.selectAllOptionAllControls = false;
        },

        

        async saveCustomControl(obj:any){
            this.v$.$touch()
            if(!this.v$.customControlObject.$invalid){
                let a = {...this.customControlObject}

                a.frameworks = [
                {
                    "framework":a.controlOptionFrameWork,
                    "controls":[a.frameworkControls]
                }
                ]

                a.isInternalControl = true

                delete a.controlOptionFrameWork
                delete a.frameworkControls


                let payload = [
                    {
                        "controlFrameWork":"InternalControls",
                        "controlsList":[a]
                    }
                ];
                // /api/org/system/:systemId/controlset/create
                // ${this.$route.params.systemId}
                try{
                    await this.$http.post(`${process.env.VUE_APP_MONITOR_API_URL}/api/org/system/${this.$route.params.systemId}/controlset/create`,payload).then(async (res: { data: any }) => {
                        let insertedIds:any = []

                        insertedIds.push(res.data.insertedIds[0]);
                        // /api/org/controls/:systemId/manageSystem

                        // payload:

                        // [{
                        // "systemId":"675bb5d5e186473ba829e54a",
                        // "controlIds":["676254b33927c1186835a5fa"]

                        // }]
                        if(insertedIds.length > 0){
                            let newPayload = [{
                                "systemId": this.$route.params.systemId,
                                "controlIds":insertedIds
                            }];
                            await this.$http.post(`${process.env.VUE_APP_MONITOR_API_URL}/api/org/controls/${this.$route.params.systemId}/manageSystem`,newPayload).then(async (res: { data: any }) => {
                                toast.info('Saved', {
                                    timeout: 1000,
                                    closeOnClick: true,
                                    closeButton: 'button',
                                    icon: true
                                });
                                this.onCancelbtnCustom('close')
                                await this.getAllInternalControlsForSystem();
                                await this.getActiveControlsForSystem();
                            })
                        } else {
                            this.onCancelbtnCustom('close')
                        }
                    });
                }catch(e){
                    this.onCancelbtnCustom('close')
                    toast.error('Entered control Number already exsist', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                }

            }
        },
        async getAllSystems() {
            let entityId='20005'
            let systems:any
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/${entityId}`).then((res: any) => {
                this.systems = res.data;
            });
            let systemId:any = this.$route.params.systemId
            let data:any = this.systems.find((data:any)=>{
			    return data._id == systemId
            })
            this.systemObj = {...data}
        },
    },
    async mounted() {
        this.visibleTabs = [...this.carouselHead];
        
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
        this.updateButtonStates(); 

        emitter.off('activiteToolTip');
        emitter.on('activiteToolTip', (data: any) => {
            this.toolTipActive = true;
            this.toolTipText = data.tipText
        });
 
        emitter.off('deActiviteToolTip');
        emitter.on('deActiviteToolTip', (data: any) => {
            this.toolTipActive = false;
            this.toolTipText = ''
        });

        emitter.on('toggle-sidebar', (isToggle: any) => {
			this.isSidebarToggle = isToggle;
		});
    },
    async created() {
        this.dataLoading = true;

        await this.getallUsers();
        await this.getAllTenants();
        await this.getAllControls()
        await this.getAllSystems();
        
        let savedTabKey: any = sessionStorage.getItem('selectedTab');
        
        savedTabKey = parseInt(savedTabKey)
		if (savedTabKey) {
		    const savedTab = this.carouselHead.find((tab: any) => tab.id == savedTabKey);
            
            if (savedTab) {
                await this.toggleTabs(savedTab);
            }else{
                this.selectedTabObj = { displayName: 'Process', name: 'Process', id: 0 },
                this.selectedIndex = 0;
            }
		}
		else{
			this.selectedTabObj = { displayName: 'Process', name: 'Process', id: 0 },
			this.selectedIndex = 0;
            await this.toggleTabs(this.selectedTabObj);
		}

        this.dataLoading = false;
        
         this.routesArray = [
            { name: this.systemObj.systemName, routeName: "systemLevel" },
            { name: 'Control Mapping', routeName: "" },
        ];
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
        sessionStorage.removeItem('selectedTab');
    }
});
</script>
