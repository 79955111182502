<template>
	<div class="pt-3 pl-5 pr-5">
		<div class="row w-full justify-between flex" style="align-items: center">
			<h1 class="title">Frameworks</h1>
			<div class="flex items-center">
				<input type="file" class="hidden" accept=".xls,.xlsx" ref="file" @change="fileReader($event)" />
				<div class="flex flex-row-reverse">
					<button class="flex" :class="!columnObject ? 'filterButton' : 'filterButtondisabled pointer-events-none'" @click="filterOpen">
						Filters
						<img src="@/assets/filter.svg" class="h-3 ml-2.5" />
					</button>
					<button v-if="selectedFrameName.displayName != 'Internal Controls'" class="btnprimary mr-3" @click="importDoc()">Import</button>
				</div>
			</div>
		</div>
		<div class="text-xs mt-3">
			Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
			velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
		</div>
		<div class="mt-5 px-5 pb-5 card w-full">
			<div class="">
				<div ref="carouselContainer" class="carousel-container">
					<div
						class="mt-5 flex flex-row borderBottom overflow-hidden justify-between cursor-pointer"
						:class="tabHead.length > 6 ? 'w-full' : '' || tabHead.length == 5 ? 'w-full' : '' || tabHead.length == 4 ? 'w-full' : '' || tabHead.length <= 3 ? 'w-full' : ''"
					>
						<div class="pb-1 adjust" :class="selectedFrame == tab.value ? 'adjust header font-semibold':'text-sm text-disabledgrey'" v-for="(tab, tabIndex) in tabHead" :key="tabIndex" @click="toggleTabs(tab.value)">
							<div>
								<div ref="actualIndex">{{ tab.displayName }}</div>
								<div :class="{ progress: selectedFrame == tab.value }" class="h-1 p-0.75"></div>
							</div>
						</div>
					</div>
				</div>
				<div class="progress1 h-1"></div>
			</div>

			<div class="button-adjust flex justify-between w-full" v-if="carouselHead.length > pageItems">
				<div class="marginprev" @click="leftButton">
					<img src="@/assets/leftArrowFilled.svg" class="w-4 h-4" :class="cantGoBack ? 'disabled pointer-events-none' : 'mr-2.5'" @click="prevPage" />
				</div>
				<div class="marginnext" @click="rightButton">
					<img src="@/assets/rightArrowFilled.svg" class="w-4 h-4" :class="cantGoForward ? 'disabled pointer-events-none' : 'ml-2.5'" @click="nextPage" />
				</div>
			</div>
			<div>
				<tableData :tableRowData="tableRow"  :headersData="headers" :tableRouteArr="optionsValue" :loadData="dataLoading"></tableData>
			</div>
			<popup v-if="popUpFrameWork" :popUp="popUpFrameWork">
				<template v-slot:header>
					<div class="flex items-center justify-between py-4 px-2 rounded">
						<!-- <h1 class="pl-2">{{ frameworkObj.controlNumber }}</h1> -->
						<h1 class="pl-2">{{ frameworkObj.controlFrameWork || 'Internal Controls'}}</h1>
						<button class="float-right text-3xl" @click="onCancelbtn()">
							<img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
						</button>
					</div>
				</template>
				<fieldset>
					<div>
						<div>
							<div class="statuscontainer popupbodycontainer">
								<div class="formcontainer flex flex-col">
									<div class="row flex flex-row w-full upperspacing" v-if="!Object.keys(frameworkObj).includes(isInternalControl) && frameworkObj.isInternalControl == true">
										<div class="w-full">
											<label class="controllabel">Framework</label>
											<div v-if="frameworkObj.controlFrameWork != undefined || frameworkObj.controlFrameWork != null">{{ frameworkObj.controlFrameWork }}</div>
											<div v-else class="mb-3 mt-2 text-sm">{{ 'N/A' }}</div>
											<!-- <input type="text" class="inputboxstyling" v-model="frameworkObj.controlFrameWork" disabled placeholder="Framework" /> -->
										</div>
									</div>
									<div class="row flex flex-row w-full upperspacing">
										<div class="w-6/12">
											<label class="controllabel">Number</label>
											<div class="text-sm mt-2" v-if="frameworkObj.controlNumber != undefined || frameworkObj.controlNumber != null">{{ frameworkObj.controlNumber }}</div>
											<div v-else class="mb-3 mt-2 text-sm">{{ 'N/A' }}</div>
											<!-- <input type="text" class="inputboxstyling" v-model="frameworkObj.controlNumber" placeholder="Number" disabled /> -->
										</div>
										<div class="w-3/6 leftspacing">
											<label class="controllabel">Title</label>
											<div class="text-sm mt-2" v-if="frameworkObj.controlTitle != undefined || frameworkObj.controlTitle != null">{{ frameworkObj.controlTitle }}</div>
											<div v-else class="mb-3 mt-2 text-sm">{{ 'N/A' }}</div>
											<!-- <input type="text" class="inputboxstyling" placeholder="Title" v-model="frameworkObj.controlTitle" disabled /> -->
										</div>
									</div>
									<div class="row flex flex-row w-full upperspacing">
										<div class="w-6/12">
											<label class="controllabel">Type</label>
											<!-- <div class="text-sm mt-2" v-if="frameworkObj.controlType != undefined || frameworkObj.controlType != null">{{ frameworkObj.controlType }}</div>
											<div v-else class="mb-3 mt-2 text-sm">{{ 'N/A' }}</div> -->
											<div class="mb-3 mt-2 text-sm">{{ frameworkObj?.controlType || 'N/A' }}</div>
											<!-- <input type="text" class="inputboxstyling" placeholder="Type" v-model="frameworkObj.controlType" disabled /> -->
											<!-- <div v-for="(dataValue, indexNum) in controlTypeArray" :key="indexNum">
												<div v-if="frameworkObj.controlType == dataValue.id">
													<label class="controllabel">Type</label>
													<input type="text" class="inputboxstyling" disabled v-model="dataValue.label" placeholder="Priority" />
												</div>
											</div> -->
										</div>
										<div class="w-3/6 leftspacing" >
										<div v-if="checkType()">
											<div>
												<label class="text-xs font-extrabold">Priority</label>
												<div class="mb-3 mt-2 text-sm">N/A</div>
											</div>
										</div>
											<div v-else v-for="(dataValue, indexNum) in priorityArray" :key="indexNum">
												<div v-if="frameworkObj.controlPriority == dataValue.id">
													<label class="text-xs font-extrabold">Priority</label>
													<div class="mb-3 mt-2 text-sm">{{ dataValue != undefined ? dataValue.label : 'N/A' }}</div>
												</div>
											</div>
										</div>
									</div>
									<div class="row flex flex-row w-full upperspacing">
										<div class="w-6/12">
											<label class="controllabel">Family</label>
											<div class="text-sm mt-2" v-if="frameworkObj.controlFamily != undefined || frameworkObj.controlFamily != null">{{ frameworkObj.controlFamily }}</div>
											<div v-else class="mb-3 mt-2 text-sm">{{ 'N/A' }}</div>
											<!-- <input type="text" class="inputboxstyling" placeholder="Family" v-model="frameworkObj.controlFamily" disabled /> -->
										</div>
										<div class="w-3/6 leftspacing">
											<label class="controllabel">Class</label>
											<div class="text-sm mt-2" v-if="frameworkObj.controlClass != undefined || frameworkObj.controlClass != null">{{ frameworkObj.controlClass }}</div>
											<div v-else class="mb-3 mt-2 text-sm">{{ 'N/A' }}</div>
											<!-- <input type="text" class="inputboxstyling" placeholder="Class" v-model="frameworkObj.controlClass" disabled /> -->
										</div>
									</div>
									<div class="row flex flex-row w-full upperspacing">
										<div class="w-full">
											<label class="controllabel">Audit Methodology</label>
											<div class="text-sm mt-2" v-if="frameworkObj.controlAuditMethodology != undefined || frameworkObj.controlAuditMethodology != null">{{ frameworkObj.controlAuditMethodology }}</div>
											<div v-else class="mb-3 mt-2 text-sm">{{ 'N/A' }}</div>
											<!-- <textarea type="text-area" class="inputboxstyling h-24" placeholder="Audit Methodology" v-model="frameworkObj.controlAuditMethodology" disabled> </textarea> -->
										</div>
									</div>
									<div class="row flex flex-row w-full upperspacing">
										<div class="w-full">
											<label class="controllabel">Audit Success Criteria</label>
											<div class="text-sm mt-2" v-if="frameworkObj.controlGuidance != null || frameworkObj.controlGuidance != undefined">{{ frameworkObj.controlGuidance }}</div>
											<div v-else class="mb-3 mt-2 text-sm">{{ 'N/A' }}</div>
											<!-- <textarea type="text-area" class="inputboxstyling h-24" placeholder="Audit Success Criteria" v-model="frameworkObj.controlGuidance" disabled> </textarea> -->
										</div>
									</div>
									<div class="row flex flex-row w-full upperspacing">
										<div class="w-full">
											<label class="controllabel">Maintenance Task</label>
											<div class="text-sm mt-2" v-if="frameworkObj.controlMaintenanceTask != null || frameworkObj.controlMaintenanceTask != undefined">{{ frameworkObj.controlMaintenanceTask }}</div>
											<div v-else class="mb-3 mt-2 text-sm">{{ 'N/A' }}</div>
											<!-- <textarea type="text-area" class="inputboxstyling h-24" placeholder="Maintenance Task" v-model="frameworkObj.controlMaintenanceTask" disabled> </textarea> -->
										</div>
									</div>
									<div class="row flex flex-row w-full upperspacing">
										<div class="w-full">
											<label class="controllabel">Policies</label>
											<div class="text-sm mt-2" v-if="frameworkObj.controlPolicies != undefined || frameworkObj.controlPolicies != null">{{ frameworkObj.controlPolicies }}</div>
											<div v-else class="mb-3 mt-2 text-sm">{{ 'N/A' }}</div>
											<!-- <textarea type="text-area" class="inputboxstyling h-24" placeholder="Policies" v-model="frameworkObj.controlPolicies" disabled> </textarea> -->
										</div>
									</div>
								</div>
								<div class="buttonposition flex justify-center items-center my-4 mx-0" v-if="deleteAccess === true">
									<button class="btnprimary" @click="deleteCustomControl(frameworkObj)">Delete</button>
								</div>
							</div>
						</div>
					</div>
				</fieldset>
			</popup>
		</div>
		<!-- <loader v-if="showLoader" /> -->
	</div>
</template>
<style scoped>
.multiselect-wrapper {
	min-height: 20px !important;
}
.multiselect-tags-search-wrapper {
	margin: 0px !important;
}
.anime-left {
	animation-name: moveLeft;
	animation-duration: 1s;
	animation-iteration-count: 1;
	animation-fill-mode: backwards;
}
@keyframes moveLeft {
	0% {
		opacity: 1;
		transform: translateX(100vw);
	}
	100% {
		transform: translateX(0);
	}
}
.anime-right {
	animation-name: moveRight;
	animation-duration: 1s;
	animation-iteration-count: 1;
	animation-fill-mode: backwards;
}
@keyframes moveRight {
	0% {
		opacity: 1;
		transform: translateX(-100vw);
	}
	100% {
		transform: translateX(0);
	}
}
.progress1 {
	border: 2px solid #e9e9e9;
	border-radius: 16px;
	margin-right: 0.5px;
	background: #e9e9e9;
	position: relative;
	top: -8px;
	/* margin-top: 3px; */
}
.progress {
	border-radius:16px;
	background: #152a43;
}
.setBox {
	width: initial;
}
.marginprev {
	margin-left: -16px;
}
.marginnext {
	margin-right: -16px;
}
.box {
	border: 2px solid red;
}
.adjust {
	flex-shrink: 0;
}
.button-adjust {
	position: relative;
	top: -30px;
	z-index: 1;
	cursor: pointer;
}
.borderBottom {
	position: relative;
	z-index: 2;
	cursor: pointer;
}
.z-index {
	z-index: 100;
}
::-webkit-scrollbar {
	width: 3px !important;
}
/* .accept{
    width:100px
} */
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import useVuelidate from '@vuelidate/core';
import { emitter, toast } from '../../main';
import * as XLSX from 'xlsx';
import tableData from '@/components/tableData.vue';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import popup from '@/components/popup.vue';
// import loader from '@/components/loader.vue';

export default defineComponent({
	data(): any {
		return {
			headers:['_id','number','title','family','class','priority', 'actionButtonForKPIs'],
			v$: useVuelidate(),
			objToBeModified: {},
			dummyObj: {},
			showFilters: false,
			dum: [],
			id: [],
			carouselHead: [],
			index: 0,
			selectedFrame: 0,
			selectedFrameName: '',
			selectedHead: 0,
			popUpFrameWork: false,
			deleteAccess: false,
			columnObject: false,
			styling: { left: '', bottom: '' },
			pageItems: 6,
			fullKeys: ['title', 'number', 'class', 'priority'],
			mandatoryKeys: ['controlNumber', 'controlTitle', 'controlGuidance', 'controlClass', 'controlFamily', 'controlType', 'controlPriority', 'controlFrameWork'],
			currentPage: 1,
			currentItems: 1,
			dynamicPagination: '',
			pop: false,
			currentSort: 'name',
			currentSortDir: 'asc',
			classSort: '',
			showBlockindex: '',
			showLoader: false,
			showBlock: false,
			showDropdown: false,
			className: '',
			filters: {},
			rowData: [],
			dataLoading:true,
			controlTypeArray: [
				{ label: 'Preventive', id: 1 },
				{ label: 'Detective', id: 2 },
				{ label: 'Corrective', id: 3 },
				{ label: 'Deterrent', id: 4 },
				{ label: 'Directive', id: 5 },
				{ label: 'Recovery', id: 6 },
				{ label: 'Compensating', id: 7 }
			],
			optionsValue: [
				{
					id: 1,
					value: 'KPI',
					displayIcon: 'mapIcon',
					presentRoute: '/root-Frameworks'
				}
			],
			priorityArray: [
				{ label: 'Very Low', id: 1 },
				{ label: 'Low', id: 2 },
				{ label: 'Moderate', id: 3 },
				{ label: 'High', id: 4 },
				{ label: 'Critical', id: 5 }
			],
			dummyList: [],
			data: {},
			tableRow: [],
			frameworkObj: {
				controlFrameWork: '',
				controlMapFrameWork: '',
				controlNumber: '',
				controlPriority: '',
				controlTitle: '',
				controlClass: '',
				controlFamily: '',
				controlObjective: '',
				controlGuidance: '',
				controlAuditMethodology: '',
				controlAuditSuccessCriteria: '',
				controlMaintenanceTask: '',
				controlPolicies: '',
				controlType: ''
			},
			type: [
				{
					id: 1,
					value: 'Primary'
				},
				{
					id: 2,
					value: 'Secondary'
				}
			]
		};
	},
    async created(){
		this.dataLoading = true;
		await this.getAllControls();
		const savedTabKey = sessionStorage.getItem('selectedTab');
		if (savedTabKey) {
			// console.log("savedTabKey",savedTabKey)
			// console.log("savedtabHead",this.tabHead)
            const savedTab = this.tabHead.find((tab: any) => tab.value == savedTabKey);
			// console.log("savedTab",savedTab)
            if (savedTab) {
				this.toggleTabs(savedTab.value);
			}
			else{
				await this.toggleTabs(0);
			}
		}
		else{
			await this.toggleTabs(0);
		}
	},
	beforeUnmount() {
		sessionStorage.removeItem('selectedTab')
	},
	async mounted() {
		emitter.on('rootFrameworksPopup', (isData: any) => {
			let comingId = isData.entityData._id;
			let showObj = this.rowData.find((obj: any) => obj._id === comingId);
			this.deleteAccess = isData.action ? true : false;
			this.showEditPopUp(showObj);
		});
		// await this.toggleTabs(0);
		window.addEventListener('resize', this.updateCarouselClass);
		this.updateCarouselClass();
		emitter.on('*', (type, booleanValue) => {
			if (type == 'apps-sidemenubar' || type == 'toggle-sidebar') {
				this.showBlock == true ? (this.showBlock = false) : '';
			} else {
				if (this.showDropdown === true) {
					this.showBlock == true ? (this.showBlock = booleanValue) : '';
				} else if (this.showDropdown === false) {
					this.showBlock == true ? '' : (this.showBlock = booleanValue);
					this.showDropdown = true;
				}
			}
		});
		// this.dataLoading = false;
	},
	components: {
		tableData,
		popup,
		// loader
	},
	computed: {
		tabHead(): any {
			return this.carouselHead.filter((row: any, index: any) => {
				let start = (this.currentItems - 1) * this.pageItems;
				this.index = 0;
				let end = this.currentItems * this.pageItems;

				if (index >= start && index < end) return true;
			});
		},
		...mapGetters({ userInfo: 'userInfo' }),
		cantGoBack(): any {
			return this.currentItems === 1;
		},
		cantGoForward(): any {
			return this.carouselHead.length / this.pageItems <= this.currentItems;
		}
	},
	methods: {
		checkType(){
			if(this.frameworkObj.controlPriority == undefined || this.frameworkObj.controlPriority == null || typeof this.frameworkObj.controlPriority === "string"){
               return true;
			}else{
				return false;
			}
		},
		async nextPage() {
			if ((this.currentItems * this.pageItems) < this.carouselHead.length) {
				this.currentItems = this.currentItems + 1;
				this.selectedFrame = this.tabHead[0].value || null;
				console.log("selectedTab",this.tabHead);
				if (this.selectedFrame) {
					await this.getAllControls(this.selectedFrame);
				}
				this.updateCarouselClass();
			}
       },
		async prevPage() {
			if (this.currentItems >= 1) {
				const savedTabKey = sessionStorage.getItem('selectedTab');
				this.currentItems = this.currentItems - 1;
				let selectedTab:any = this.tabHead.find((data:any) => {
					return data.value == savedTabKey
				});
				console.log("selectedTab",this.tabHead);
				if (selectedTab!=undefined) {
					this.selectedFrame = selectedTab.value ;
				}else{
					this.selectedFrame = 0
				}
				// console.log("23456",this.carouselHead);
				if (this.selectedFrame) {
					await this.getAllControls(this.selectedFrame);
				}
				//  else{
				// 	this.toggleTabs(0);
				//  }
				this.updateCarouselClass(); 
			}
        },
		updateCarouselClass() {
			const x = window.innerWidth;
			let className = '';

			if (x > 1280) {
				className = 'page-items-7';
				this.pageItems = 7;
			} else if (x >= 1024 && x <= 1280) {
				className = 'page-items-6';
				this.pageItems = 6;
			} else if (x >= 899 && x < 1024) {
				className = 'page-items-5';
				this.pageItems = 5;
			} else if (x >= 640 && x < 899) {
				className = 'page-items-4';
				this.pageItems = 4;
			} else {
				className = 'page-items-1';
				this.pageItems = 1;
			}

			const carouselContainer = this.$refs.carouselContainer;
			if (carouselContainer) {
				carouselContainer.className = '';
				carouselContainer.classList.add(className);
			}

			// Adjust currentItems if it exceeds the number of pages
			const totalItems = this.carouselHead.length;
			const totalPages = Math.ceil(totalItems / this.pageItems);
			if (this.currentItems > totalPages) {
				this.currentItems = totalPages;
			}
			if (this.currentItems < 1) {
				this.currentItems = 1;
			}
		},
		importDoc() {
			this.$refs.file.click();
		},
		fileReader(oEvent: any): any {
			var oFile = oEvent.target.files[0];
			var reader = new FileReader();
			reader.onload = async (e: any) => {
				let data: any = e.target.result;
				data = new Uint8Array(data);
				var workbook = XLSX.read(data, { type: 'array' });
				var first_sheet_name = workbook.SheetNames[0];
				var worksheet = workbook.Sheets[first_sheet_name];
				var jsonObj = XLSX.utils.sheet_to_json(worksheet, {
					raw: true,
					header: this.mandatoryKeys,
					range: 1
				});

				let total = jsonObj.length;
				let valid = 0;
				const jsonArray: any = [];
				let arr: any[] = [];
				console.log("jsonObj", jsonObj)
				jsonObj.forEach((item: any) => {
					let arr = ['controlNumber', 'controlFrameWork'];
					let PriorityObj = this.priorityArray.find((obj: any) => {
						return obj.label == item.controlPriority;
					});
					item.controlPriority = PriorityObj.id;

					// let TypeObj = this.controlTypeArray.find((obj: any) => {
					// 	return obj.label == item.controlType;
					// });
					// item.controlType = TypeObj.id;

					if (
						arr.every((key: any) => {
							return Object.keys(item).includes(key) && item[key];
						})
					) {
						item.controlFrameWork = item.controlFrameWork ? item.controlFrameWork : '';
						arr.push(item);
						jsonArray[item.controlFrameWork] = jsonArray[item.controlFrameWork] || [];
						jsonArray[item.controlFrameWork].push(item);
					}
				});
				console.log("jsonArray", jsonArray)
				const controlSets: any = Object.keys(jsonArray).map((key, index) => {
					this.carouselHead.push({ value: index, displayName: key });
					return {
						controlFrameWork: key,
						controlsList: jsonArray[key]
					};
				});
				console.log("controlSets", controlSets)

				valid = controlSets.reduce((count: any, set: any) => count + set.controlsList.length, 0);

				// this.addControl = true;

				await this.addControlSource(controlSets);
				if (controlSets.length > 0) {
					toast.info(`Imported ${total} rows, Valid rows: ${valid}, Ignored rows: ${total - valid}`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				} else {
					toast.error(`Something went wrong`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				}
			};
			reader.readAsArrayBuffer(oFile);
		},
		showEditPopUp(framework: any) {
			this.popUpFrameWork = true;
			this.dummyObj = { ...framework };
			this.objToBeModified = framework;
			this.frameworkObj = { ...framework };
		},
		async toggleTabs(frame: any) {
			this.dataLoading = true;
			this.defaultFrame = frame;
			this.selectedFrame = frame;
			this.tableRow = [];
			await this.getAllControls(frame);
			this.dataLoading = false;
			sessionStorage.setItem('selectedTab', frame);
		},
		filterOpen() {
			if (this.columnObject == false) {
				this.showFilters = !this.showFilters;
				emitter.emit('visible', this.showFilters);
			}
		},
		groupBy(arr: any[]) {
			return arr.reduce((group: any, product: any) => {
				const { controlFrameWork } = product;
				group[controlFrameWork] = group[controlFrameWork] ?? [];
				group[controlFrameWork].push(product);
				return group;
			}, {});
		},
		onCancelbtn() {
			this.v$.$reset();
			this.popUpFrameWork = false;
			this.deleteAccess = false;
			Object.keys(this.frameworkObj).forEach((prop) => {
				Array.isArray(this.frameworkObj[prop]) ? (this.frameworkObj[prop] = []) : (this.frameworkObj[prop] = '');
			});
		},
		async addControlSource(payload: any) {
			try {
				await this.$http.post(`${process.env.VUE_APP_CONTROLS_API_URL}/api/root/controls/create`, payload).then(async (result: any) => {
					// await this.toggleTabs(0);
				});
			} catch (e) {
				toast.error(`Something went wrong`, {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
		},
		async deleteCustomControl(frameworkDeleteObj: any) {
			await this.$http
				.post(`${process.env.VUE_APP_CONTROLS_API_URL}/api/root/control/${frameworkDeleteObj._id}/delete?frameWork=${frameworkDeleteObj.controlFrameWork}`)
				.then((result: { data: any }) => {
					this.popUpFrameWork = false;
					toast.error(`Deleted Successfully`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				})
				.catch((error: any) => {
					this.isLoading = false;
					this.showLoader = false;
					toast.error(`Something went wrong`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
			// await this.toggleTabs(0);
		},
		async getAllControls(frameName: any) {
			this.dataLoading = true;
			if(this.tabHead && this.tabHead.length > 0){
				this.selectedFrameName = this.tabHead.find((obj:any)=> obj.value == frameName);
			}
			emitter.emit('visible', false);
			this.showFilters = false;
			try {
				this.isLoading = false;
				const result = await this.$http.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/root/controls/getall`);
				this.isLoading = true;
				this.columnObject = false;
				this.rowData = result.data;
				let jsonArray: any = [];
				let arr: any = [];
				let jObj: any = [];
				let iControls: any = [];
				this.rowData.forEach((item: any) => {
					if (item.controlFrameWork && item.controlNumber && Object.keys(item).includes('controlFrameWork') && Object.keys(item).includes('controlNumber') && !item.isInternalControl) {
						jObj.push(item);
						jsonArray = this.groupBy(jObj);
					} else {
						iControls.push(item);
					}
					arr.push(item);
				});
				console.log('arr', arr);
				let obj = { 'Internal Controls': iControls };
				Object.assign(jsonArray, obj);
				console.log('jsonArray', jsonArray);
				this.carouselHead = [];
				let displayFrameName = '';
				Object.keys(jsonArray).forEach((key, index) => {
					if (frameName === index) {
						displayFrameName = key;
					}
					this.carouselHead.push({ value: index, displayName: key });
				});
				// this.tableRow = arr
				// 	.filter((control: any) => control.controlFrameWork && control.controlFrameWork === displayFrameName)
				// 	.map((control: any) => ({
				// 		_id: control._id,
				// 		// frameWork: control.controlFrameWork,
				// 		number: control.controlNumber,
				// 		title: control.controlTitle,
				// 		family: control.controlFamily,
				// 		class: control.controlClass,
				// 		priority: Number(control.controlPriority) ? parseInt(control.controlPriority) : control.controlPriority
				// 		// 'No .of Kpis': control.kpis ? control.kpis.length: '0'
				// 	}));
				// this.tableRow = [];
				// arr.forEach((control: any) => {
				// 	if (control.controlFrameWork && control.controlFrameWork === displayFrameName && !Object.keys(control).includes('isInternalControl')) {
				// 		const obj: any = {
				// 			_id: control._id,
				// 			// frameWork: control.controlFrameWork,
				// 			number: control.controlNumber,
				// 			title: control.controlTitle,
				// 			family: control.controlFamily,
				// 			class: control.controlClass,
				// 			priority: Number(control.controlPriority) ? parseInt(control.controlPriority) : control.controlPriority
				// 			// 'No .of Kpis': control.kpis ? control.kpis.length: '0'
				// 		};
				// 		this.tableRow.push(obj);
				// 	} else if(Object.keys(control).includes('isInternalControl') && control.isInternalControl == true)  {
				// 		const obj: any = {
				// 			_id: control._id,
				// 			// frameWork: control.controlFrameWork,
				// 			number: control.controlNumber,
				// 			title: control.controlTitle,
				// 			family: control.controlFamily,
				// 			class: control.controlClass,
				// 			priority: Number(control.controlPriority) ? parseInt(control.controlPriority) : control.controlPriority
				// 			// 'No .of Kpis': control.kpis ? control.kpis.length: '0'
				// 		};
				// 		this.tableRow.push(obj);
				// 	}
				// });
				this.tableRow = [];
				let array = Object.values(jsonArray);
				array.forEach((controlValues: any, index: any) => {
					controlValues.forEach((control: any) => {
						if (frameName === index) {
							this.tableRow.push({
								_id: control._id,
								// frameWork: control.controlFrameWork,
								number: control.controlNumber?control.controlNumber:'N/A',
								title: control.controlTitle?control.controlTitle:'N/A',
								family: control.controlFamily?control.controlFamily:'N/A',
								class: control.controlClass?control.controlClass:'N/A',
								priority:control.controlPriority?Number(control.controlPriority) ? parseInt(control.controlPriority) : control.controlPriority:'N/A'
								// 'No .of Kpis': control.kpis ? control.kpis.length: '0'});
							});
							this.dataLoading = false;
						}
					});
				});
				if (this.rowData.length === 0) {
					this.columnObject = true;
					this.tableRow.push({ _id: '', number: '', title: '', family: '', class: '', priority: '' });
					// this.dataLoading = false;
				}
			} catch (e) {
				this.isLoading = false;
				toast.error(`Something went wrong`, {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
		}
	}
});
</script>
