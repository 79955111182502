<template>
  <div class="px-5 pt-3">
    <div class="mb-2">
      <breadCrumbs :routesArray="routesArray"></breadCrumbs>
    </div>
    <div class="row w-full justify-between flex">
      <h1 class="title">Create - Compliance</h1>
    </div>
    <div class="pt-5">
      <div
        v-if="complianceDiv == 1"
        class="cardbody compliancediv1 rounded-t-sm overflow-y-scroll bg-white border-b border-l border-r border-t border-lightgrey"
      >
        <div class="px-5 pt-5 mb-24">
          <div>
            <label class="controllabel text-xs">Compliance period</label>
            <div class="row flex flex-row w-full gap-5 mt-2 mb-3">
              <div class="w-6/12">
                <input
                  type="date"
                  @focus="openCalendar"
                  onkeydown="return false"
                  placeholder="Select Date"
                  v-model="presentComplianceObject.period.fromDate"
                  @change="checkFromPeriodFromDate"
                  :class="{
                    'is-invalid':
                      v$.presentComplianceObject.period.fromDate.$error,'inputboxstylingForSelectAtPopup': presentComplianceObject.period.fromDate === '','stylingForInputboxstylingForSelectAtPopup': presentComplianceObject.period.fromDate !== ''
                  }"
                />
                <div
                  v-if="v$.presentComplianceObject.period.fromDate.$error"
                  class="text-red-500"
                >
                  <div
                    class="error-text"
                    v-if="
                      v$.presentComplianceObject.period.fromDate.required
                        .$invalid
                    "
                  >
                    Required
                  </div>
                </div>
              </div>
              <div class="p-1">-</div>
              <div class="w-6/12">
                <input
                  type="date"
                  @focus="openCalendar"
                  onkeydown="return false"
                  :min="presentComplianceObject.period.fromDate"
                  placeholder="Select date"
                  v-model="presentComplianceObject.period.toDate"
                  :disabled="
                    presentComplianceObject.period.fromDate === ''
                      ? true
                      : false
                  "
                  :class="{
                    'is-invalid':
                      v$.presentComplianceObject.period.toDate.$error,'inputboxstylingForSelectAtPopup': presentComplianceObject.period.toDate === '','stylingForInputboxstylingForSelectAtPopup': presentComplianceObject.period.toDate !== ''
                  }"
                />
                <div
                  v-if="v$.presentComplianceObject.period.toDate.$error"
                  class="text-red-500"
                >
                  <div
                    class="error-text"
                    v-if="
                      v$.presentComplianceObject.period.toDate.required.$invalid
                    "
                  >
                    Required
                  </div>
                </div>
              </div>
            </div>
            <div class="row flex flex-row w-full gap-5 items-center mb-3">
              <div class="w-6/12">
                <label class="controllabel text-xs"
                  >Compliance Start Date</label
                >
                <input
                  type="date"
                  class="mt-2"
                  @focus="openCalendar"
                  onkeydown="return false"
                  v-model="presentComplianceObject.startDate"
                  @change="checkForStartDate"
                  :disabled="presentComplianceObject.period.toDate == ''"
                  :min="presentComplianceObject.period.fromDate > today ? presentComplianceObject.period.fromDate:today"
                  placeholder="Select Start Date"
                  :class="{
                    'is-invalid': v$.presentComplianceObject.startDate.$error,'inputboxstylingForSelectAtPopup': presentComplianceObject.startDate === '','stylingForInputboxstylingForSelectAtPopup': presentComplianceObject.startDate !== ''
                  }"
                />
                <div
                  v-if="v$.presentComplianceObject.startDate.$error"
                  class="text-red-500"
                >
                  <div
                    class="error-text"
                    v-if="
                      v$.presentComplianceObject.startDate.required.$invalid
                    "
                  >
                    Required
                  </div>
                </div>
              </div>
              <div class="px-1 paddingTop">-</div>
              <div class="w-6/12">
                <label class="controllabel text-xs">Compliance End Date</label>
                <input
                  type="date"
                  class="mt-2"
                  @focus="openCalendar"
                  onkeydown="return false"
                  :min="presentComplianceObject.startDate"
                  v-model="presentComplianceObject.endDate"
                  placeholder="Select End date"
                  :disabled="
                    presentComplianceObject.startDate === '' ? true : false
                  "
                  :class="{
                    'is-invalid': v$.presentComplianceObject.endDate.$error,'inputboxstylingForSelectAtPopup': presentComplianceObject.endDate === '','stylingForInputboxstylingForSelectAtPopup': presentComplianceObject.endDate !== ''
                  }"
                />
                <div
                  v-if="v$.presentComplianceObject.endDate.$error"
                  class="text-red-500"
                >
                  <div
                    class="error-text"
                    v-if="v$.presentComplianceObject.endDate.required.$invalid"
                  >
                    Required
                  </div>
                </div>
              </div>
            </div>
            <div class="row flex flex-row w-full gap-12 mb-3">
              <div class="w-6/12">
                <label class="controllabel text-xs">Compliance Title</label>
                <input
                  type="text"
                  class="inputboxstyling mt-2"
                  v-model.trim="presentComplianceObject.title"
                  @input="v$.presentComplianceObject.title.$touch()"
                  placeholder="Compliance Title"
                  :class="{
                    'is-invalid': v$.presentComplianceObject.title.$error || checkComplianceNames(presentComplianceObject.title)
                  }"
                />
                <div v-if="v$.presentComplianceObject.title.$error" class="text-red-500">
                  <div class="error-text" v-if="v$.presentComplianceObject.title.required.$invalid">Required</div>
                  <div v-else-if="v$.presentComplianceObject.title.required && v$.presentComplianceObject.title.restrictCharacters30.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
                </div>
                <div v-if="checkComplianceNames(presentComplianceObject.title)" class="text-red-500">
								<div class="error-text text-xs">Compliance title already exists.</div>
							</div>
              </div>
              <div class="w-6/12">
                <label class="controllabel text-xs">Compliance Id</label>
                <div class="inputboxstyling mt-2">{{ presentComplianceObject.complianceId }}</div>
                <!-- <input
                  type="text"
                  class="inputboxstyling"
                  v-model="presentComplianceObject.complianceId"
                  disabled
                  placeholder="Compliance Id"
                  :class="{
                    'is-invalid':
                      v$.presentComplianceObject.complianceId.$error,
                  }"
                /> -->
                <div
                  v-if="v$.presentComplianceObject.complianceId.$error"
                  class="text-red-500"
                >
                  <div
                    class="error-text"
                    v-if="
                      v$.presentComplianceObject.complianceId.required.$invalid
                    "
                  >
                    Required
                  </div>
                </div>
              </div>
            </div>
            <div class="row flex flex-row w-full gap-12 mb-3">
              <div class="w-6/12">
                <label class="controllabel">Team Leader</label>
                <multiselect
                  v-model="presentComplianceObject.teamLeader"
                  :options="userOptionsArr"
                  @search-change="asyncFind"
                  :multiple="false"
                  :close-on-select="true"
                  :clear-on-select="true"
                  label="label"
                  open-direction="bottom"
                  :hide-selected="true"
                  track-by="value"
                  :searchable="true"
                  :hideArrow="false"
                  placeholder="TeamLeader"
                  deselectLabel=""
                  selectLabel=""
                  class="userSelect custom-multiselect mt-2"
                  :showNoOptions="false"
				          :class="{
                    'is-invalid borderRed':
                      v$.presentComplianceObject.teamLeader.$error,
                  }"
                >
                 <template v-slot:noResult>
									<span class="text-xs">No Members Found</span>
								</template>
                </multiselect>
			  <div v-if="v$.presentComplianceObject.teamLeader.$error" class="text-red-500">
              <div class="error-text" v-if="v$.presentComplianceObject.teamLeader.required.$invalid">
                    Required
                  </div>
                </div>
				</div>
              <div class="w-6/12">
                <label class="controllabel text-xs">Frameworks (Maximum of 3 Frameworks)</label>
                <multiselect
                  v-model="presentComplianceObject.frameworks"
                  :multiple="true"
                  :max="3"
                  :options="auditFrameworkArray"
                  :searchable="true"
                  :hide-selected="true"
                  open-direction="bottom"
                  :close-on-select="false"
                  :show-labels="false"
                  placeholder="Frameworks"
                  class="custom-multiselect mt-2"
                  :class="{
                    'is-invalid borderRed':
                      v$.presentComplianceObject.frameworks.$error,
                  }"
                >
                  <template v-slot:maxElements> <span class="text-xs">Maximum 3 Frameworks</span></template>
                  <template v-slot:noResult>
                      <span class="text-xs">No Frameworks Found</span>
                  </template>
                </multiselect>
                <div
                  v-if="v$.presentComplianceObject.frameworks.$error"
                  class="text-red-500"
                >
                  <div
                    class="error-text"
                    v-if="
                      v$.presentComplianceObject.frameworks.required.$invalid
                    "
                  >
                    Required
                  </div>
                </div>
              </div>
            </div>
            <div class="row flex flex-row w-full gap-12 mb-3">
              <div class="w-6/12">
              <label class="controllabel text-xs">Location</label>
              <multiselect
                  v-model="presentComplianceObject.location"
                  :options="optionsLocationArray"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="true"
                  label="label"
                  track-by="value"
                  :searchable="true"
                  :hideArrow="false"
                  open-direction="bottom"
                  :hide-selected="true"
                  placeholder="Location"
                  deselectLabel=""
                  selectLabel=""
                  class="custom-multiselect mt-2"
                  :class="{
                    'is-invalid borderRed':
                      v$.presentComplianceObject.location.$error,
                  }"
                >
                  <template v-slot:noResult>
                      <span class="text-xs">No Locations Found</span>
                  </template>
                </multiselect>
                <div v-if="v$.presentComplianceObject.location.$error" class="text-red-500">
                <div class="error-text" v-if="v$.presentComplianceObject.location.required.$invalid">Required
                </div>
                </div>
              </div>
              <div class="w-6/12">
			        <label class="controllabel text-xs">Compliance Members</label>
                <multiselect
                  v-model="presentComplianceObject.complianceMembers"
                  :options="userOptionsArr"
                  @search-change="asyncFind"
                  :multiple="true"
                  :close-on-select="true"
                  :clear-on-select="true"
                  label="label"
                  track-by="value"
                  :searchable="true"
                  open-direction="bottom"
                  :hide-selected="true"
                  :hideArrow="false"
                  placeholder="Compliance Members"
                  class="custom-multiselect mt-2"
                  :showNoOptions="false"
                >
                <template v-slot:noResult>
									<span class="text-xs">No Members Found</span>
								</template>
                </multiselect>
                <!-- :class="{
                    'is-invalid borderRed':
                      v$.presentComplianceObject.complianceMembers.$error,
                  }" -->
                <!-- <div
                  v-if="v$.presentComplianceObject.complianceMembers.$error"
                  class="text-red-500"
                >
                  <div
                    class="error-text"
                    v-if="v$.presentComplianceObject.complianceMembers.required.$invalid">
                    Required
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else-if="complianceDiv == 2"
        class="cardbody rounded-t-sm overflow-y-scroll bg-white px-5 border-l border-r border-t border-lightgrey"
      >
        <div class="oneSideBorder pb-2 border-b border-lightgrey">
          <div class="justify-between items-center flex h-8 mt-3">
            <div class="flex my-3">
              <h2 class="header cursor-pointer">Select Teams</h2>
            </div>
            <div class="flex justify-end">
              <button
                @click="addTeamsToTableView"
                :class="
                  AddButtonDisableCondition()
                    ? 'btnprimary'
                    : 'btndisabled pointer-events-none'">
                Add
              </button>
            </div>
          </div>
          <div class="rounded flex text-xs mt-3">
            <div class="w-2/5">
              <div
                class="border border-lightgrey option mr-1 displayContainer overflow-y-scroll rounded"
              >
                <div v-if="showFilters" class="p-2">
                  <input
                    type="text"
                    v-model.trim="entityFilters.entity"
                    class="inputboxstyling"
                    placeholder="Search Entities"
                  />
                </div>
                <div
                  v-for="(location, index) in displayEntitiesList"
                  @click="clickedLocation(location)"
                  :class="
                    selectedLocation == location.value
                      ? 'bg-primary hover:bg-primary text-white'
                      : 'hover:bg-lightgrey'" :key="index" class="rounded">
                  <div class="p-3 pl-5 cursor-pointer">
                    <p class="text-xs">{{ location.label }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-3/5">
              <div class="w-full pt-0">
                <div
                  class="displayContainer border flex-col text-sm border-lightgrey overflow-y-scroll ml-1 rounded"
                >
                  <div v-if="showFilters" class="p-2">
                    <input
                      type="text"
                      v-model.trim="teamFilters.team"
                      class="inputboxstyling"
                      placeholder="Search Teams"
                    />
                  </div>
                  <div
                    class="h-7 flex justify-normal items-center cursor-pointer p-5 hover:bg-lightgrey"
                    v-for="(team, index) in displayTeamList"
                    :key="index"
                  >
                    <div class="flex items-center whitespace-nowrap">
                      <input
                        v-model="finalMappingObj[selectedLocation]"
                        :value="team._id"
                        :id="index"
                        type="checkbox"
                        class="checkbox"
                      />
                      <label :for="index" class="pl-3 cursor-pointer">{{ team.teamName }} ({{ team.controlCount == undefined || team.controlCount == null ? team.controlCount : 0 || team.controlCount}} {{'Controls'}})</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--v-if="mappingTableTeamsArray.length > 0"  -->
        <div class="pb-5 mb-14">
          <h2 class="header mt-2 cursor-pointer">Selected Teams</h2>
          <tableData
            :tableRowData="mappingTableTeamsArray"
            :headersData="teamDisplayHeaders"
            :departmentArr="filteredDepartments"
            :locations="optionsLocationArray"
          ></tableData>
        </div>
      </div>
      <div
        v-else-if="complianceDiv == 3"
        class="cardbody rounded-t-sm overflow-y-scroll bg-white border-l border-r border-t border-lightgrey"
      >
        <div v-if="teamDetailsTableArray.length > 0" class="mb-10">
          <newTable
            :tableRowData="teamDetailsTableArray"
            :headersData="detailsEditHeaders"
            :locations="optionsLocationArray"
            :userArray="filterUsersArray"
            :departmentArr="filteredDepartments"
          ></newTable>
        </div>
      </div>
    </div>
    <div class="fixed flex w-full bottom-5 right-0 bg-white rounded p-5 justify-end">
      <!-- && callsComplete -->
      <div v-if="complianceDiv == 1" class="flex justify-end pr-8 float-right w-full">
        <button class="mr-1.5" :class="disableStatus? 'btnprimary': 'btndisabled pointer-events-none'" @click="onCancelbtn()">
          Cancel
        </button>
        <button class="mr-1.5" :class="( disableStatus|| pageType === 'edit') ? 'btnprimary' : 'btndisabled pointer-events-none'" :disabled="!(JSON.stringify(dummyComplianceObj) !== JSON.stringify(presentComplianceObject) || pageType === 'edit')" @click="nextFrameworkMapbtnAudit">
        Next
        </button>
        </div>
      <div v-else-if="complianceDiv == 2" class="flex justify-end pr-8 float-right w-full">
        <button class="btnprimary mr-1.5" @click="goBack()">Back</button>
        <button class="mr-1.5" :class=" mappingTableTeamsArray.length > 0? 'btnprimary' : 'btndisabled pointer-events-none'"
          @click="nextFrameworkMapbtnAudit()">
          Next
        </button>
      </div>
      <div v-else-if="complianceDiv == 3" class="flex justify-end pr-8 float-right w-full">
        <button class="btnprimary mr-1.5" @click="goBack()">Back</button>
        <button class="btnprimary mr-1.5" v-if="pageType == 'add'" @click="createNewCompliance()" :disabled="clickOnButton"
        >
          Create
        </button>
        <button class="btnprimary mr-1.5" v-if="pageType == 'edit'" :class="disableStatus? 'btnprimary': 'btndisabled pointer-events-none'" :disabled="clickOnButton" @click="updateCompliance()">
          Update
        </button>
      </div>
    </div>
  </div>
</template>
<style scoped>
.paddingTop{
  padding-top : 28px;
}
.displayContainer {
  height: 276px !important;
}
.compliancediv1::-webkit-scrollbar {
	width: 0 !important;
	height: 0 !important;
	background: transparent;
}

.compliancediv1::-webkit-scrollbar-thumb {
	background: transparent;
}
.oneSideBorder {
  border-bottom-style: 1px solid #e9e9e9;
}
.drop {
  box-shadow: 0px -25px 20px -20px rgba(0, 0, 0, 0.05),
    0px -21px 255px -255px rgba(121, 119, 119, 0.05);
}
.cardbody {
  height: 68vh;
}
.fixBody {
  height: 12vh;
  width: calc(100% - 220px);
  position: fixed;
  bottom: 28px;
}
.borderRed {
  border: 1px solid red !important;
}
::-webkit-scrollbar {
  width: 0px;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { mapGetters } from "vuex";
import { required } from "@vuelidate/validators";
import { helpers } from "@vuelidate/validators";
import multiselect from "vue-multiselect";
import { groupBy } from "lodash";
import { emitter, toast } from "@/main";
import { v4 as uuidv4 } from "uuid";
import _ from 'lodash';
import tableData from "@/components/tableData.vue";
import newTable from "@/components/newTable.vue";
import breadCrumbs from "@/components/breadCrumb.vue";
import spinner from '@/components/spinner.vue';
const restrictCharacters30 = helpers.regex(/^.{0,30}$/);
export default defineComponent({
  data(): any {
    return {
      v$: useVuelidate(),
      complianceNames : [],
      userOptionsArr: [],
      pageType: "",
      complianceDiv: 1,
      optionsLocationArray: [],
      auditFrameworkArray: [],
      optionUserArray: [],
      orgUsersList: [],
      clickOnButton:false,
      dummyComplianceObj: [],
      mappingTableTeamsArray: [],
      teamDetailsTableArray: [],
      dummyteamDetailsTable: [],
      filteredDepartments: [],
      tableDisplayTeamIds: [],
      routesArray:[],
      allDepartments: [],
      existingComplianceArray: [],
      filter: {},
      finalMappingObj: {},
      showFilters: true,
      entityFilters: {
        entity: "",
      },
      teamFilters: {
        team: "",
      },
      filtereduser: [
        { id: 1, name: "John Doe" },
        { id: 2, name: "Jane Smith" },
        { id: 3, name: "Jack White" },
        { id: 4, name: "Jill Black" },
      ],
      teamDisplayHeaders: ["team", "entity", "deleteIcon"],
      detailsEditHeaders: ["team", "entity", "teamOwner", "reviewer"],
      selectedLocation: "",
      searchQuery: "",
      presentComplianceObject: {
        period: {
          fromDate: "",
          toDate: "",
        },
        startDate: "",
        endDate: "",
        title: "",
        complianceId: "",
        location: [],
        frameworks: [],
        teamLeader: "",
        complianceMembers: [],
        entities: [],
        // callsComplete: false,
      },
      controlCountData: [],
      filterUsersArray:[]
    };
  },
  validations() {
    return {
      presentComplianceObject: {
        period: {
          fromDate: { required },
          toDate: { required },
        },
        startDate: { required },
        endDate: { required },
        title: { required, restrictCharacters30 },
        complianceId: { required },
        location: { required },
        frameworks: { required },
        teamLeader: { required },
        // complianceMembers: { required },
      },
    };
  },
  components: {
    multiselect,
    tableData,
    breadCrumbs,
    newTable,
    spinner
  },
  computed: {
    ...mapGetters({ userInfo: "userInfo" }),
    // disableStatus: function (): any {
    //   let output;
    //   let val: any = [];
    //   Object.values(this.presentComplianceObject).forEach((value: any) => {
    //     val.push(value);
    //   });
    //   for (let n of val) {
    //     if (n != "") {
    //       output = true;
    //     }
    //   }
    //   return output;
    // },
    disableStatus: function (): any {
      let result;
  
      // Helper function to sort arrays, including nested arrays
      const sortArray:any = (array: any[]) => {
          return array.map(item => {
              if (Array.isArray(item)) {
                  return sortArray(item); // Sort nested arrays if any
              }
              return item;
          }).sort();
      };
  
      // Helper function to sort arrays of objects by a specific key
      const sortArrayOfObjects = (array: any[], key: string) => {
          return _.sortBy(array, [key]); // Sort the array by the specified key (e.g., 'value')
      };
  
      // Function to handle deep comparison, including sorting of arrays and arrays of objects
      const deepEqualWithArraySort = (obj1: any, obj2: any) => {
          const keys1 = Object.keys(obj1);
          const keys2 = Object.keys(obj2);
  
          if (keys1.length !== keys2.length) return false;
  
          for (let key of keys1) {
              if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
                  // For `frameworks` array, compare directly by sorting values
                  if (key === 'frameworks') {
                      if (!_.isEqual(sortArray(obj1[key]), sortArray(obj2[key]))) {
                          return false;
                      }
                  }
                  // For `locations` and `complianceMembers` arrays, sort by 'value' before comparison
                  else if (key === 'location' || key === 'complianceMembers') {
                      if (!_.isEqual(sortArrayOfObjects(obj1[key], 'value'), sortArrayOfObjects(obj2[key], 'value'))) {
                          return false;
                      }
                  } else {
                      // For other arrays, use default sorting and comparison
                      if (!_.isEqual(sortArray(obj1[key]), sortArray(obj2[key]))) {
                          return false;
                      }
                  }
              } else if (_.isObject(obj1[key]) && _.isObject(obj2[key])) {
                  if (!deepEqualWithArraySort(obj1[key], obj2[key])) {
                      return false;
                  }
              } else if (!_.isEqual(obj1[key], obj2[key])) {
                  return false;
              }
          }
          return true;
      };
  
      if (this.presentComplianceObject) {
          if (deepEqualWithArraySort(this.presentComplianceObject, this.dummyComplianceObj)) {
              result = false;
          } else {
              result = true;
          }
      } else {
          let valueArray: any = [];
          Object.values(this.presentComplianceObject).forEach((value: any) => {
              valueArray.push(value);
          });
          for (let value of valueArray) {
              if (value != '') {
                  result = true;
              }
          }
      }
 
        return result;
    },
    displayEntitiesList: function (): any {
      return this.presentComplianceObject.location.filter((obj: any) => {
        return obj.label.toLowerCase().includes(this.entityFilters.entity);
      });
    },
    displayTeamList: function (): any {
      console.log("controlCount", this.filteredDepartments)
      this.filteredDepartments.forEach((obj:any)=>{
        console.log("controlCountData", this.controlCountData)
        let countObj = this.controlCountData.find((countObj:any)=>{return countObj.departmentId == obj._id}) 
        console.log("countObj", countObj)
        if (countObj && countObj.count){
          obj.controlCount = countObj.count
        } else if(countObj && countObj.controlCount){
          obj.controlCount = countObj.controlCount
        } else{
          obj.controlCount = 0
        }          
      })
      return this.filteredDepartments.filter((obj: any) => {
        console.log("kldsklhfsd",obj)
        let locationIds: any = [];
        let countObj = this.controlCountData.find((countObj:any)=>{return countObj.departmentId == obj._id}) 
        let checkTeamAddedInAdmin:any = Object.keys(obj)
        let checkPrimaryEntity:any = this.displayEntitiesList.find((data:any)=>{return data.value == this.selectedLocation})
        console.log("checkPrimaryEntity",checkPrimaryEntity)
        
        let isMapped = this.mappingTableTeamsArray.some(
            (item: any) =>
              item.team == obj._id && item.entity === this.selectedLocation
          );
        if(checkPrimaryEntity != undefined && checkPrimaryEntity.entityType == 1 && checkTeamAddedInAdmin.includes('isRootDepartment') && obj.isRootDepartment == true){
          console.log("add the teams")
          return obj && !isMapped &&
            obj.teamName.toLowerCase().includes(this.teamFilters.team) &&
            this.displayEntitiesList.length > 0
        }
        console.log("this.teamFilters",this.teamFilters)
          if (obj.entityType == 20303) {
              locationIds.push(obj.entityId);
          }
          return (
            (
              (obj.entityType == 20303 &&
                locationIds.includes(this.selectedLocation))) &&
            !isMapped &&
            obj.teamName.toLowerCase().includes(this.teamFilters.team) &&
            this.displayEntitiesList.length > 0
          );
      });
    },
    today() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
  },
  async mounted() {
    emitter.off('auditValidationDone')
		emitter.on('auditValidationDone', async (action:any) => {
			if(action == 'add'){
				await this.ClickedOnCreate();
			}else{
				await this.updatePresentCompliance()
			}
			
      });
    emitter.off("deleteRowFromTable");
    emitter.on("deleteRowFromTable", (data: any) => {
      let afterDeleteArray = this.mappingTableTeamsArray.filter((obj: any) => {
        return !(data.team == obj.team && data.entity == obj.entity);
      });
      this.mappingTableTeamsArray = [...afterDeleteArray];
    });
  },
  methods: {
     async getAllCompliances() {
      await this.$http.get(`${process.env.VUE_APP_MONITOR_API_URL}/api/org/compliance/getAll`).then((result:any) => {
          this.existingComplianceArray = [...result.data];
        });
    },
     asyncFind(query:any){
			if(query == ''){
				this.userOptionsArr = []
			}else if(query.length >= 1){
				this.userOptionsArr = this.optionUserArray.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
			}
		},
    //  checkComplianceNames(complianceTitle: any) {
		// 	// console.log("this.complianceTitle", complianceTitle);
		// 	let check: any = this.complianceNames.includes(complianceTitle)
		// 	// console.log("this.check", check);
		// 	return check;
		// },
    checkComplianceNames(complianceTitle: any) {
			const trimmedComplianceTitle = complianceTitle.trim().toLowerCase();
			let array: any 
			if(this.pageType == 'edit'){
				let originalObj = this.existingComplianceArray.find((obj: any)=>{return obj._id == this.$route.params.complianceId})
				let a: any = this.presentComplianceObject.title
				array = this.complianceNames.filter((obj: any)=>{
           return obj.toLowerCase() !== originalObj.title.toLowerCase();
				})
				const trimmedCheckedTitle = complianceTitle.trim().toLowerCase();
				let check: any = array.map((name: any) => name.toLowerCase()).includes(trimmedCheckedTitle)
			return check;
			}else if(this.pageType == 'add'){
				let check: any = this.complianceNames.map((name: any) => name.toLowerCase()).includes(trimmedComplianceTitle)
				return check;
			}
		},
    async createNewCompliance(){
      this.clickOnButton = true;
			emitter.emit('checkAuditValidation','add')
		},
		async updateCompliance(){
      this.clickOnButton = true;
			emitter.emit('checkAuditValidation','edit')
		},
    async ClickedOnCreate() {
      let copyOfpresentComplianceObject:any = _.cloneDeep(this.presentComplianceObject);
      copyOfpresentComplianceObject.location =
        copyOfpresentComplianceObject.location.map((obj: any) => obj.value);
      copyOfpresentComplianceObject.complianceMembers =
        copyOfpresentComplianceObject.complianceMembers.map(
          (obj: any) => obj.value
        );
        copyOfpresentComplianceObject.teamLeader =
        copyOfpresentComplianceObject.teamLeader.value;
        copyOfpresentComplianceObject.entities.map((entityObj: any) => {
          entityObj.teams.map((obj: any) => {
            obj.teamOwner = obj.teamOwner.value;
            obj.reviewer = obj.reviewer.value;
          });
      });
      copyOfpresentComplianceObject.createdBy = this.userInfo._id;
      copyOfpresentComplianceObject.createdAt = new Date();
      copyOfpresentComplianceObject.statusCode = 10401;
      try {
        await this.$http
          .post(
            `${process.env.VUE_APP_MONITOR_API_URL}/api/org/compliance/create`,
            [copyOfpresentComplianceObject]
          )
          .then(async () => {
            this.showPopup = false;
            toast.info(`Created Successfully`, {
              timeout: 1000,
              closeOnClick: true,
              closeButton: "button",
              icon: true,
            });
            this.$router.push({ name: "compliance" });
          });
      } catch (e) {
        toast.error(`Something went wrong`, {
          timeout: 1000,
          closeOnClick: true,
          closeButton: "button",
          icon: true,
        });
      } finally {
				this.clickOnButton = false;
			}
    },

    async updatePresentCompliance() {
      // let newCompliance:any = {...this.presentComplianceObject};
      let newCompliance:any = _.cloneDeep(this.presentComplianceObject);

      delete newCompliance._id;
      newCompliance.location = newCompliance.location.forEach((obj:any) => obj.value);
      newCompliance.complianceMembers = newCompliance.complianceMembers.forEach((obj: any) => obj.value);
      newCompliance.teamLeader = newCompliance.teamLeader.value;
      newCompliance.entities.forEach((entityObj: any) => {
      entityObj.teams.forEach((obj: any) => {
        obj.teamOwner = obj.teamOwner.value;
		    obj.reviewer = obj.reviewer.value;
      });
      });
      newCompliance.updatedAt = new Date();
      newCompliance.updatedBy = this.userInfo._id;

      console.log('newCompliance',newCompliance)
      // let payloadObj:any = {...newCompliance};


      try{
        await this.$http
        .post(`${process.env.VUE_APP_MONITOR_API_URL}/api/org/compliance/${this.$route.params.complianceId}/update`, newCompliance)
        .then((result: any) => {
          toast.info(`Updated Successfully`, {
              timeout: 1000,
              closeOnClick: true,
              closeButton: "button",
              icon: true,
            });
             this.$router.push({name:'compliance'})
        });
        }catch (e) {
          toast.error('error', {
            timeout: 1000,
            closeOnClick: true,
            closeButton: 'button',
            icon: true
          });
        } finally {
          this.clickOnButton = false;
        }
    },
    modifyComplianceForEdit(obj: any) {
      let presentObj: any = { ...obj };
      let CompliancelocationIds = [...presentObj.location];
      presentObj.location = [];
      let ComplianceMembersIds = [...presentObj.complianceMembers];
      presentObj.complianceMembers = [];
      CompliancelocationIds.map((id: any) => {
        let findObj: any = this.optionsLocationArray.find((obj: any) => {
          return obj.value == id;
        });
        findObj != undefined ? presentObj.location.push(findObj) : "";
      });
      ComplianceMembersIds.map((id: any) => {
        let findUserObj;
        findUserObj = this.optionUserArray.find((obj: any) => {
          return obj.value == id;
        });
        findUserObj != undefined
          ? presentObj.complianceMembers.push(findUserObj)
          : "";
      });
      let personId = presentObj.teamLeader;
      let findTl: any = this.optionUserArray.find((obj: any) => {
        return obj.value == personId;
      });
      findTl != undefined ? (presentObj.teamLeader = findTl) : "";


      presentObj.entities.map((entityObj:any)=>{
          entityObj.teams.map((detailObj:any)=>{
              let findAUditeeobj = this.optionUserArray.find((obj:any)=>{return obj.value == detailObj.teamOwner});
              let findAuditorobj = this.optionUserArray.find((obj:any)=>{return obj.value == detailObj.reviewer});
              detailObj.teamOwner = findAUditeeobj;
              detailObj.reviewer = findAuditorobj;
          })
      })

      return presentObj;
    },

    updateSearchQuery(query: any) {
      this.searchQuery = query;
    },
    async getallEntities() {
      this.optionsLocationArray = [];
      await this.$http
        .get(
          `${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`
        )
        .then((result: any) => {
          result.data.map((obj: any) => {
            let sendObj = {
              value: obj._id,
              label: obj.entityName,
              owner: obj.owner,
              entityType: obj.entityType
            };
            this.optionsLocationArray.push(sendObj);
          });
        });
    },
    async getControlsCount() {
        try {
          await this.$http.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/departments/mappedcontrols/count`).then((result: { data: any }) => {
            this.controlCountData = result.data;
          });
        } catch (e) {
          toast.error('error', {
            timeout: 1000,
            closeOnClick: true,
            closeButton: 'button',
            icon: true
          });
        }


      await this.$http
        .get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`)
        .then((res: any) => {
          this.allDepartments = [...res.data];
          this.filteredDepartments = res.data.filter((obj: any) => {
            return Object.keys(obj).includes("entityType");
          });
        });
    },
    async getControls() {
      await this.$http
        // .get(`http://localhost:4030/services/controls/api/org/allControls`)
        .get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/allControls`)
        .then((result: { data: any }) => {
          this.controlSets = result.data.filter((obj: any) => {
            return Object.keys(obj).includes("controlFrameWork");
          }); 
          const groupedControlsFrameworks = groupBy(
            this.controlSets,
            "controlFrameWork"
          );
          const listOfFrameworks = Object.keys(groupedControlsFrameworks);
          listOfFrameworks.forEach((key) => {
            if (key != "") {
              // let obj = {value:key,label:key}
              this.auditFrameworkArray.push(key);
            }
          });
          this.auditFrameworkArray.push('Internal Controls')
        })
        .catch((error: any) => {
          this.isLoading = false;
            toast.error(` ${error}`, {
              timeout: 1000,
              closeOnClick: true,
              closeButton: "button",
              icon: true,
            });
        });
    },
    async getAllUsers() {
      await this.$http
        .get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`)
        .then((res: any) => {
          this.orgUsersList = res.data
          // this.orgUsersList = res.data.filter((user: any) => {
          //   return user.isActive == true;
          // });
          for (var i = 0; i < this.orgUsersList.length; i++) {
            let obj = {
              label: this.orgUsersList[i].name,
              value: this.orgUsersList[i]._id,
            };
            this.optionUserArray.push(obj);
          }
        });
    },
    async nextFrameworkMapbtnAudit() {
      let checkComplianceName = this.complianceNames.includes(this.presentComplianceObject.title)
      this.v$.presentComplianceObject.$touch();
      if (!this.v$.presentComplianceObject.$invalid && (this.complianceNames.length == 0 || !this.checkComplianceNames(this.presentComplianceObject.title))) {
        if (this.complianceDiv == 1) {
          this.clickedLocation(this.displayEntitiesList[0]);
          if(this.pageType == 'edit'){
						this.mappingTableTeamsArray = []
						this.presentComplianceObject.entities.map((entityObj:any)=>{
							entityObj.teams.map((teamobj:any)=>{
								// let findAUditeeobj = this.optionUserArray.find((obj:any)=>{return obj.value == teamobj.teamOwner});
								// let findAuditorobj = this.optionUserArray.find((obj:any)=>{return obj.value == this.presentComplianceObject.teamLeader.value});
								// teamobj.teamOwner = findAUditeeobj;
								// teamobj.reviewer = findAuditorobj;
								this.mappingTableTeamsArray.push(teamobj)
							})
						})
          }

          this.filterUsersArray = [...this.presentComplianceObject.complianceMembers]
          let memeberIds:any = []
          this.presentComplianceObject.complianceMembers.map((obj:any)=>{
              memeberIds.push(obj.value);
          })

          if(!memeberIds.includes(this.presentComplianceObject.teamLeader.value)){
              this.filterUsersArray.push({...this.presentComplianceObject.teamLeader});
          }

          this.complianceDiv = 2;



        } else if (this.complianceDiv == 2) {
          this.presentComplianceObject.entities = [];
          this.teamDetailsTableArray = [];
          this.dummyteamDetailsTable = [];
          let groupByObjectForTeamsAndLocation: any = this.groupBy(this.mappingTableTeamsArray,
          "entity");
          let entityKeys = Object.keys(groupByObjectForTeamsAndLocation);
          for (let key of entityKeys) {
            if (
              groupByObjectForTeamsAndLocation[key] != undefined &&
              groupByObjectForTeamsAndLocation[key].length > 0
            ) {
              let pushObj = {
                entity: key,
                teams: groupByObjectForTeamsAndLocation[key],
              };
              this.presentComplianceObject.entities.push(pushObj);
              groupByObjectForTeamsAndLocation[key].map((obj: any) => {
                this.teamDetailsTableArray.push(obj);
                this.dummyteamDetailsTable.push({ ...obj });
              });
            } else {
              console.log("no teams for this entity");
            }
          }
          this.complianceDiv = 3;
        }
      }
    },
    clickedLocation(location: any) {
      console.log("location",location)
      this.selectedLocation = location.value;
      if (!Object.keys(this.finalMappingObj).includes(location.value)) {
        this.finalMappingObj[location.value] = [];
      }
    },
    async addTeamsToTableView() {
      // this.mappingTableTeamsArray = []
      this.tableDisplayTeamIds = [];
      // let controlTeams = this.displayTeamList()
      let finalArray = Object.entries(this.finalMappingObj);
      let hasShownToast = false;
      for (let [location, teams] of finalArray) {
        let locationObj = this.optionsLocationArray.find((obj:any)=>{return obj.value == location});
        let locationOwnerObj = this.optionUserArray.find((obj:any)=>{return obj.value == locationObj.owner});
        let teamLeader = this.optionUserArray.find((obj:any)=>{return obj.value == this.presentComplianceObject.teamLeader.value})
        let assignedTeams: any = teams;
        assignedTeams.map((teamId: any) => {
          let controlTeam:any 

          controlTeam = this.filteredDepartments.find((dept: any)=> dept._id == teamId);

          if (controlTeam.type == 20303){
            controlTeam = this.filteredDepartments.find((dept: any)=> dept._id == teamId && dept.entity == location);
          }

          if((controlTeam.controlCount != null || controlTeam.controlCount != undefined) && controlTeam.controlCount > 0){
            this.tableDisplayTeamIds.push(teamId);
            let pushObj = {
              team: teamId,
              entity: location,
              teamOwner:locationOwnerObj,
              reviewer: teamLeader,
            };
            this.mappingTableTeamsArray.push(pushObj);
          }
          else {
            if (!hasShownToast) {
              toast.error(`The Team Selected have no Controls`, {
                timeout: 1000,
                closeOnClick: true,
                closeButton: "button",
                icon: true,
              });
              hasShownToast = true; // Set flag to true after showing toast
            }
          }

        });
      }

      let keys = Object.keys(this.finalMappingObj);
      keys.forEach((key: any) => {
        this.finalMappingObj[key] = [];
      });
      this.teamFilters.team = "";
      this.entityFilters.entity = "";
    },
    
    onCancelbtn() {
      this.v$.$reset();

      if (this.pageType == "add") {
        this.mappingTableTeamsArray = [];
        Object.keys(this.presentComplianceObject).forEach((prop) => {
          if (prop == "period") {
            this.presentComplianceObject.period.fromDate = "";
            this.presentComplianceObject.period.toDate = "";
          } else if (prop != "complianceId") {
            Array.isArray(this.presentComplianceObject[prop])
              ? (this.presentComplianceObject[prop] = [])
              : (this.presentComplianceObject[prop] = "");
          }
        });
      } else {
        this.presentComplianceObject = JSON.parse(
          JSON.stringify(this.dummyComplianceObj)
        );
      }
    },
    groupBy(arr: any, keyName: any) {
      return arr.reduce((group: any, product: any) => {
        const keyValue = product[keyName];
        group[keyValue] = group[keyValue] ?? [];
        group[keyValue].push(product);
        return group;
      }, {});
    },
    goBack() {
      if (this.complianceDiv == 2) {
        // this.mappingTableTeamsArray = [];
        if (this.pageType == "add") {
          let keys = Object.keys(this.finalMappingObj);
          keys.forEach((key: any) => {
            this.finalMappingObj[key] = [];
          });
        } 
        // else {
        //   this.presentComplianceObject = JSON.parse(JSON.stringify(this.dummyComplianceObj));
        // }
        this.complianceDiv = 1;
        this.filterUsersArray=[];

      } else if (this.complianceDiv == 3) {

				if(this.pageType == 'add'){
					this.presentComplianceObject.entities = [];
				}
        //else{
				// 	this.presentComplianceObject.entities = JSON.parse(JSON.stringify(this.dummyComplianceObj.entities))
				// 	this.mappingTableTeamsArray = [];
				// 	this.teamDetailsTableArray = [];
				// 	this.presentComplianceObject.entities.map((entityObj:any)=>{
				// 		entityObj.teams.map((teamobj:any)=>{
				// 		let findAUditeeobj = this.optionUserArray.find((obj:any)=>{return obj.value == teamobj.teamOwner});
				// 		let findAuditorobj = this.optionUserArray.find((obj:any)=>{return obj.value == teamobj.reviewer});
				// 		teamobj.teamOwner = findAUditeeobj;
				// 		teamobj.reviewer = findAuditorobj;
				// 		this.mappingTableTeamsArray.push(teamobj)
				// 		})
				// 	})

				// }
				this.clickedLocation(this.displayEntitiesList[0]);
				this.complianceDiv = 2

			}
    },
    openCalendar(event: any) {
      event.target.showPicker();
    },
    checkFromPeriodFromDate() {
      if (this.presentComplianceObject.period.toDate != "") {
        if (
          this.presentComplianceObject.period.fromDate >
          this.presentComplianceObject.period.toDate
        ) {
          this.presentComplianceObject.period.toDate = "";
        }
      }
    },
    checkForStartDate() {
      if (this.presentComplianceObject.endDate != "") {
        if (
          this.presentComplianceObject.startDate >
          this.presentComplianceObject.endDate
        ) {
          this.presentComplianceObject.endDate = "";
        }
      }
    },
    AddButtonDisableCondition() {
      let ValueArraysOfObj: any = Object.values(this.finalMappingObj);
      if (ValueArraysOfObj.length > 0) {
        let check = ValueArraysOfObj.some((arr: any) => {
          return arr.length > 0;
        });
        return check;
      } else {
        return false;
      }
    },
  },
  async created() {
    await this.getControls();
    await this.getControlsCount();
    await this.getallEntities();
    await this.getAllUsers();
    if (this.$route.path.includes("/createCompliance")) {
      this.routesArray = [
        { name: "Compliance", routeName: "compliance" },
        { name: "Add Compliance", routeName: "" },
      ];
      this.pageType = "add";
      let id = uuidv4();
      const truncatedUuid = id.replace(/-/g,'').substring(0, 8)
      this.presentComplianceObject.complianceId = truncatedUuid;
      this.dummyComplianceObj = JSON.parse(
        JSON.stringify(this.presentComplianceObject)
      );
       await this.getAllCompliances();
       this.complianceNames = []
      let arr:any = []
      this.existingComplianceArray.forEach((name: any) => {
          arr.push(name.title)
					this.complianceNames = arr;
				});
    } else {
      this.routesArray = [
        { name: "Compliance", routeName: "compliance" },
        { name: "Edit Compliance", routeName: "" },
      ];
      this.pageType = "edit";
      await this.getAllCompliances();
      this.complianceNames = []
      let arr:any = []
      this.existingComplianceArray.forEach((name: any) => {
        // console.log("name", name.title) 
          arr.push(name.title)
					this.complianceNames = arr;
				});
      let present = this.existingComplianceArray.find((obj: any) => {
        return this.$route.params.complianceId == obj._id;
      });
      let modified = await this.modifyComplianceForEdit(present);
      this.presentComplianceObject = { ...modified };
      this.dummyComplianceObj = JSON.parse(
        JSON.stringify(this.presentComplianceObject)
      );
    }
    this.complianceDiv = 1;
  },
});
</script>

