<template>
	<div class="pt-3 pl-5 pr-5">
		<div class="mb-2">
			<breadCrumb :routesArray="routesArray"></breadCrumb>
		</div>
		<div>
			<div class="items-center justify-between flex">
				<div class="title flex items-center">
					<h1 class="title" :class="titleLength > 40? 'heading-ellipsis':''">{{presentControlObject.controlTitle}}</h1>
				</div>
				<div class="flex flex-row-reverse">
					<button class="flex" @click="filterOpen" :class="(selectedTabIndex == 0 && kpisListForPresentControl.length != 0) || (selectedTabIndex == 1 && allRootKpis.length != 0) ? 'filterButton' : 'filterButtondisabled pointer-events-none'">
						Filters
						<img src="@/assets/filter.svg" class="h-3 ml-2.5" />
					</button>
					<button class="mr-3" v-if="selectedTabIndex == 0" :class="showTotalKpisWeightage < 100 ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="addKPI(kpi)">Add</button>
				</div>
			</div>
			<div class="mt-5 card w-full h-full">
				<div class="px-5 pb-5">
					
					<div class="mr-2.5">
						<div ref="carouselContainer" class="carousel-container">
						<div class="w-3/12 pt-5 flex flex-row borderBottom justify-between overflow-hidden cursor-pointer">
							<div class="ProgessStyle mr-1" :class="selectedTabIndex == tab.id ? 'adjust header font-semibold':'text-sm text-disabledgrey'" v-for="(tab, tabIndex) in carouselHead" :key="tabIndex" @click="toggleTabs(tab.id)">
								<div>
									<div ref="actualIndex">{{ tab.displayName }}</div>
									<div :class="selectedTabIndex == tab.id ? 'progress' : ''" class="h-1 p-0.75"></div>
								</div>
							</div>
						</div>
						</div>
						<div class="progressbarSI h-1 mr-2.5"></div>
					</div>
					
					
					<div class="button-adjust flex justify-between w-full" v-if="carouselHead.length > pageItems">
						<div class="marginprev" @click="leftButton">
							<img src="@/assets/leftArrowFilled.svg" class="w-4 h-4" :class="cantGoBack ? 'disabled pointer-events-none' : 'mr-2.5'" @click="prevPage" />
						</div>
						<div class="marginnext" @click="rightButton">
							<img src="@/assets/rightArrowFilled.svg" class="w-4 h-4" :class="cantGoForward ? 'disabled pointer-events-none' : 'ml-2.5'" @click="nextPage" />
						</div>
					</div>
					<div>
						<div v-if="selectedTabIndex == 0" class="noScrollbar tabStructure1 overflow-y-auto">
							<div class="ResponsivetableCornerRadius bg-white">
								<!-- :class="sortedAppliedKpisData.length == 0 ? 'border-separate' : 'border-collapse'" -->
								<table>
									<thead>
										<th>
											<input type="checkbox" name="checkbox" class="checkbox cursor-pointer" v-model="selecteAllActiveKpis" @change="deSelectKpis(0,$event)" />
										</th>
										<th scope="col" v-for="column in tableHeads" :key="column" class="bg-white cursor-pointer capitalize" :class="getColumnHeadWidth(column)" @click="sort(column)">
											
											<div>
												<div class="flex">
													<div v-if="column == 'description'">KPI Name</div>
													<div v-else-if="column == 'weightage'">weightage (%)</div>
													<!-- <div v-else-if="column == 'target'">Target (%)</div>
													<div v-else-if="column == 'targetTimePeriod'">Target Time Period</div>
													<div v-else-if="column == 'reportingFrequency'">Reporting Frequency</div> -->
													<div v-else>{{column}}</div>
													<div class="sort cursor-pointer" :class="className == column && (selectedTabIndex == 0 && kpisListForPresentControl.length != 0) || (selectedTabIndex == 1 && allRootKpis.length != 0) ? classSort : 'opacity-50'"></div>
												</div>
											</div>
										</th>
										<tr v-if="showFilters">
                                            <td></td>	
											<td v-for="name in tableHeads" class="bg-white" :key="name">
												<select class="inputboxstyling resize" v-if="name == 'targetTimePeriod' || name == 'reportingFrequency'" v-model="filters[name]">
													<option :value="filters.name" selected disabled hidden>Select</option>
													<option v-for="(value, index) in controlRecurringArray" :key="index" :value="value.id">{{ value.label }}</option>
												</select>
												<div v-else-if="name == 'weightage'"></div>
												<input v-else type="text" class="inputboxstyling" placeholder="Search" v-model="filters[name]" />
											</td>
										</tr>
									</thead>
                                    <tbody v-if="loadData == true">
                                        <tr class="cursor-pointer empty rowHeight">
                                            <td class="relative h-5" :colspan="tableHeads.size">
                                                <div class="flex items-center justify-center w-full h-16 skeleton">
                                                    <dots-loader/>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
									<tbody v-else-if="columnObject || sortedAppliedKpisData.length === 0">
										<tr class="cursor-pointer rowHeight">
											<td class="relative h-10" :colspan="tableHeads.length">
												<div class="flex justify-center"><p class="text-xs font-normal">No Records Found</p></div>
											</td>
										</tr>
									</tbody>
									<tbody v-else class="tbodylast">
										<tr v-for="(kpiData, index) in sortedAppliedKpisData" :key="kpiData._id" :class="index % 2 == 0 ? 'odd' : 'even'">
											<td class="flex items-center">
												<input type="checkbox" name="checkbox" class="checkbox cursor-pointer" v-model="checkedActiveKpis"  @change="changedActiveKpiInput(0, $event, kpiData.weightage)" :value="kpiData._id" />
											</td>
											<td v-for="column in tableHeads" :key="column" @mouseout="doFalse()" @click="showEditPopUp(kpiData)" class="whitespace-nowrap cursor-pointer relative">
												
												<div v-if="column == 'targetTimePeriod' || column == 'reportingFrequency'">
													<div class="overflow-hidden table-ellipsis">
														<div class="inline">
															{{
																controlRecurringArray.find((itemOne) => {
																	return itemOne.id == kpiData[column];
																}).label
															}}
														</div>
													</div>
												</div>
												<div v-else>
													<div class="overflow-hidden table-ellipsis">
														<div class="inline">
															{{ kpiData[column] }}
														</div>
													</div>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
								<div v-if="sortedAppliedKpisData.length != 0">
									<pagination :dynamicPagination="dynamicPagination" @currentPageUpdate="paginationUpdations" :sortedData="sortedAppliedKpisData"></pagination>
								</div>
							</div>
						</div>

						<div v-if="selectedTabIndex == 1" class="noScrollbar tabStructure1 overflow-y-auto">
							<div class="ResponsivetableCornerRadius bg-white">
								<table>
									<thead>
										<th class="" v-if="allRootKpis.length != 0">
											<input type="checkbox" name="checkbox" class="checkbox cursor-pointer" v-model="selecteAllRelatedKpis" @change="selectKpis(1,$event)" />
										</th>
										<th scope="col" v-for="column in tableHeads" :key="column" class="bg-white cursor-pointer capitalize" @click="sort(column)" :class="getColumnHeadWidth(column)">
											
											<div>
												<div class="flex">
													<div v-if="column == 'description'">KPI Name</div>
													<div v-else-if="column == 'weightage'">weightage (%)</div>
													<div v-else>{{column}}</div>
													<div class="sort cursor-pointer" :class="className == column && (selectedTabIndex == 0 && kpisListForPresentControl.length != 0) || (selectedTabIndex == 1 && allRootKpis.length != 0) ? classSort : 'opacity-50'"></div>
												</div>
											</div>
										</th>
										<tr v-if="showFilters">
											<td v-if="allRootKpis.length != 0"></td>
											<td v-for="name in tableHeads" class="bg-white" :key="name">
												<select class="inputboxstyling resize" v-if="name == 'targetTimePeriod' || name == 'reportingFrequency'" v-model="filters[name]">
													<option :value="filters.name" selected disabled hidden>Select</option>
													<option v-for="(value, index) in controlRecurringArray" :key="index" :value="value.id">{{ value.label }}</option>
												</select>
												<div v-else-if="name == 'weightage'"></div>
												<input v-else type="text" class="inputboxstyling" placeholder="Search" v-model="filters[name]" />
											</td>
										</tr>
									</thead>
                                    <tbody v-if="loadData == true">
                                        <tr class="cursor-pointer empty rowHeight">
                                            <td class="flex items-center justify-center relative h-5" :colspan="tableHeads.size">
                                                <div class="flex items-center justify-center w-full h-16 skeleton">
                                                    <dots-loader/>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
									<tbody v-else-if="columnObject || sortedData.length === 0">
										<tr class="cursor-pointer rowHeight">
											<td class="relative h-10" :colspan="tableHeads.length">
												<div class="flex justify-center"><p class="text-xs font-normal">No Records Found</p></div>
											</td>
										</tr>
									</tbody>
									<tbody v-else class="tbodylast">
										<tr v-for="(kpiData, index) in sortedData" :key="kpiData._id" :class="index % 2 == 0 ? 'odd' : 'even'">
											<td>
												<div class="flex items-center">
													<input type="checkbox" name="checkbox" class="checkbox cursor-pointer" :value="kpiData" v-model="selectedRootKpis" @change="calculateWeightAge(1, $event, kpiData.weightage)" />
												</div>
											</td>
											<td v-for="column in tableHeads" :key="column" @mouseout="doFalse()" class="whitespace-nowrap cursor-pointer relative">
												<div v-if="column == 'targetTimePeriod' || column == 'reportingFrequency'">
													<div class="overflow-hidden table-ellipsis">
														<div class="inline">
															{{
																controlRecurringArray.find((itemOne) => {
																	return itemOne.id == kpiData[column];
																}).label
															}}
														</div>
													</div>
												</div>
												<div v-else>
													<div class="overflow-hidden table-ellipsis">
														<div class="inline">
															{{ kpiData[column] }}
														</div>
													</div>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
								<div v-if="sortedData.length != 0">
									<pagination :dynamicPagination="dynamicPagination" @currentPageUpdate="paginationUpdations" :sortedData="sortedData" v-if="!columnObject"></pagination>
								</div>
							</div>
						</div>
					</div>

					<div class="flex items-center justify-between text-sm mt-6">
						<div class="ml-2">Total Weightage is: {{ showTotalKpisWeightage }} %</div>
					</div>
				</div>


				<div>
					<div v-if="selectedTabIndex == 0" class="bottom-7 bg-white h-14 flex justify-end items-center w-full rounded-b-sm" style="box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);">
						<button class="mr-2"  :class="arraysAreEqual ? 'btndisabled pointer-events-none' : 'btnprimary'" @click="cancelActiveKpis()">Cancel</button>
						<button class="mr-2"  :class="arraysAreEqual ? 'btndisabled pointer-events-none' : 'btnprimary'" @click="saveToRelatedKpis(checkedActiveKpis,10303)">Save</button>
					</div>
					<div v-if="selectedTabIndex == 1" class="bottom-7 bg-white h-14 flex justify-end items-center w-full rounded-b-sm" style="box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);">
						<button class="mr-2" :class="selectedRootKpis.length > 0 ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="cancelRelatedKpis()">Cancel</button>
						<button class="mr-2" @click="saveToRelatedKpis(selectedRootKpis,10301)" :disabled="isButtonDisabled" :class="selectedRootKpis.length > 0 ? 'btnprimary' : 'btndisabled pointer-events-none'">Save</button>
					</div>
				</div>

 			</div>
			<popup v-if="showPopup" :popUp="showPopup">
				<template v-slot:header>
					<div class="flex items-center justify-between py-4 px-2 rounded">
						<h1 class="pl-2">Manage KPI</h1>
						<button
							class="float-right text-3xl"
							@click="
								onCancelbtn();
								showPopup = false;
							"
						>
							<img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
						</button>
					</div>
				</template>
				<fieldset>
					<div>
						<div>
							<div class="statuscontainer popupbodycontainer">
								<div class="formcontainer flex flex-col">
									<!-- <div class="row flex flex-row w-full upperspacing">
										<div class="w-full">
											<label class="controllabel">KPI ID</label>
											<input
												type="text"
												class="inputboxstyling"
												v-model="addingKpiObj.kpi_id"
												placeholder="KPI ID"
												disabled
												
											/>
											
										</div>
									</div> -->
									<div class="row flex flex-row w-full upperspacing">
										<div class="w-full">
											<label class="controllabel">KPI Name</label>
											<textarea
												type="text"
												class="inputboxstyling h-20 max-h-48"
												v-model.trim="addingKpiObj.description"
												@input="v$.addingKpiObj.description.$touch()" 
												placeholder="KPI Name"
												:class="{
													'is-invalid': v$.addingKpiObj.description.$error || isDuplicateData.description
												}"
											></textarea>
											<div v-if="v$.addingKpiObj.description.$error" class="text-red-500">
												<div class="error-text" v-if="v$.addingKpiObj.description.required.$invalid">Required</div>
												<div v-else-if="v$.addingKpiObj.description.required && v$.addingKpiObj.description.restrictCharacters100.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
											</div>
											<div v-if="isDuplicateData.description" class="text-red-500">
												<div class="error-text text-xs">KPI Name already exists.</div>
											</div>
										</div>
									</div>
						
									<div class="row flex flex-row w-full upperspacing">
										<div class="w-6/12">
											<label class="controllabel">Weightage (%)</label>
											<input
												type="number"
												class="inputboxstyling mt-2"
												v-model.trim="addingKpiObj.weightage"
												maxlength="3"
												ref="editWeight"
												placeholder="Weightage (%)"
												@keyup="weightageLessThanZero(addingKpiObj.weightage) || weightageGreaterThanZero(addingKpiObj) && handleInput"
												:class="{
												'is-invalid': v$.addingKpiObj.weightage.$error || validtaionWeightageError || numberLessThanZero,
												}"
											/>
											<div v-if="v$.addingKpiObj.weightage.$error || validtaionWeightageError || numberLessThanZero" class="text-red-500">
												<div class="error-text" v-if="v$.addingKpiObj.weightage.required.$invalid">
													Weightage (%) is required
												</div>
												<div class="error-text" v-if="(validtaionWeightageError && !v$.addingKpiObj.weightage.required.$invalid) || (numberLessThanZero == true && !v$.addingKpiObj.weightage.required.$invalid)">
													Weightage(%) is Invalid
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-if="addingKpiObj._id" class="buttonposition flex justify-center items-center mb-4 mx-0">
							<button class="mr-1.5" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="onCancelbtn()">Cancel</button>
							<button :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="addOrUpdateKpiForControlManual(addingKpiObj)">Update</button>
						</div>
						<div v-else class="buttonposition flex justify-center items-center mb-4 mx-0">
							<!-- <button class="mr-3" :class="addingKpiObj.metrics.length >= 2 ? 'btndisabled pointer-events-none' : 'btnprimary'" @click="addMetrics(addingKpiObj)">Add Metrics</button> -->
							<button class="mr-1.5" :class="disableStatus ? 'btndisabled pointer-events-none' : 'btnprimary'" @click="onCancelbtn()">Cancel</button>
							<button :class="disableStatus ? 'btndisabled pointer-events-none' : 'btnprimary'" @click="addOrUpdateKpiForControlManual(addingKpiObj)">Save</button>
						</div>
					</div>
				</fieldset>
			</popup>
		</div>
	</div>
</template>
<style scoped>
.Vue-Toastification__container {
	z-index: 999999999 !important;
}
  @media only screen and (min-width: 801px) and  (max-width: 1024px) {
    .tabStructure1 {
      height: calc(100vh - 340px);
    }
  }
  @media only screen and (min-width: 200px) and  (max-width: 800px) {
    .tabStructure1 {
      height: calc(100vh - 340px);
    }
  }
  @media only screen and (min-width: 1025px) and  (max-width: 1200px) {
    .tabStructure1 {
      height: calc(100vh - 340px);
    }
  }
  @media only screen and (min-width: 1201px) and  (max-width: 1400px) {
    .tabStructure1 {
      height: calc(100vh - 340px);
    }
  }
  @media only screen and (min-width: 1401px) and  (max-width: 1600px) {
    .tabStructure1 {
      height: calc(100vh - 340px);
    }
  }
  @media only screen and (min-width: 1601px) and  (max-width: 6000px) {
    .tabStructure1 {
      height: calc(100vh - 341px);
    }
  }
.anime-left {
	animation-name: moveLeft;
	animation-duration: 1s;
	animation-iteration-count: 1;
	animation-fill-mode: backwards;
}
@keyframes moveLeft {
	0% {
		opacity: 1;
		transform: translateX(100vw);
	}
	100% {
		transform: translateX(0);
	}
}
.anime-right {
	animation-name: moveRight;
	animation-duration: 1s;
	animation-iteration-count: 1;
	animation-fill-mode: backwards;
}
@keyframes moveRight {
	0% {
		opacity: 1;
		transform: translateX(-100vw);
	}
	100% {
		transform: translateX(0);
	}
}
.progressbarSI {
	border: 2px solid #e9e9e9;
	background: #e9e9e9;
	position: relative;
	top: -8px;
	border-radius:16px;
}
.progress {
	border-radius:16px;
    background: #152a43;
}
.tbodylast:last-child {
	border-bottom: 1px solid #e9e9e9;
}
.ResponsivetableCornerRadius{
	margin-top: 20px;
    border:1px solid #e9e9e9;
    border-radius: 4px !important;
    padding: 1px;
}
@media only screen and (max-width: 768px) {
  .ResponsivetableCornerRadius
  {
    margin-left:0px !important;
  }
}
.button-adjust {
	position: relative;
	top: -30px;
	z-index: 1;
	cursor: pointer;
}
.marginprev {
	margin-left: -20px;
}
.ProgessStyle{
	padding-bottom: 4px;
	z-index: 1;
}
.heading-ellipsis {
  width: 50%;
  display:-webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
::-webkit-scrollbar {
	width: 3px !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.noScrollbar::-webkit-scrollbar {
  display: none;
}
</style>

<script lang="ts">
import { required } from '@vuelidate/validators';
import { helpers } from '@vuelidate/validators';
const { withParams } = helpers;
// import loader from '../../components/loader.vue';
const numeric = helpers.regex('numeric', /^[0-9]+$/i);
const lessthanHundred = helpers.regex('lessthan', /^[1-9][0-9]?$|^100$/i);
const validateMetricsArray = withParams({ type: 'array' }, (value: any) => {
	return Array.isArray(value) && value.length > 0;
});
const customValidator = withParams({ type: 'array' }, validateMetricsArray);

import _ from 'lodash';
import { defineComponent } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { emitter, toast } from '@/main';
import tableData from '@/components/tableData.vue';
import breadCrumb from '@/components/breadCrumb.vue';
import popup from '@/components/popup.vue';
import pagination from '@/components/pagination.vue';
import {v4 as uuidv4 } from 'uuid';
import dotsLoader from '@/components/dotsLoader.vue';
const restrictCharacters100 = helpers.regex(/^.{0,100}$/);

export default defineComponent({
	data(): any {
		return {
			// kpiClone: _.cloneDeep(this.addingKpiObj)	, // Deep clone the kpi object
			v$: useVuelidate(),
			showFilters: false,
			loadData:false,
			dummyList: [],
			hideFormula: false,
			addingKpiObj: {
				// kpi_id:'',
				weightage: '',
				description: '',
			
			},
			dummyObj: [],
			showPopup: false,
			columnObject: false,
			controlRecurringArray: [
				{ label: 'Daily', id: 1 },
				{ label: 'Weekly', id: 2 },
				{ label: 'Monthly', id: 3 },
				{ label: 'Quarterly', id: 4 },
				{ label: 'Half-Yearly', id: 5 },
				{ label: 'Yearly', id: 6 }
			],
			routesArray: [],
			saveToActiveKpis: false,

			allControls: [],
			presentControlObject: {},
			allRootKpis: [],
			kpisListForPresentControl: [],
			allRootKpisUnfiltered: [],
			carouselHead: [
				{ displayName: 'Active KPIs', name: 'Kpis', id: 0 },
				{ displayName: 'Related KPIs', name: 'SuggestedKpis', id: 1 }
			],
			selecteAllActiveKpis: false,
			selecteAllRelatedKpis: false,
            checkedActiveKpis:[],
            checkedAllRelatedKpis: [],
            defaultCheckedActiveKpis:[],


			selectedTabIndex: '',
			sideButton: false,
			styling: { left: '', bottom: '' },
			pageSize: 20,
			currentPage: 1,
			dynamicPagination: '',
			currentSort: 'name',
			currentSortDir: 'asc',
			classSort: '',
			className: '',
			validtaionWeightageError: false,
			numberLessThanZero : false,
			filters: {},
			selectedRootKpis: [],
			titleLength: 0,
			pop: false,
			showTotalKpisWeightage: 0,
			tableHeads: ['description', 'weightage'],
			pageItems: 6,
			currentItems: 1,
			addedKpiNamesList: [],
			addedKpiNamesListExcludindSelectedKpi: [],
			isButtonDisabled: false,
		};
	},
	components: {
		pagination,
		popup,
		breadCrumb,
		dotsLoader
	},
	validations: {
		addingKpiObj: {
			weightage: { required },
			description: { required, restrictCharacters100 }
		}
	},
	async created() {
		this.loadData = true
		await this.getAllRootKpis();
		await this.getAllActiveKpis();
		await this.getAllRootControls();
		this.loadData = false
		// await this.toggleTabs(0);
		const savedTabKey = sessionStorage.getItem('selectedTab');
		if (savedTabKey) {
			const savedTab = this.carouselHead.find((tab: any) => tab.id == savedTabKey);
			if (savedTab) {
			// console.log("Hiiii")
				await this.toggleTabs(savedTab.id);
			}
		} else {
			await this.toggleTabs(0);
		}
		let routeControlId = this.$route.params.id;
		let controlObj;
		controlObj = this.allControls.find((item: any) => routeControlId === item._id);
		this.titleLength = controlObj.controlTitle ? controlObj.controlTitle.length:0
		if (this.$route.query.from == 'frameworks') {
			this.routesArray = [
				{ name: 'Frameworks', routeName: 'root-Frameworks' },
				{ name: controlObj.controlNumber, routeName: 'manageRootControlMapping' }
			];
		} else if (this.$route.query.from == 'internalControls') {
			this.routesArray = [
				{ name: 'Internal Controls', routeName: 'root-controls' },
				{ name: controlObj.controlNumber, routeName: 'manageRootControlMapping' }
			];
		}
	},
  beforeUnmount() {
		sessionStorage.removeItem('selectedTab')
	},
	computed: {
		isDuplicateData: function (): any {
			if(this.kpisListForPresentControl.length != 0 && this.hideFormula == true){
				return {
					description: this.addedKpiNamesListExcludindSelectedKpi.some((kpi:any) =>{
						return this.addingKpiObj.description && kpi && JSON.stringify(kpi).replace(/\s+/g, '').toLowerCase() == JSON.stringify(this.addingKpiObj.description).replace(/\s+/g, '').toLowerCase()
					}),
				};
			} else if( this.hideFormula == false){
				return {
					description: this.addedKpiNamesList.some((kpi:any) =>{
						return this.addingKpiObj.description && kpi && JSON.stringify(kpi).replace(/\s+/g, '').toLowerCase() == JSON.stringify(this.addingKpiObj.description).replace(/\s+/g, '').toLowerCase()
					}),
				};
			}else {
				return false
			}
		},
		sortedData: function (): any {
			return [...this.allRootKpis]
				.filter((row: any) => {
					for (const key in this.filters) {
						const inputValue = this.filters[key];
						if (inputValue !== '' || row[key]) {
							if (Number(inputValue) && isNaN(row[key])) {
								if (!String(row[key]).includes(inputValue)) {
									return false;
								}
							} else {
								if (!row[key].toString().toLowerCase().includes(inputValue.toString().toLowerCase())) {
									return false;
								}
							}
						}
					}
					return true;
				})
				.sort((a, b) => {
					let modifier = 1;
					if (this.currentSortDir === 'desc') modifier = -1;
					if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
					if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
					return 0;
				})
				.filter((row, index) => {
					this.dynamicPagination = index + 1;
					let start = (this.currentPage - 1) * this.pageSize;
					let end = this.currentPage * this.pageSize;
					if (index >= start && index < end) return true;
				});
		},
		sortedAppliedKpisData: function (): any {
			return [...this.kpisListForPresentControl]
				.filter((row: any) => {
					for (const key in this.filters) {
						const inputValue = this.filters[key];
						if (inputValue !== '' || row[key]) {
							if (Number(inputValue) && isNaN(row[key])) {
								if (!String(row[key]).includes(inputValue)) {
									return false;
								}
							} else {
								if (!row[key].toString().toLowerCase().includes(inputValue.toString().toLowerCase())) {
									return false;
								}
							}
						}
					}
					return true;
				})
				.sort((a, b) => {
					let modifier = 1;
					if (this.currentSortDir === 'desc') modifier = -1;
					if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
					if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
					return 0;
				})
				.filter((row, index) => {
					this.dynamicPagination = index + 1;
					let start = (this.currentPage - 1) * this.pageSize;
					let end = this.currentPage * this.pageSize;
					if (index >= start && index < end) return true;
				});
		},
		getControlId(): any {
			return this.$route.params.id;
		},
		disableStatus: function (): any {
			let output;
			if (this.editPop) {
				let objCopy = Object.assign({}, this.addingKpiObj);
				if (_.isEqual(objCopy, this.dummyObj)) {
					output = false;
				} else {
					output = true;
				}
			} else {
				if (_.isEqual(this.addingKpiObj, this.dummyObj)) {
					output = true;
				} else {
					output = false;
				}
			}
			return output;
		},
		
        arraysAreEqual: function (): any {
            const sortedArray1 = [...this.checkedActiveKpis].sort();
            const sortedArray2 = [...this.defaultCheckedActiveKpis].sort();
            
            if (sortedArray1.length !== sortedArray2.length) {
                return false;
            }

            for (let i = 0; i < sortedArray1.length; i++) {
                if (sortedArray1[i] !== sortedArray2[i]) {
                return false;
                }
            }

            return true;
        },
		cantGoBack(): any {
			return this.currentItems === 1;
		},
		cantGoForward(): any {
			return this.carouselHead.length / this.pageItems <= this.currentItems;
		},
		tabHead():any {
        const start = (this.currentItems - 1) * this.pageItems;
        const end = this.currentItems * this.pageItems;
        return this.carouselHead.slice(start, end);
       },
		// filteredTimePeriods(): any {
		// 	if (this.addingKpiObj.reportingFrequency) {
		// 		return this.controlRecurringArray.filter((value: any) => value.id >= this.addingKpiObj.reportingFrequency);
		// 	} else {
		// 		return [];
		// 	}
		// }
	},
	methods: {
		selectKpis(tabIndex:any , e: any){
            this.selectedRootKpis = [];
            const checked = e.target.checked;
            if (checked) {
                for (let obj of this.allRootKpis) {
                    this.selectedRootKpis.push(obj);
                }
				if (tabIndex == 1) {
					const checked = e.target.checked;
					let weightage:any = 0
					this.selectedRootKpis.map((data:any)=>{
						weightage += data.weightage
					})
					this.showTotalKpisWeightage == 0 ? (this.showTotalKpisWeightage = 0) : '';

					if (checked) {
						this.showTotalKpisWeightage = this.showTotalKpisWeightage + weightage;
					} else {
						this.showTotalKpisWeightage = this.showTotalKpisWeightage - weightage;
					}
				} else {
					this.showTotalKpisWeightage = 0;
					if(this.kpisListForPresentControl != undefined && Array.isArray(this.kpisListForPresentControl)){
						this.kpisListForPresentControl.map((obj: any) => {
							this.showTotalKpisWeightage = this.showTotalKpisWeightage + obj.weightage;
						});
					}
				}
            } else {
                this.selectedRootKpis = [];
				this.showTotalKpisWeightage = 0;
				if(this.kpisListForPresentControl != undefined && Array.isArray(this.kpisListForPresentControl)){
					this.kpisListForPresentControl.map((obj: any) => {
						this.showTotalKpisWeightage = this.showTotalKpisWeightage + obj.weightage;
					});
				}
            }
		},
		deSelectKpis(tabIndex:any , e: any){
            this.checkedActiveKpis = [];
            const checked = e.target.checked;
            if (checked) {
                for (let obj of this.kpisListForPresentControl) {
                    this.checkedActiveKpis.push(obj._id);
                }
                this.selectedRootKpis = [];
				this.showTotalKpisWeightage = 0;
				if(this.kpisListForPresentControl != undefined && Array.isArray(this.kpisListForPresentControl)){
					this.kpisListForPresentControl.map((obj: any) => {
						this.showTotalKpisWeightage = this.showTotalKpisWeightage + obj.weightage;
					});
				}
            } else {
                this.checkedActiveKpis = [];
				
				if (tabIndex == 0) {
					const checked = e.target.checked;
					let weightage:any = 0
					this.selectedRootKpis.map((data:any)=>{
						weightage += data.weightage
					})
					this.showTotalKpisWeightage == 0 ? (this.showTotalKpisWeightage = 0) : '';

					if (checked) {
						this.showTotalKpisWeightage = this.showTotalKpisWeightage + weightage;
					} else {
						this.showTotalKpisWeightage = 0;
					}
				} else {
					this.showTotalKpisWeightage = 0;
					if(this.kpisListForPresentControl != undefined && Array.isArray(this.kpisListForPresentControl)){
						this.kpisListForPresentControl.map((obj: any) => {
							this.showTotalKpisWeightage = this.showTotalKpisWeightage + obj.weightage;
						});
					}
				}
            }
		},

        cancelActiveKpis(){
            this.checkedActiveKpis = [...this.defaultCheckedActiveKpis];
            if(this.checkedActiveKpis.length == this.kpisListForPresentControl.length){
                this.selecteAllActiveKpis = true;
            }else{
                this.selecteAllActiveKpis = false;
            }
        },
		cancelRelatedKpis(){
            this.selectedRootKpis = [];
            this.selecteAllRelatedKpis = false;
			this.showTotalKpisWeightage = 0;
			if(this.kpisListForPresentControl != undefined && Array.isArray(this.kpisListForPresentControl)){
				this.kpisListForPresentControl.map((obj: any) => {
					this.showTotalKpisWeightage = this.showTotalKpisWeightage + obj.weightage;
				});
			}
		},
        changedActiveControlInput(){
            if(this.checkedActiveKpis.length == this.kpisListForPresentControl.length){
                this.selecteAllActiveKpis = true;
            }else{
                 this.selecteAllActiveKpis = false;
            }
        },
		
		changedActiveKpiInput(tabIndex: any, e: any, weightage: any){

			if (tabIndex == 0) {
				const checked = e.target.checked;
				this.showTotalKpisWeightage == 0 ? (this.showTotalKpisWeightage = 0) : '';

				if (checked) {
					this.showTotalKpisWeightage = this.showTotalKpisWeightage + weightage;
				} else {
					this.showTotalKpisWeightage = this.showTotalKpisWeightage - weightage;
				}
				console.log("this.showTotalKpisWeightage",this.showTotalKpisWeightage)
				if(this.checkedActiveKpis.length == this.kpisListForPresentControl.length){
					this.selecteAllActiveKpis = true;
				}else{
					this.selecteAllActiveKpis = false;
				}
			} else {
				this.showTotalKpisWeightage = 0;
				console.log("this.kpisListForPresentControl",this.kpisListForPresentControl)
				if(this.kpisListForPresentControl != undefined && Array.isArray(this.kpisListForPresentControl)){
					this.kpisListForPresentControl.map((obj: any) => {
						this.showTotalKpisWeightage = this.showTotalKpisWeightage + obj.weightage;
					});
				}
			}
		},

        changedRelatedKpiInput(){
            if(this.checkedAllRelatedKpis.length == this.allRootKpis.length){
                this.selecteAllRelatedKpis = true;
            }else{
                this.selecteAllRelatedKpis = false;
            }
        },


		updateSelectedFrame() {
            // Update selectedFrame based on the first tab in the current page
            this.selectedFrame = this.tabHead[0]?.name || null;
        },

		async nextPage() {
        if ((this.currentItems * this.pageItems) < this.carouselHead.length) {
            this.currentItems = this.currentItems + 1;
            this.updateSelectedTabIndex(); // Update selectedTabIndex after navigation
            this.updateCarouselClass(); // Update carousel layout after navigation
        }
		},
		async prevPage() {
			if (this.currentItems > 1) {
				this.currentItems = this.currentItems - 1;
				this.updateSelectedTabIndex(); // Update selectedTabIndex after navigation
				this.updateCarouselClass(); // Update carousel layout after navigation
			}
		},
		getColumnHeadWidth(column:any){
			if(column == 'weightage'||column == 'description'){
				return 'w-1/2'
			}
		},
		updateSelectedTabIndex() {
			// Update selectedTabIndex based on the first tab in the current page
			// Adjust index based on the current page and pageItems
			this.selectedTabIndex = (this.currentItems - 1) * this.pageItems;
		},
		newWeightage(kpiObj: any, previousWeightage: number = 0) {
			const totalWeightage = this.showTotalKpisWeightage - previousWeightage + kpiObj.weightage;
			return totalWeightage;
		},
		handleInput(event : any) {
			console.log('event',event)
			this.addingKpiObj.weightage = event.target.value.replace(/[^\d]/g, '');
		},
		weightageLessThanZero(weightage : any){
			if(weightage < 0 || weightage == 0){
				this.numberLessThanZero = true
			}else{
				this.numberLessThanZero = false
			}
		},
		weightageGreaterThanZero(kpi: any){
			this.validtaionWeightageError = false;
			let previousWeightage = 0;
			let kpiId = kpi._id;
			if (kpiId){
				const existingKpi = this.kpisListForPresentControl.find((eachKPI: any) => eachKPI._id === kpiId);
				console.log('existingKpi',existingKpi)
				console.log("kpisListForPresentControl",this.kpisListForPresentControl)
				if (existingKpi) {
					previousWeightage = existingKpi.weightage;
				}
			}
			const valid = this.newWeightage(kpi, previousWeightage);
			console.log('valid',valid)
			console.log("validvalidvalidvalid", valid);
			if (valid <= 100) {
				this.validtaionWeightageError = false;
				// this.showTotalKpisWeightage = valid;
			}else {
				this.validtaionWeightageError = true;
			}
		},

		updateCarouselClass() {
				const x = window.innerWidth;
				let className = '';

				if (x > 1280) {
				className = 'page-items-7';
				this.pageItems = 7;
				} else if (x >= 1024 && x <= 1280) {
				className = 'page-items-6';
				this.pageItems = 6;
				} else if (x >= 899 && x < 1024) {
				className = 'page-items-5';
				this.pageItems = 5;
				} else if (x >= 640 && x < 899) {
				className = 'page-items-4';
				this.pageItems = 4;
				} else {
				className = 'page-items-1';
				this.pageItems = 1;
				}

				const carouselContainer = this.$refs.carouselContainer;
				if (carouselContainer) {
				carouselContainer.className = '';
				carouselContainer.classList.add(className);
				}

			// Adjust currentItems if it exceeds the number of pages
			const totalItems = this.carouselHead.length;
			const totalPages = Math.ceil(totalItems / this.pageItems);
			if (this.currentItems > totalPages) {
			this.currentItems = totalPages;
			}
			if (this.currentItems < 1) {
			this.currentItems = 1;
			}
		},
		emptyTargetTimePeriod(kpi: any) {
			if (kpi.targetTimePeriod < kpi.reportingFrequency) {
				kpi.targetTimePeriod = '';
			}
		},
		addKPI() {
			if (this.kpisListForPresentControl.length < 10) {
				let numeric = 1;
				// this.allRootKpis.forEach((item: any) => {
				// 	if (Object.keys(item).includes("kpi_id") && item.kpi_id) {
				// 		const number = item.kpi_id.split(" ");
				// 		if (parseInt(number[2]) && !isNaN(parseInt(number[2]))) {
				// 		let intNumber = parseInt(number[2]);
				// 		numeric = Math.max(numeric, intNumber);
				// 		numeric++;
				// 		}
				// 	}
				// });
				// let id = "KPI - " + numeric;
				this.addingKpiObj = { weightage: '', description: '' };
				// this.addingKpiObj = { kpi_id: id, weightage: '', description: '' };
				this.hideFormula = false;
				this.showPopup = true;
				this.editPop = false;
				this.dummyObj = {...this.addingKpiObj};
				
			} else {
				toast.error('Maximum KPIs should be 10', {
					timeout: 4000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
		},
		onReset() {
			// reset form validation errors
			this.v$.$reset();
			// reset form data
			const initialData = this.$options.data.call(this);
			Object.assign(this.$data, initialData);
		},
		showEditPopUp(showObj: any) {
			this.v$.$reset();
			this.showPopup = true;
			// let id = uuidv4();
			// showObj.isformula = 'A/B';
			// console.log("idid", id);
			// this.addingKpiObj.kpi_id = id;
			// this.dummyObj = _.cloneDeep();
			this.addedKpiNamesListExcludindSelectedKpi=[]
			this.kpisListForPresentControl.forEach((data:any) => {
				if(showObj._id != data._id){
					this.addedKpiNamesListExcludindSelectedKpi.push(data.description)
				}
			});
			this.editPop = true;
			this.addingKpiObj = { ...showObj };
			this.dummyObj = { ...this.addingKpiObj }
			this.hideFormula = true;
		},
		filterOpen() {
			if (this.columnObject == false) {
				this.showFilters = !this.showFilters;
				this.filters = {};
				emitter.emit('visible', this.showFilters);
			}
		},
		paginationUpdations(currentPage: any, pageSize: any) {
			this.currentPage = currentPage;
			this.pageSize = pageSize;
		},
		sort: function (s: any) {
			this.className = s;
			if (s === this.currentSort) {
				this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
			}
			this.currentSort = s;
			this.classSort = this.currentSortDir === 'asc' ? 'sort_asc' : 'sort_desc';
		},
		// addMetrics(kpi: { metrics: { label: string; key: string; value: string }[] }) {
		// 	this.hideFormula = true;
		// 	// this.addingKpiObj.isformula = 'A/B';
		// 	this.addingKpiObj.metrics.push({ label: '', key: 'A', value: '' });
		// 	this.addingKpiObj.metrics.push({ label: '', key: 'B', value: '' });
		// },
		onCancelbtn() {
			this.v$.$reset();
			this.validtaionWeightageError = false;
			this.numberLessThanZero = false;
			if (!this.addingKpiObj._id) {
				Object.keys(this.addingKpiObj).forEach((prop) => {
					Array.isArray(this.addingKpiObj[prop]) ? (this.addingKpiObj[prop] = []) : (this.addingKpiObj[prop] = '');
				});
				this.hideFormula = false;
			} else {
				this.addingKpiObj = {...this.dummyObj};
			}
		},
		async addOrUpdateKpiForControlManual(kpi: any) {
			this.v$.$touch();
			// this.validtaionWeightageError = false;
			let check:any = !Object.values(this.isDuplicateData).includes(true)
			if (!this.v$.$invalid && this.numberLessThanZero == false && this.validtaionWeightageError == false && check) {
			let previousWeightage = 0;
			let kpiId = kpi._id;

			if (kpiId) {
			// Find the previous weightage if KPI is being updated
			const existingKpi = this.kpisListForPresentControl.find((eachKPI: any) => eachKPI._id === kpiId);
			console.log("kpisListForPresentControl",this.kpisListForPresentControl)
			console.log('existingKpi',existingKpi)
			if (existingKpi) {
				previousWeightage = existingKpi.weightage;
			}
			}

			const valid = this.newWeightage(kpi, previousWeightage);
			console.log("validvalidvalidvalid", valid);

			if (valid <= 100) {
			// this.validtaionWeightageError = false;

			if (kpiId) {
				// Update the KPI
				await this.updateKpiForControl(kpi);
			} else {
				// Add new KPI
				await this.saveKpisForPresentRootControl(kpi, 'manual');
			}

			// Update showTotalKpisWeightage
			this.showTotalKpisWeightage = valid;

			this.showPopup = false;
			this.v$.$reset();
			} else {
			// this.validtaionWeightageError = true;
			}
			} 
			// else {
			// toast.error(`error`, {
			// 	timeout: 1000,
			// 	closeOnClick: true,
			// 	closeButton: "button",
			// 	icon: true,
			// });
			// }
		},
		// async addOrUpdateKpiForControlManual(kpi: any) {
		// 	this.v$.$touch();
		// 	if (!this.v$.$invalid) {
		// 		if (Object.keys(kpi).includes('_id')) {
		// 			await this.updateKpiForControl(kpi);
		// 		} else {
		// 			await this.saveKpisForPresentRootControl(kpi);
		// 		}
		// 		this.showPopup = false;
		// 		this.v$.$reset();
		// 	} else {
		// 		toast.error(`error`, {
		// 			timeout: 1000,
		// 			closeOnClick: true,
		// 			closeButton: 'button',
		// 			icon: true
		// 		});
		// 	}
		// },
		async getAllRootControls() {
			this.presentControlObject = {};
			// this.kpisListForPresentControl = [];
			this.addedKpiNamesList=[]
			try {
				await this.$http.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/root/controls/getall`).then((result: { data: any }) => {
					this.allControls = result.data;
					let routeControlId = this.$route.params.id;
					let mainObj = result.data.find((eachItem: any) => {
						return routeControlId == eachItem._id;
					});
					console.log("mainObj",mainObj)
					if(mainObj != undefined){
						this.presentControlObject = mainObj;
					}
					// if (mainObj != undefined && Object.keys(mainObj).includes('kpis')&& Array.isArray(mainObj.kpis)) {
					// 	// this.presentControlObject = mainObj;
					// 	this.kpisListForPresentControl = mainObj.kpis;

					// 	let mappedKpis: any = [];
					// 	if(this.kpisListForPresentControl != undefined){
					// 		this.kpisListForPresentControl.map((obj: any) => {
					// 			mappedKpis.push(obj.description);
					// 			this.addedKpiNamesList.push(obj.description)
					// 		});
					// 	}

					// 	if (mappedKpis.length > 0) {
					// 		let filteredRootKpis = this.allRootKpis.filter((obj: any) => {
					// 			return !mappedKpis.includes(obj.description);
					// 		});
					// 		this.allRootKpis = filteredRootKpis;
					// 	}
					// } else {
					// 	this.kpisListForPresentControl = [];
					// }
				});
			} catch (e) {
				toast.error('error', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
		},
		async getAllActiveKpis(){
			// /api/root/control/:controlId/active/kpis/get
			this.kpisListForPresentControl = []
			this.checkedActiveKpis = []
			this.defaultCheckedActiveKpis = []
			let dummy:any = []
			let controlId:any = this.$route.params.id
			try {
				await this.$http.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/root/control/${controlId}/active/kpis/get`).then((result: { data: any }) => {
					dummy = result.data
				});
				let mappedKpis: any = [];
				dummy.map((obj: any) => {
					mappedKpis.push(obj.description);
					this.addedKpiNamesList.push(obj.description)
					this.checkedActiveKpis.push(obj._id)
					this.defaultCheckedActiveKpis.push(obj._id);
				});
				if (mappedKpis.length > 0) {
					let filteredRootKpis = this.allRootKpis.filter((obj: any) => {
						return !mappedKpis.includes(obj.description);
					});
					console.log("filteredRootKpis",filteredRootKpis)
					this.allRootKpis = filteredRootKpis;
				}
				
				this.kpisListForPresentControl = [...dummy]
						
				if(this.checkedActiveKpis.length == this.kpisListForPresentControl.length && this.kpisListForPresentControl.length!=0){
					this.selecteAllActiveKpis = true;
				}else{
					this.selecteAllActiveKpis = false;
				}
			} catch (e) {
				toast.error('error', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
		},
		doFalse() {
			this.pop = false;
		},
		async getAllRootKpis() {
			this.allRootKpis = [];
			this.allRootKpisUnfiltered = [];
			let controlId:any = this.$route.params.id
			try {
				await this.$http.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/root/control/${controlId}/kpis/getall`).then((result: { data: any }) => {
					this.allRootKpisUnfiltered = [...result.data];
					this.allRootKpis = result.data;
				});
			} catch (e) {
				toast.error('error', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
		},
		async toggleTabs(navIndex: any) {
			this.selectedTabIndex = navIndex;
			this.calculateWeightAge(0);
			this.selectedRootKpis = [];
			this.showFilters = false;
			this.filters = {};
			sessionStorage.setItem('selectedTab', navIndex);

			if (navIndex == 0) {
                this.currentPage = 1
                // this.filtersOpen();
                // emitter.emit('visible', false);
                // this.showFilters = false;
				this.loadData = true
                await this.getAllRootKpis();
                await this.getAllActiveKpis();
				this.loadData = false
            } else if (navIndex == 1) {
                this.currentPage = 1
				this.selecteAllRelatedKpis = false
                // this.filtersOpen();
                // emitter.emit('visible', false);
                // this.showFilters = false;
				this.loadData = true
                await this.getAllRootKpis();
                await this.getAllActiveKpis();
				this.loadData = false
            }
		},
		async saveKpisForPresentRootControl(kpiObj: any) {
			let finalArr: any[] = [];
			let routeControlId = this.$route.params.id;
			let controlObj;
			controlObj = this.allControls.find((item: any) => routeControlId === item._id);
			if (this.showTotalKpisWeightage <= 100) {
				if (this.selectedTabIndex == 1) {
					this.selectedRootKpis.map((obj: any) => {
						obj.referenceKpiId = obj._id;
						delete obj._id;
						finalArr.push(obj);
					});
				} else {
					finalArr.push(kpiObj);
				}
				let controlPayLoadFramework;
				if (this.$route.query.from == 'frameworks') {
					controlPayLoadFramework = controlObj.controlFrameWork;
				} else if (this.$route.query.from == 'internalControls') {
					controlPayLoadFramework = 'InternalControls';
				}
				let controlId:any = this.$route.params.id
				// /api/root/control/${this.$route.params.id}/kpis/add?frameWork=${controlPayLoadFramework}`
				await this.$http.post(`${process.env.VUE_APP_CONTROLS_API_URL}/api/root/control/${controlId}/kpis/create?frameWork=${controlPayLoadFramework}`, finalArr).then(async () => {
					toast.info('Saved Successfully', {
						timeout: 5000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
					this.selectedRootKpis = [];
					await this.getAllRootControls();
					await this.toggleTabs(0);
				});
			} else {
				toast.error('Total Weightage should be less than 100', {
					timeout: 5000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
		},
		calculateWeightAge(tabIndex: any, e: any, weightage: any) {
			if (tabIndex == 1) {
				const checked = e.target.checked;
				this.showTotalKpisWeightage == 0 ? (this.showTotalKpisWeightage = 0) : '';

				if (checked) {
					this.showTotalKpisWeightage = this.showTotalKpisWeightage + weightage;
				} else {
					this.showTotalKpisWeightage = this.showTotalKpisWeightage - weightage;
				}
				console.log("this.showTotalKpisWeightage",this.showTotalKpisWeightage)
				if(this.selectedRootKpis.length == this.allRootKpis.length){
					this.selecteAllRelatedKpis = true;
				}else{
					this.selecteAllRelatedKpis = false;
				}
				// this.selectedRootKpis.map((obj:any)=>{this.showTotalKpisWeightage = this.showTotalKpisWeightage + obj.weightage})
			} else {
				this.showTotalKpisWeightage = 0;
				console.log("this.kpisListForPresentControl",this.kpisListForPresentControl)
				if(this.kpisListForPresentControl != undefined && Array.isArray(this.kpisListForPresentControl)){
					this.kpisListForPresentControl.map((obj: any) => {
						this.showTotalKpisWeightage = this.showTotalKpisWeightage + obj.weightage;
					});
				}
			}
		},
		async updateKpiForControl(kpiObj: any) {
			let routeControlId = this.$route.params.id;
			let controlObj;
			controlObj = this.allControls.find((item: any) => routeControlId === item._id);
			let kpiId = kpiObj._id;
			delete kpiObj._id;
			let controlPayLoadFramework;
			if (this.$route.query.from == 'frameworks') {
				controlPayLoadFramework = controlObj.controlFrameWork;
			} else if (this.$route.query.from == 'internalControls') {
				controlPayLoadFramework = 'InternalControls';
			}
			await this.$http.post(`${process.env.VUE_APP_CONTROLS_API_URL}/api/root/control/${routeControlId}/kpi/${kpiId}/update?frameWork=${controlPayLoadFramework}`, kpiObj).then(async () => {
				toast.info('Updated Successfully', {
					timeout: 5000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
				this.selectedRootKpis = [];
				await this.getAllRootControls();
				await this.toggleTabs(0);
				this.showPopup = false;
			});
		},

		async saveToRelatedKpis(obj:any,type:any){
			// /api/root/control/:controlId/kpis/:type
			// note:if you are selecting an kpi from relative kpi then type is 10301, if you select kpi from active kpis then the type is 10303
			let controlId:any = this.$route.params.id
			let payload:any = []
			this.isButtonDisabled = true;

			if(this.showTotalKpisWeightage <= 100){
				if(type == 10301){
					if(obj !=undefined){
						console.log("kjjkgas",obj)
						obj.map((data:any)=>{
							let keysOfdata:any = Object.keys(data)
							if(keysOfdata.includes('kpiType') && data.kpiType == 3){
								payload.push(data)
							} else {
								let payloadObj:any = {
									kpi_id: data.kpi_id,
									weightage: data.weightage,
									description: data.description,
									referenceKpiId: data._id
								}
								// console.log("jgsdsdhsdjhs",data)
								payload.push(payloadObj)
							}
						})
						// payload = obj
					}
				}else if(type == 10303){
					let deselectedControls:any = this.defaultCheckedActiveKpis.filter((id:any) => !this.checkedActiveKpis.includes(id));
					if(deselectedControls != undefined && deselectedControls.length != 0){
						this.kpisListForPresentControl.forEach((kpiData:any) => {
							deselectedControls.map((data:any)=>{
								if(data == kpiData._id){
									payload.push(kpiData)
								}
							})
						});
					}
					// payload = deselectedControls
				}
				let controlPayLoadFramework:any
				let controlObj:any
				let routeControlId = this.$route.params.id;
				controlObj = this.allControls.find((item: any) => routeControlId === item._id);
				if (this.$route.query.from == 'frameworks') {
					controlPayLoadFramework = controlObj.controlFrameWork;
				} else if (this.$route.query.from == 'internalControls') {
					controlPayLoadFramework = 'InternalControls';
				}
				console.log("payload",payload)
				try {
					await this.$http.post(`${process.env.VUE_APP_CONTROLS_API_URL}/api/root/control/${controlId}/kpis/${type}?frameWork=${controlPayLoadFramework}`,payload).then(async (result: { data: any }) => {
						
						toast.info('Updated Sucessfully', {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
						await this.getAllActiveKpis();
						await this.getAllRootKpis();
					});
				} catch (e) {
					toast.error('error', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				}
			} else {
				toast.error('Total Weightage should be less than 100', {
					timeout: 5000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
			setTimeout(() => {
				this.isButtonDisabled = false;
			}, 5000);
		}
	},
	mounted() {
		window.addEventListener('resize', this.updateCarouselClass);
		this.updateCarouselClass(); 
	},
});
</script>